import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { Switch, Route } from "react-router-dom";
import PointOfSale from "../../main/pointOfSale/PointOfSale";

class PointOfSales extends AbstractMenu {
  state = {};

  render() {
    const background = "#847556";
    return (
      <Switch>
        <Route path={"/pointOfSales/pointOfSale/:tableNumber"} render={props => <PointOfSale {...props} />} />
        <Route
          path={"/pointOfSales"}
          render={props => (
            <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
              <SubmenuHeader {...this.props} header={<Translate value="admin_local.menus.pointOfSales" />} />
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flextablesGrow: 1 }}>
                {this.props.pointOfSales.map(table => {
                  return (
                    <MenuButton
                      key={table.number}
                      href={"/pointOfSales/pointOfSale/" + table.number}
                      i="i_cash_register"
                      background={background}
                      myStatus={this.props.myStatus}
                      backgroundImage={require("../../img/point-of-sale.jpg")}
                      name={table.name ? table.name : "" + table.number}
                    ></MenuButton>
                  );
                })}
              </div>
            </div>
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurant_production_lines: state.rootReducer.myStatus.restaurant_production_lines,
    pointOfSales: state.rootReducer.pointOfSales ? state.rootReducer.pointOfSales : [],
    pointtOfSales: state.rootReducer.pointOfSales ? state.rootReducer.pointOfSales : [],
    myStatus: state.rootReducer.myStatus
  };
};

export default connect(mapStateToProps)(PointOfSales);
