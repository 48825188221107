import React, { Component } from "react";
import EatWithMeText from "../../components/EatWIthMeText";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, hideEditTable, userChanged, stopIntervals, fullReload, showEditTable, afterLockTable, selectNewOrders } from "../../js/tables-overview";
import orderListUtil, { updateTableOccupations } from "../../js/order-list-util";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import LoginButton from "../../components/icon-buttons/LoginButton";
import AICommandBox from "../../js/ai/AICommandBox";
import auth, { ajaxCallEnd, ajaxCallStart, featureEnabled, get, getMyImageUrl, messageDialogTemp } from "../../js/auth";
import admin, { getHomeDeliveryTableNumber, lockTable, selectTableNumberSelected } from "../../js/admin";
import RestaurantSelector from "../../components/RestaurantSelector";
import BackButton from "../../components/menus/BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import { Help } from "../../js/help";
import { help } from "../../help/tables-overview";
import HelpButton from "../../components/icon-buttons/HelpButton";
import { dispatch } from "d3-dispatch";
import EatWithMeActions from "../../actions/EatWithMeActions";
import { store } from "../..";
import moment from "moment-timezone";
import OpeningDay from "../../components/OpeningDay";
import $ from "jquery";
import tableLayout from "../../js/table-layout";

class TablesOverview extends Component {
  state = {
    initialized: false,
    ringing: []
  };

  checkDayOpen = (first = false) => {
    if (featureEnabled("ntak")) {
      const h = this.props.myStatus.restaurant_settings["business-day-starts-at"];
      if (this.state.open === undefined || ((!first || !this.state.doneOnce) && this.state.date !== moment().add(-h, "hour").startOf("day").valueOf())) {
        //this.setState({ open: false })
        ajaxCallStart("findByRestaurantAndDate");
        get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().add(-h, "hour").startOf("day").format("yyyy-MM-DD HH:mm:ss ZZ").replace("+", "%2B"), undefined, undefined, undefined, false).done(d => {
          ajaxCallEnd("findByRestaurantAndDate");
          this.setState({ doneOnce: true, open: d._embedded.businessDays.length > 0, date: d._embedded.businessDays.length > 0 ? moment().startOf("day").valueOf() : null })
        })
      }
    } else {
      this.setState({ open: true })
    }
  }

  openDayChecker = null;

  componentDidMount = () => {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    auth.minfontsize = 5;

    /*
    store.dispatch(EatWithMeActions.phoneringing("+36302698791"));
    store.dispatch(EatWithMeActions.phoneringing("+36301831932"));
    */

    if (myStatus && myStatus.restaurant_id) {
      if (this.state.open || this.state.continue) {
        ready(this.props, this).done((tablesoverview, tablelayout) => {
          this.tablesoverview = tablesoverview;
          this.tablelayout = tablelayout;
          orderListUtil.help = new Help(help);
        });
        this.setState({ initialized: true });
        this.checkDayOpen();
        this.updateDeliveryUsers();
      }
      admin.userChanged = userChanged;
      admin.hideEditTable = hideEditTable;
      if (this.openDayChecker == null)
        this.openDayChecker = setInterval(this.checkDayOpen, 30000);
      this.checkDayOpen(true);
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      if (this.state.open || this.state.continue) {
        ready(this.props, this).done((tablesoverview, tablelayout) => {
          this.tablesoverview = tablesoverview;
          this.tablelayout = tablelayout;
          orderListUtil.help = new Help(help);
        });
        this.setState({ initialized: true });
        this.checkDayOpen();
        this.updateDeliveryUsers();
      }
      admin.userChanged = userChanged;
      admin.hideEditTable = hideEditTable;
      if (this.openDayChecker == null)
        this.openDayChecker = setInterval(this.checkDayOpen, 30000);
      this.checkDayOpen(true);
    }

    if (this.state.warningShown || !this.props.myStatus.subscription || myStatus.roles.filter(r => r.role === "superadmin").length)
      return;

    this.setState({ warningShown: true });


    var dueSince = myStatus.invoiceDueSince;
    if (dueSince > 0) {
      const instance = this;
      var duration = dueSince;
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
      function updateMessage() {
        duration -= 1;
        if (instance.dialog)
          instance.dialog.resolve();
        if (duration > 0) {
          instance.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
          setTimeout(updateMessage, 60000);
        }
      }
      setTimeout(updateMessage, 60000);
      this.dialog.done(() => {
        delete this.dialog;
      });
    } else if (dueSince >= -3) {
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_due_in"), -dueSince), false);
      setTimeout(this.dialog.resolve, (5 + dueSince) * 1000);
      this.dialog.done(() => {
        delete this.dialog;
      });
    }

  }

  componentWillUnmount() {
    stopIntervals();
    this.setState({ initialized: false, open: undefined });
    delete admin.userChange;
    delete admin.hideEditTable;
    delete auth.recoverFromError;
    document.onkeydown = null;
    clearInterval(this.openDayChecker);
  }

  onReload = () => {
    fullReload(this.props);
  }

  render() {
    const { myStatus } = this.props;

    if (myStatus && myStatus.restaurant_id) {

      return (
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          <div
            className="menuItemHeader"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              height: "50px",
              alignItems: "center",
              fontSize: "18px",
              backgroundColor: "#FAF8EA",
              color: "#837B6C",
              paddingRight: "30px"
            }}
          >
            <EatWithMeText />
            <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
            <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
            <BackButton onClick={this.backHandler} {...this.props} />
            <FlexGrowSpace />
            <RestaurantSelector {...this.props} onReload={this.onReload} />
            <FlexGrowSpace />
            <span className="myTitle" style={{ fontSize: "24px", marginRight: "20px" }}>
              {<Translate value="admin_local.menus.tableOverview" />}
            </span>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {auth.device.platform === "browser" ?
                <HelpButton href="https://eatwithme.online/helpdesk/knowledgebase.php?category=3" /> : null}
              <UserImageButton src={getMyImageUrl()} />
            </div>
          </div>
          {featureEnabled("ntak") && this.state.open === false && !this.state.continue ?
            <OpeningDay {...this.props} onContinue={(open) => this.setState({ open: open, continue: true })} /> : null}
          <div id="main" className={"overview " + ((featureEnabled("ntak") && this.state.open === false && !this.state.continue) ? "hidden" : "")}>
            {auth.device.platform === "browser" ?
              <div className="ringing">
                <div className="ringing2">
                  {this.props.ringing.map(number => {
                    return (<div className={number.state} onClick={() => this.phoneselected(number.number)}>
                      <div className="when">{moment(number.timestamp).format("HH:mm")}</div>
                      <div className="number">{number.number}</div>
                      <div className="icon icon-phone" />
                      <div className="icon icon-cancel" onClick={(event) => { event.stopPropagation(); this.phoneremove(number.number) }} />
                      {number?.details?.name ? (<div className="name">{number.details.surname} {number.details.name}</div>) : ''}
                      {number?.details?.address ? (<div className="address">{number.details.postcode || ""} {number.details.town || ""}, {number.details.address}</div>) : ''}
                      {number?.details?.comment ? (<div className="comment">({number.details.comment})</div>) : ''}
                    </div>)
                  })}
                </div>
              </div> : null}
            <div className="bar"></div>
            <div id="editMenuItemDialog" />
            <div id="editDiscountDialog" />
            <div id="markTableMateForPaymentDialog" />
            <div id="tableMatePaidDialog" />
          </div>

          <div class="messages" />
          <AICommandBox />
          <div id="updateLabel"></div>
        </div>
      );
    } else return null;
  }

  backHandler = () => {
    const def = new $.Deferred();
    if (this.tablelayout && this.tablelayout.roomView) {
      this.tablelayout.roomClickHandler();
      def.reject();
    } else {
      def.resolve();
    }
    return def.promise();
  }

  updateDeliveryUsers = () => {
    get("adminService/" + this.props.myStatus.restaurant_id + "/couriers").done(users => {
      store.dispatch(EatWithMeActions.updateDeliveryUsers(users));
    }).fail(() => {
      auth.ajaxError = false;
    })
  }

  phoneselected = number => {
    store.dispatch(EatWithMeActions.phoneselected(number));
    this.tablelayout.selectTable(getHomeDeliveryTableNumber());
    lockTable(localStorage.tableNumberSelected, 1, afterLockTable);
    selectNewOrders();
  }

  phoneremove = number => {
    store.dispatch(EatWithMeActions.phoneremove(number));
  }

}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      deliveryUsers: props.rootReducer.deliveryUsers,
      ringing: props.rootReducer.ringing
    };
  }
  return {};
};

export default connect(mapStateToProps)(TablesOverview);
