
import React, { Component } from "react";
import { Map as MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import L from "leaflet";
import EditControl from "./EditControl";
import "leaflet-draw/dist/leaflet.draw.css";
import auth, { get } from "../js/auth";
import { FaCopy, FaPaste } from 'react-icons/fa';

if (typeof localStorage.bookingMapZoom === "undefined") {
    localStorage.bookingMapZoom = 13;
}

class AreaEditor extends Component {
    constructor(props) {
        if (!auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price) {
            auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price = [];
        }
        super(props);
        this.targetRef = React.createRef();
        this.featureGroupRef = React.createRef();
        this.state = {
            isVisible: false,
            areas: props.value ? JSON.parse(props.value) : [],
            orderAreas: auth.myStatus.restaurant_settings["enabled-features"].homedelivery.price.filter(p => p.area && p.area != props.value).map(p => JSON.parse(p.area)),
            bookingMapZoom: 13,
            init: false
        };
    }

    componentWillUnmount() {
        this.disconnectObserver();
    }

    setupIntersectionObserver() {
        if (this.observer)
            return;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);

        if (this.targetRef.current) {
            this.observer.observe(this.targetRef.current);
            this.handleIntersection = this.handleIntersection.bind(this);
        }
    }

    disconnectObserver() {
        if (this.observer && this.targetRef.current) {
            this.observer.unobserve(this.targetRef.current);
        }
    }

    handleIntersection = (entries) => {
        entries.forEach((entry) => {
            const isVisible = this.state.isVisible || entry.isIntersecting;

            this.setState({ isVisible });

            if (isVisible && this.props.onVisible) {
                this.props.onVisible();
            }
        });
    };

    componentDidMount() {
        this.setupIntersectionObserver();

        if (this.mapRef && !this.state.init) {
            this._onFeatureGroupReady();
            this.setState({ init: true })
        }
    }

    componentDidUpdate() {
        this.setupIntersectionObserver();

        if (this.mapRef && this.featureGroupRef?.leafletElement && !this.state.init) {
            this._onFeatureGroupReady();
            this.setState({ init: true })
        }
    }


    render() {
        return (
            <div ref={this.targetRef} style={{ height: "500px", width: "calc(100vw - 11rem - 100px)" }}>
                <React.Fragment>
                    {this.state.isVisible ?
                        <MapContainer key={this.props.id} ref={(ref) => (this.mapRef = ref)} style={{ height: "500px", width: "100%" }} center={[auth.myStatus.restaurant_latitude, auth.myStatus.restaurant_longitude]} zoom={Number(this.state.bookingMapZoom)} scrollWheelZoom={false}>
                            <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
                            <FeatureGroup ref={(ref2) => { this.featureGroupRef = ref2; this.state.isFeatureGroupPresent || this.setState({ isFeatureGroupPresent: true }) }} >
                                <EditControl
                                    position="topright"
                                    onCreated={this.areaCreated}
                                    onEdited={this.areaChanged}
                                    onDeleted={this.areaChanged}
                                    draw={
                                        {
                                            rectangle: true,
                                            circle: false,
                                            polygon: {
                                                allowIntersection: false
                                            },
                                            marker: false,
                                            polyline: false,
                                            circlemarker: false,
                                        }
                                    }
                                    edit={{
                                        edit: true
                                    }}
                                />
                            </FeatureGroup>
                            {this.state.orderAreas.map(f => f.map(f => {
                                return f.geometry.coordinates.map((f, i) => {
                                    return (<Polygon key={i} positions={f.map(f => [f[1], f[0]])} color="yellow" />)
                                });
                            }))}
                            <this.CopyPasteButton />
                        </MapContainer> : null}
                </React.Fragment>
            </div >
        )
    }

    areaCreated = event => {
        const value = this.featureGroupRef.leafletElement.toGeoJSON().features;
        this.setState({ areas: value });
        this.props.onChange(JSON.stringify(value));
    }

    areaChanged = event => {

        const geojsonData = this.featureGroupRef.leafletElement.toGeoJSON().features;

        this.setState({ areas: geojsonData })
        this.props.onChange(JSON.stringify(geojsonData));
    }


    _onFeatureGroupReady = () => {
        // populate the leaflet FeatureGroup with the geoJson layers

        if (this.state?.areas?.length) {
            const json = this.getGeoJson();
            let leafletGeoJSON = new L.GeoJSON(json);
            let leafletFG = this.featureGroupRef.leafletElement;

            leafletGeoJSON.eachLayer(layer => {
                leafletFG.addLayer(layer);
            });
            this.mapRef.leafletElement.fitBounds(this.featureGroupRef.leafletElement.getBounds());
        } else {
            var zipid = this.props.id.split("_");
            zipid.pop();
            zipid.push("zip");
            zipid = zipid.join("_");
            const element = document.getElementById(zipid);
            get("https://nominatim.openstreetmap.org/search?format=json&postalcode=" + element.value + "&country=HU", undefined, undefined, undefined, false, false).done(res => {
                const boundingBox = res.boundingBox;
            }).fail(() => {
                auth.ajaxError = false;
            })
        }

    };

    getGeoJson = () => {
        return {
            type: "FeatureCollection",
            features: this.state.areas ? this.state.areas : []
        }
    }

    CopyPasteButton = () => {

        const buttonStyle = {
            color: 'darkslategray',
            background: "white",
            border: 'none',
            width: "30px",
            height: "30px",
            cursor: 'pointer',
        };

        const buttonGroupStyle = {
            display: "flex",
            flexFlow: "column",
            border: "2px solid darkgray",
            position: "absolute",
            top: "156px",
            right: "10px",
            zIndex: 1000,
            background: "lightgray",
            height: "65px",
            justifyContent: "space-between"
        };
        return (
            <div style={buttonGroupStyle}>
                <button style={buttonStyle} onClick={this.onCopy}>
                    <FaCopy />
                </button>
                <button style={buttonStyle} onClick={this.onPaste}>
                    <FaPaste />
                </button>

            </div>
        );
    };

    onCopy = () => {
        navigator.clipboard.writeText("MapPolygon:" + JSON.stringify(this.state.areas));
    };

    onPaste = () => {
        navigator.clipboard.readText().then((pastedText) => {
            if (pastedText.startsWith("MapPolygon:")) {
                this.setState({ areas: JSON.parse(pastedText.substring("MapPolygon:".length)) })
                const json = this.getGeoJson();
                let leafletGeoJSON = new L.GeoJSON(json);
                let leafletFG = this.featureGroupRef.leafletElement;

                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
                this.mapRef.leafletElement.fitBounds(this.featureGroupRef.leafletElement.getBounds());
            }
        });
    };

}



export default AreaEditor;