/* eslint-disable */
export const postcodes = {
    Hungary: {

        "1011": "Budapest",
        "1012": "Budapest",
        "1013": "Budapest",
        "1014": "Budapest",
        "1015": "Budapest",
        "1016": "Budapest",
        "1021": "Budapest",
        "1022": "Budapest",
        "1023": "Budapest",
        "1024": "Budapest",
        "1025": "Budapest",
        "1026": "Budapest",
        "1027": "Budapest",
        "1028": "Budapest",
        "1029": "Budapest",
        "1031": "Budapest",
        "1032": "Budapest",
        "1033": "Budapest",
        "1034": "Budapest",
        "1035": "Budapest",
        "1036": "Budapest",
        "1037": "Budapest",
        "1038": "Budapest",
        "1039": "Budapest",
        "1041": "Budapest",
        "1042": "Budapest",
        "1043": "Budapest",
        "1044": "Budapest",
        "1045": "Budapest",
        "1046": "Budapest",
        "1047": "Budapest",
        "1048": "Budapest",
        "1051": "Budapest",
        "1052": "Budapest",
        "1053": "Budapest",
        "1054": "Budapest",
        "1055": "Budapest",
        "1056": "Budapest",
        "1061": "Budapest",
        "1062": "Budapest",
        "1063": "Budapest",
        "1064": "Budapest",
        "1065": "Budapest",
        "1066": "Budapest",
        "1067": "Budapest",
        "1068": "Budapest",
        "1071": "Budapest",
        "1072": "Budapest",
        "1073": "Budapest",
        "1074": "Budapest",
        "1075": "Budapest",
        "1076": "Budapest",
        "1077": "Budapest",
        "1078": "Budapest",
        "1081": "Budapest",
        "1082": "Budapest",
        "1083": "Budapest",
        "1084": "Budapest",
        "1085": "Budapest",
        "1086": "Budapest",
        "1087": "Budapest",
        "1088": "Budapest",
        "1089": "Budapest",
        "1091": "Budapest",
        "1092": "Budapest",
        "1093": "Budapest",
        "1094": "Budapest",
        "1095": "Budapest",
        "1096": "Budapest",
        "1097": "Budapest",
        "1098": "Budapest",
        "1101": "Budapest",
        "1102": "Budapest",
        "1103": "Budapest",
        "1104": "Budapest",
        "1105": "Budapest",
        "1106": "Budapest",
        "1107": "Budapest",
        "1108": "Budapest",
        "1111": "Budapest",
        "1112": "Budapest",
        "1113": "Budapest",
        "1114": "Budapest",
        "1115": "Budapest",
        "1116": "Budapest",
        "1117": "Budapest",
        "1118": "Budapest",
        "1119": "Budapest",
        "1121": "Budapest",
        "1122": "Budapest",
        "1123": "Budapest",
        "1124": "Budapest",
        "1125": "Budapest",
        "1126": "Budapest",
        "1131": "Budapest",
        "1132": "Budapest",
        "1133": "Budapest",
        "1134": "Budapest",
        "1135": "Budapest",
        "1136": "Budapest",
        "1137": "Budapest",
        "1138": "Budapest",
        "1139": "Budapest",
        "1141": "Budapest",
        "1142": "Budapest",
        "1143": "Budapest",
        "1144": "Budapest",
        "1145": "Budapest",
        "1146": "Budapest",
        "1147": "Budapest",
        "1148": "Budapest",
        "1149": "Budapest",
        "1151": "Budapest",
        "1152": "Budapest",
        "1153": "Budapest",
        "1154": "Budapest",
        "1155": "Budapest",
        "1156": "Budapest",
        "1157": "Budapest",
        "1158": "Budapest",
        "1161": "Budapest",
        "1162": "Budapest",
        "1163": "Budapest",
        "1164": "Budapest",
        "1165": "Budapest",
        "1171": "Budapest",
        "1172": "Budapest",
        "1173": "Budapest",
        "1174": "Budapest",
        "1181": "Budapest",
        "1182": "Budapest",
        "1183": "Budapest",
        "1184": "Budapest",
        "1185": "Budapest",
        "1186": "Budapest",
        "1188": "Budapest",
        "1191": "Budapest",
        "1192": "Budapest",
        "1193": "Budapest",
        "1194": "Budapest",
        "1195": "Budapest",
        "1196": "Budapest",
        "1201": "Budapest",
        "1202": "Budapest",
        "1203": "Budapest",
        "1204": "Budapest",
        "1205": "Budapest",
        "1211": "Budapest",
        "1212": "Budapest",
        "1213": "Budapest",
        "1214": "Budapest",
        "1215": "Budapest",
        "1221": "Budapest",
        "1222": "Budapest",
        "1223": "Budapest",
        "1224": "Budapest",
        "1225": "Budapest",
        "1237": "Budapest",
        "1238": "Budapest",
        "1239": "Budapest",
        "2000": "Szentendre",
        "2009": "Pilisszentlászló",
        "2011": "Budakalász",
        "2013": "Pomáz",
        "2014": "Csobánka",
        "2015": "Szigetmonostor",
        "2016": "Leányfalu",
        "2017": "Pócsmegyer",
        "2021": "Tahitótfalu",
        "2022": "Tahitótfalu",
        "2023": "Dunabogdány",
        "2024": "Kisoroszi",
        "2025": "Visegrád",
        "2026": "Visegrád",
        "2027": "Dömös",
        "2028": "Pilismarót",
        "2030": "Érd",
        "2038": "Sóskút",
        "2039": "Pusztazámor",
        "2040": "Budaörs",
        "2045": "Törökbálint",
        "2049": "Diósd",
        "2051": "Biatorbágy",
        "2053": "Herceghalom",
        "2060": "Bicske",
        "2063": "Óbarok",
        "2064": "Bicske",
        "2065": "Mány",
        "2066": "Szár",
        "2066": "Újbarok",
        "2067": "Szárliget",
        "2071": "Páty",
        "2072": "Zsámbék",
        "2073": "Tök",
        "2074": "Perbál",
        "2080": "Pilisjászfalu",
        "2081": "Piliscsaba",
        "2083": "Solymár",
        "2084": "Pilisszentiván",
        "2085": "Pilisvörösvár",
        "2086": "Tinnye",
        "2089": "Telki",
        "2090": "Remeteszőlős",
        "2091": "Etyek",
        "2092": "Budakeszi",
        "2093": "Budajenő",
        "2094": "Nagykovácsi",
        "2095": "Pilisszántó",
        "2096": "Üröm",
        "2097": "Pilisborosjenő",
        "2098": "Pilisszentkereszt",
        "2099": "Pilisszentkereszt",
        "2100": "Gödöllő",
        "2111": "Szada",
        "2112": "Veresegyház",
        "2113": "Erdőkertes",
        "2114": "Valkó",
        "2115": "Vácszentlászló",
        "2116": "Zsámbok",
        "2117": "Isaszeg",
        "2118": "Dány",
        "2119": "Pécel",
        "2120": "Dunakeszi",
        "2131": "Göd",
        "2132": "Göd",
        "2133": "Sződliget",
        "2134": "Sződ",
        "2135": "Csörög",
        "2141": "Csömör",
        "2142": "Nagytarcsa",
        "2143": "Csömör",
        "2144": "Kerepes",
        "2146": "Mogyoród",
        "2151": "Fót",
        "2161": "Csomád",
        "2162": "Őrbottyán",
        "2163": "Vácrátót",
        "2164": "Váchartyán",
        "2165": "Kisnémedi",
        "2166": "Püspökszilágy",
        "2167": "Vácduka",
        "2170": "Aszód",
        "2173": "Kartal",
        "2174": "Verseg",
        "2175": "Kálló",
        "2176": "Erdőkürt",
        "2177": "Erdőtarcsa",
        "2181": "Iklad",
        "2182": "Domony",
        "2183": "Galgamácsa",
        "2184": "Vácegres",
        "2185": "Váckisújfalu",
        "2191": "Bag",
        "2192": "Hévízgyörk",
        "2193": "Galgahévíz",
        "2194": "Tura",
        "2200": "Monor",
        "2209": "Péteri",
        "2211": "Vasad",
        "2212": "Csévharaszt",
        "2213": "Monorierdő",
        "2214": "Pánd",
        "2215": "Káva",
        "2216": "Bénye",
        "2217": "Gomba",
        "2220": "Vecsés",
        "2225": "Üllő",
        "2230": "Gyömrő",
        "2233": "Ecser",
        "2234": "Maglód",
        "2235": "Mende",
        "2241": "Sülysáp",
        "2243": "Kóka",
        "2244": "Úri",
        "2251": "Tápiószecső",
        "2252": "Tóalmás",
        "2253": "Tápióság",
        "2254": "Szentmártonkáta",
        "2255": "Szentlőrinckáta",
        "2300": "Ráckeve",
        "2309": "Lórév",
        "2310": "Szigetszentmiklós",
        "2314": "Halásztelek",
        "2315": "Szigethalom",
        "2316": "Tököl",
        "2317": "Szigetcsép",
        "2318": "Szigetszentmárton",
        "2319": "Szigetújfalu",
        "2321": "Szigetbecse",
        "2322": "Makád",
        "2330": "Dunaharaszti",
        "2335": "Taksony",
        "2336": "Dunavarsány",
        "2337": "Délegyháza",
        "2338": "Áporka",
        "2339": "Majosháza",
        "2340": "Apaj",
        "2344": "Dömsöd",
        "2345": "Apaj",
        "2347": "Bugyi",
        "2351": "Alsónémedi",
        "2360": "Gyál",
        "2363": "Felsőpakony",
        "2364": "Alsónémedi",
        "2365": "Inárcs",
        "2366": "Kakucs",
        "2367": "Újhartyán",
        "2370": "Dabas",
        "2371": "Dabas",
        "2373": "Dabas",
        "2375": "Tatárszentgyörgy",
        "2376": "Hernád",
        "2377": "Örkény",
        "2378": "Pusztavacs",
        "2381": "Táborfalva",
        "2400": "Dunaújváros",
        "2407": "Dunaújváros",
        "2421": "Nagyvenyim",
        "2422": "Mezőfalva",
        "2423": "Daruszentmiklós",
        "2424": "Előszállás",
        "2425": "Nagykarácsony",
        "2426": "Baracs",
        "2427": "Baracs",
        "2428": "Kisapostag",
        "2431": "Perkáta",
        "2432": "Szabadegyháza",
        "2433": "Sárosd",
        "2434": "Hantos",
        "2435": "Nagylók",
        "2440": "Százhalombatta",
        "2443": "Százhalombatta",
        "2444": "Százhalombatta",
        "2451": "Ercsi",
        "2453": "Ercsi",
        "2454": "Iváncsa",
        "2455": "Beloiannisz",
        "2456": "Besnyő",
        "2457": "Adony",
        "2458": "Kulcs",
        "2459": "Rácalmás",
        "2461": "Tárnok",
        "2462": "Martonvásár",
        "2463": "Tordas",
        "2464": "Gyúró",
        "2465": "Ráckeresztúr",
        "2471": "Baracska",
        "2472": "Kajászó",
        "2473": "Vál",
        "2475": "Kápolnásnyék",
        "2476": "Pázmánd",
        "2477": "Vereb",
        "2481": "Velence",
        "2483": "Gárdony",
        "2484": "Gárdony",
        "2485": "Gárdony",
        "2490": "Pusztaszabolcs",
        "2500": "Esztergom",
        "2508": "Esztergom",
        "2509": "Esztergom",
        "2510": "Dorog",
        "2517": "Kesztölc",
        "2518": "Leányvár",
        "2519": "Piliscsév",
        "2521": "Csolnok",
        "2522": "Dág",
        "2523": "Sárisáp",
        "2524": "Nagysáp",
        "2525": "Bajna",
        "2526": "Epöl",
        "2527": "Máriahalom",
        "2528": "Úny",
        "2529": "Annavölgy",
        "2531": "Tokod",
        "2532": "Tokodaltáró",
        "2533": "Bajót",
        "2534": "Tát",
        "2535": "Bajót",
        "2536": "Nyergesújfalu",
        "2541": "Lábatlan",
        "2543": "Süttő",
        "2544": "Neszmély",
        "2545": "Dunaalmás",
        "2600": "Vác",
        "2610": "Nőtincs",
        "2611": "Felsőpetény",
        "2612": "Kosd",
        "2613": "Rád",
        "2614": "Penc",
        "2615": "Csővár",
        "2616": "Keszeg",
        "2617": "Alsópetény",
        "2618": "Nézsa",
        "2619": "Legénd",
        "2621": "Verőce",
        "2623": "Kismaros",
        "2624": "Szokolya",
        "2625": "Kóspallag",
        "2626": "Nagymaros",
        "2627": "Zebegény",
        "2628": "Szob",
        "2629": "Márianosztra",
        "2631": "Ipolydamásd",
        "2632": "Letkés",
        "2633": "Ipolytölgyes",
        "2634": "Nagybörzsöny",
        "2635": "Vámosmikola",
        "2636": "Tésa",
        "2637": "Perőcsény",
        "2638": "Kemence",
        "2639": "Bernecebaráti",
        "2640": "Szendehely",
        "2641": "Berkenye",
        "2642": "Nógrád",
        "2643": "Diósjenő",
        "2644": "Borsosberény",
        "2645": "Nagyoroszi",
        "2646": "Drégelypalánk",
        "2647": "Hont",
        "2648": "Patak",
        "2649": "Dejtár",
        "2651": "Rétság",
        "2652": "Tereske",
        "2653": "Bánk",
        "2654": "Romhány",
        "2655": "Kétbodony",
        "2656": "Szátok",
        "2657": "Tolmács",
        "2658": "Horpács",
        "2659": "Érsekvadkert",
        "2660": "Balassagyarmat",
        "2668": "Patvarc",
        "2669": "Ipolyvece",
        "2671": "Őrhalom",
        "2672": "Hugyag",
        "2673": "Csitár",
        "2675": "Iliny",
        "2676": "Cserhátsurány",
        "2677": "Herencsény",
        "2678": "Csesztve",
        "2681": "Galgagyörk",
        "2682": "Püspökhatvan",
        "2683": "Acsa",
        "2685": "Nógrádsáp",
        "2686": "Galgaguta",
        "2687": "Bercel",
        "2688": "Vanyarc",
        "2691": "Nógrádkövesd",
        "2692": "Szécsénke",
        "2693": "Becske",
        "2694": "Cserháthaláp",
        "2696": "Terény",
        "2697": "Szanda",
        "2698": "Mohora",
        "2699": "Szügy",
        "2700": "Cegléd",
        "2711": "Tápiószentmárton",
        "2712": "Nyársapát",
        "2713": "Csemő",
        "2721": "Pilis",
        "2723": "Nyáregyháza",
        "2724": "Újlengyel",
        "2730": "Albertirsa",
        "2735": "Dánszentmiklós",
        "2736": "Mikebuda",
        "2737": "Ceglédbercel",
        "2738": "Cegléd",
        "2740": "Abony",
        "2745": "Kőröstetétlen",
        "2746": "Jászkarajenő",
        "2747": "Törtel",
        "2750": "Nagykőrös",
        "2755": "Kocsér",
        "2760": "Nagykáta",
        "2764": "Tápióbicske",
        "2765": "Farmos",
        "2766": "Tápiószele",
        "2767": "Tápiógyörgye",
        "2768": "Újszilvás",
        "2769": "Tápiószőlős",
        "2800": "Tatabánya",
        "2821": "Gyermely",
        "2822": "Szomor",
        "2823": "Vértessomló",
        "2824": "Várgesztes",
        "2831": "Tarján",
        "2832": "Héreg",
        "2833": "Vértestolna",
        "2834": "Tardos",
        "2835": "Tata",
        "2836": "Baj",
        "2837": "Vértesszőlős",
        "2840": "Oroszlány",
        "2851": "Környe",
        "2852": "Kecskéd",
        "2853": "Kömlőd",
        "2854": "Dad",
        "2855": "Bokod",
        "2856": "Szákszend",
        "2858": "Császár",
        "2859": "Vérteskethely",
        "2861": "Bakonysárkány",
        "2862": "Ácsteszér",
        "2870": "Kisbér",
        "2879": "Kisbér",
        "2881": "Ászár",
        "2882": "Kerékteleki",
        "2883": "Bársonyos",
        "2884": "Bakonyszombathely",
        "2885": "Bakonybánk",
        "2886": "Réde",
        "2887": "Ácsteszér",
        "2888": "Csatka",
        "2889": "Súr",
        "2890": "Tata",
        "2896": "Szomód",
        "2897": "Dunaszentmiklós",
        "2898": "Kocs",
        "2899": "Naszály",
        "2900": "Komárom",
        "2903": "Komárom",
        "2911": "Mocsa",
        "2921": "Komárom",
        "2931": "Almásfüzitő",
        "2941": "Ács",
        "2942": "Nagyigmánd",
        "2943": "Bábolna",
        "2944": "Bana",
        "2945": "Tárkány",
        "2946": "Csép",
        "2947": "Ete",
        "2948": "Kisigmánd",
        "2949": "Csém",
        "3000": "Hatvan",
        "3009": "Kerekharaszt",
        "3011": "Heréd",
        "3012": "Nagykökényes",
        "3013": "Ecséd",
        "3014": "Ecséd",
        "3015": "Csány",
        "3016": "Boldog",
        "3021": "Lőrinci",
        "3023": "Lőrinci",
        "3024": "Héhalom",
        "3031": "Zagyvaszántó",
        "3032": "Apc",
        "3033": "Rózsaszentmárton",
        "3034": "Szűcsi",
        "3035": "Gyöngyöspata",
        "3036": "Gyöngyöstarján",
        "3041": "Héhalom",
        "3042": "Palotás",
        "3043": "Egyházasdengeleg",
        "3044": "Szirák",
        "3045": "Bér",
        "3046": "Kisbágyon",
        "3047": "Buják",
        "3051": "Szarvasgede",
        "3052": "Csécse",
        "3053": "Ecseg",
        "3060": "Pásztó",
        "3063": "Jobbágyi",
        "3064": "Szurdokpüspöki",
        "3065": "Pásztó",
        "3066": "Bokor",
        "3067": "Felsőtold",
        "3068": "Mátraszőlős",
        "3069": "Alsótold",
        "3070": "Bátonyterenye",
        "3073": "Tar",
        "3074": "Sámsonháza",
        "3075": "Kisbárkány",
        "3077": "Mátraverebély",
        "3078": "Bátonyterenye",
        "3082": "Pásztó",
        "3100": "Salgótarján",
        "3104": "Salgótarján",
        "3109": "Salgótarján",
        "3121": "Salgótarján",
        "3123": "Cered",
        "3124": "Cered",
        "3125": "Cered",
        "3126": "Bárna",
        "3127": "Kazár",
        "3128": "Vizslás",
        "3129": "Lucfalva",
        "3131": "Sóshartyán",
        "3132": "Nógrádmegyer",
        "3133": "Magyargéc",
        "3134": "Piliny",
        "3135": "Szécsényfelfalu",
        "3136": "Etes",
        "3137": "Karancsberény",
        "3138": "Ipolytarnóc",
        "3141": "Salgótarján",
        "3142": "Kazár",
        "3143": "Mátranovák",
        "3145": "Mátraterenye",
        "3147": "Kazár",
        "3151": "Rákóczibánya",
        "3152": "Nemti",
        "3153": "Dorogháza",
        "3154": "Szuha",
        "3155": "Mátramindszent",
        "3161": "Kishartyán",
        "3162": "Ságújfalu",
        "3163": "Karancsság",
        "3165": "Endrefalva",
        "3170": "Szécsény",
        "3175": "Nagylóc",
        "3176": "Hollókő",
        "3177": "Rimóc",
        "3178": "Varsány",
        "3179": "Nógrádsipek",
        "3181": "Karancsalja",
        "3182": "Karancslapujtő",
        "3183": "Karancskeszi",
        "3184": "Mihálygerge",
        "3185": "Egyházasgerge",
        "3186": "Litke",
        "3187": "Nógrádszakál",
        "3188": "Ludányhalászi",
        "3200": "Gyöngyös",
        "3211": "Gyöngyösoroszi",
        "3212": "Gyöngyöshalász",
        "3213": "Atkár",
        "3214": "Nagyréde",
        "3221": "Gyöngyös",
        "3231": "Gyöngyössolymos",
        "3232": "Gyöngyös",
        "3233": "Gyöngyös",
        "3234": "Mátraszentimre",
        "3235": "Mátraszentimre",
        "3240": "Parád",
        "3242": "Parádsasvár",
        "3243": "Bodony",
        "3244": "Parád",
        "3245": "Recsk",
        "3246": "Mátraderecske",
        "3247": "Mátraballa",
        "3248": "Ivád",
        "3250": "Pétervására",
        "3252": "Erdőkövesd",
        "3253": "Istenmezeje",
        "3254": "Váraszó",
        "3255": "Fedémes",
        "3256": "Kisfüzes",
        "3257": "Bükkszenterzsébet",
        "3258": "Bükkszenterzsébet",
        "3259": "Szentdomonkos",
        "3261": "Abasár",
        "3262": "Markaz",
        "3263": "Domoszló",
        "3264": "Kisnána",
        "3265": "Vécs",
        "3271": "Visonta",
        "3273": "Halmajugra",
        "3274": "Ludas",
        "3275": "Detk",
        "3281": "Karácsond",
        "3282": "Nagyfüged",
        "3283": "Tarnazsadány",
        "3284": "Tarnaméra",
        "3291": "Vámosgyörk",
        "3292": "Adács",
        "3293": "Visznek",
        "3294": "Tarnaörs",
        "3295": "Erk",
        "3296": "Zaránk",
        "3300": "Eger",
        "3304": "Eger",
        "3321": "Egerbakta",
        "3322": "Hevesaranyos",
        "3323": "Mónosbél",
        "3324": "Felsőtárkány",
        "3325": "Noszvaj",
        "3326": "Ostoros",
        "3327": "Novaj",
        "3328": "Egerszólát",
        "3331": "Tarnaszentmária",
        "3332": "Sirok",
        "3334": "Szajla",
        "3335": "Bükkszék",
        "3336": "Bátor",
        "3337": "Egerbocs",
        "3341": "Egercsehi",
        "3343": "Bekölce",
        "3344": "Mikófalva",
        "3345": "Mónosbél",
        "3346": "Bélapátfalva",
        "3347": "Balaton",
        "3348": "Nagyvisnyó",
        "3349": "Nagyvisnyó",
        "3350": "Kál",
        "3351": "Verpelét",
        "3352": "Feldebrő",
        "3353": "Aldebrő",
        "3354": "Tófalu",
        "3355": "Aldebrő",
        "3356": "Kompolt",
        "3357": "Nagyút",
        "3358": "Erdőtelek",
        "3359": "Tenk",
        "3360": "Heves",
        "3368": "Boconád",
        "3369": "Tarnabod",
        "3371": "Átány",
        "3372": "Kömlő",
        "3373": "Besenyőtelek",
        "3374": "Dormánd",
        "3375": "Mezőtárkány",
        "3377": "Szihalom",
        "3378": "Mezőszemere",
        "3379": "Egerfarmos",
        "3381": "Pély",
        "3382": "Tarnaszentmiklós",
        "3383": "Hevesvezekény",
        "3384": "Kisköre",
        "3385": "Tiszanána",
        "3386": "Sarud",
        "3387": "Újlőrincfalva",
        "3388": "Poroszló",
        "3390": "Füzesabony",
        "3394": "Egerszalók",
        "3395": "Demjén",
        "3396": "Kerecsend",
        "3397": "Maklár",
        "3398": "Nagytálya",
        "3399": "Andornaktálya",
        "3400": "Mezőkövesd",
        "3411": "Szomolya",
        "3412": "Bogács",
        "3413": "Cserépfalu",
        "3414": "Bükkzsérc",
        "3416": "Tard",
        "3417": "Cserépváralja",
        "3418": "Szentistván",
        "3421": "Mezőnyárád",
        "3422": "Bükkábrány",
        "3423": "Tibolddaróc",
        "3424": "Kács",
        "3425": "Sály",
        "3426": "Borsodgeszt",
        "3431": "Vatta",
        "3432": "Emőd",
        "3433": "Nyékládháza",
        "3434": "Mályi",
        "3441": "Mezőkeresztes",
        "3442": "Csincse",
        "3443": "Mezőnagymihály",
        "3444": "Gelej",
        "3450": "Mezőcsát",
        "3458": "Tiszakeszi",
        "3459": "Igrici",
        "3461": "Egerlövő",
        "3462": "Borsodivánka",
        "3463": "Négyes",
        "3464": "Tiszavalk",
        "3465": "Tiszabábolna",
        "3466": "Tiszadorogma",
        "3467": "Ároktő",
        "3500": "Miskolc",
        "3501": "Miskolc",
        "3510": "Miskolc",
        "3511": "Miskolc",
        "3516": "Miskolc",
        "3517": "Miskolc",
        "3518": "Miskolc",
        "3519": "Miskolc",
        "3521": "Miskolc",
        "3525": "Miskolc",
        "3527": "Miskolc",
        "3528": "Miskolc",
        "3529": "Miskolc",
        "3531": "Miskolc",
        "3532": "Miskolc",
        "3533": "Miskolc",
        "3534": "Miskolc",
        "3535": "Miskolc",
        "3551": "Ónod",
        "3552": "Muhi",
        "3553": "Kistokaj",
        "3554": "Bükkaranyos",
        "3555": "Harsány",
        "3556": "Kisgyőr",
        "3557": "Bükkszentkereszt",
        "3558": "Miskolc",
        "3559": "Répáshuta",
        "3561": "Felsőzsolca",
        "3562": "Onga",
        "3563": "Gesztely",
        "3564": "Hernádnémeti",
        "3565": "Tiszalúc",
        "3571": "Alsózsolca",
        "3572": "Sajólád",
        "3573": "Sajópetri",
        "3574": "Bőcs",
        "3575": "Berzék",
        "3576": "Sajóhídvég",
        "3577": "Köröm",
        "3578": "Girincs",
        "3579": "Kesznyéten",
        "3580": "Tiszaújváros",
        "3586": "Sajóörös",
        "3587": "Tiszapalkonya",
        "3588": "Hejőkürt",
        "3589": "Tiszatarján",
        "3591": "Oszlár",
        "3592": "Nemesbikk",
        "3593": "Hejőbába",
        "3594": "Hejőpapi",
        "3595": "Hejőszalonta",
        "3596": "Szakáld",
        "3597": "Hejőkeresztúr",
        "3598": "Nagycsécs",
        "3599": "Sajószöged",
        "3600": "Ózd",
        "3604": "Ózd",
        "3608": "Farkaslyuk",
        "3621": "Ózd",
        "3622": "Uppony",
        "3623": "Borsodszentgyörgy",
        "3626": "Hangony",
        "3627": "Domaháza",
        "3630": "Putnok",
        "3635": "Dubicsány",
        "3636": "Sajógalgóc",
        "3641": "Nagybarca",
        "3642": "Bánhorváti",
        "3643": "Dédestapolcsány",
        "3644": "Tardona",
        "3645": "Mályinka",
        "3646": "Nekézseny",
        "3647": "Csokvaomány",
        "3648": "Bükkmogyorósd",
        "3651": "Ózd",
        "3652": "Sajónémeti",
        "3653": "Sajópüspöki",
        "3654": "Bánréve",
        "3655": "Hét",
        "3656": "Sajómercse",
        "3657": "Királd",
        "3658": "Borsodbóta",
        "3659": "Sáta",
        "3661": "Ózd",
        "3663": "Arló",
        "3664": "Járdánháza",
        "3671": "Borsodnádasd",
        "3672": "Borsodnádasd",
        "3700": "Kazincbarcika",
        "3704": "Berente",
        "3711": "Szirmabesenyő",
        "3712": "Sajósenye",
        "3713": "Arnót",
        "3714": "Sajópálfala",
        "3715": "Gesztely",
        "3716": "Sóstófalva",
        "3717": "Alsódobsza",
        "3718": "Megyaszó",
        "3720": "Sajóivánka",
        "3721": "Dövény",
        "3722": "Felsőkelecsény",
        "3723": "Zubogy",
        "3724": "Imola",
        "3726": "Alsószuha",
        "3728": "Gömörszőlős",
        "3729": "Serényfalva",
        "3731": "Szuhakálló",
        "3732": "Kurityán",
        "3733": "Rudabánya",
        "3734": "Szuhogy",
        "3735": "Alsótelekes",
        "3741": "Izsófalva",
        "3742": "Rudolftelep",
        "3743": "Ormosbánya",
        "3744": "Múcsony",
        "3751": "Szendrő",
        "3752": "Abod",
        "3753": "Abod",
        "3754": "Meszes",
        "3755": "Martonyi",
        "3756": "Perkupa",
        "3757": "Égerszög",
        "3758": "Jósvafő",
        "3759": "Aggtelek",
        "3761": "Szin",
        "3762": "Szögliget",
        "3763": "Bódvaszilas",
        "3764": "Bódvarákó",
        "3765": "Komjáti",
        "3767": "Tornanádaska",
        "3768": "Becskeháza",
        "3769": "Tornaszentjakab",
        "3770": "Sajószentpéter",
        "3773": "Sajókápolna",
        "3775": "Kondó",
        "3776": "Radostyán",
        "3777": "Parasznya",
        "3778": "Varbó",
        "3779": "Alacska",
        "3780": "Balajt",
        "3786": "Hegymeg",
        "3787": "Tomor",
        "3791": "Sajókeresztúr",
        "3792": "Sajóbábony",
        "3793": "Sajóecseg",
        "3794": "Boldva",
        "3795": "Hangács",
        "3796": "Borsodszirák",
        "3800": "Szikszó",
        "3809": "Abaújszolnok",
        "3811": "Alsóvadász",
        "3812": "Homrogd",
        "3813": "Kupa",
        "3814": "Felsővadász",
        "3815": "Abaújlak",
        "3816": "Gagyvendégi",
        "3817": "Gagybátor",
        "3821": "Büttös",
        "3825": "Debréte",
        "3826": "Rakacaszend",
        "3831": "Kázsmárk",
        "3832": "Léh",
        "3833": "Rásonysápberencs",
        "3834": "Beret",
        "3836": "Baktakék",
        "3837": "Alsógagy",
        "3841": "Aszaló",
        "3842": "Halmaj",
        "3843": "Kiskinizs",
        "3844": "Nagykinizs",
        "3846": "Hernádkércs",
        "3847": "Felsődobsza",
        "3848": "Csobád",
        "3849": "Forró",
        "3851": "Ináncs",
        "3852": "Hernádszentandrás",
        "3853": "Hernádbűd",
        "3854": "Gibárt",
        "3855": "Fancsal",
        "3860": "Abaújkér",
        "3863": "Szalaszend",
        "3864": "Fulókércs",
        "3865": "Fáj",
        "3866": "Litka",
        "3871": "Méra",
        "3872": "Novajidrány",
        "3873": "Garadna",
        "3874": "Hernádpetri",
        "3875": "Hernádszurdok",
        "3876": "Hidasnémeti",
        "3877": "Tornyosnémeti",
        "3881": "Abaújszántó",
        "3882": "Abaújalpár",
        "3884": "Boldogkőújfalu",
        "3885": "Arka",
        "3886": "Korlát",
        "3887": "Hernádcéce",
        "3888": "Vizsoly",
        "3891": "Vilmány",
        "3892": "Hejce",
        "3893": "Fony",
        "3894": "Göncruszka",
        "3895": "Gönc",
        "3896": "Telkibánya",
        "3897": "Zsujta",
        "3898": "Abaújvár",
        "3899": "Kéked",
        "3900": "Szerencs",
        "3903": "Bekecs",
        "3904": "Legyesbénye",
        "3905": "Monok",
        "3906": "Golop",
        "3907": "Tállya",
        "3908": "Rátka",
        "3909": "Mád",
        "3910": "Tokaj",
        "3915": "Tarcal",
        "3916": "Bodrogkeresztúr",
        "3917": "Bodrogkisfalud",
        "3918": "Szegi",
        "3921": "Taktaszada",
        "3922": "Taktaharkány",
        "3924": "Taktakenéz",
        "3925": "Prügy",
        "3926": "Taktabáj",
        "3927": "Csobaj",
        "3928": "Tiszatardos",
        "3929": "Tiszaladány",
        "3931": "Mezőzombor",
        "3932": "Erdőbénye",
        "3933": "Olaszliszka",
        "3934": "Tolcsva",
        "3935": "Erdőhorváti",
        "3936": "Háromhuta",
        "3937": "Komlóska",
        "3941": "Vámosújfalu",
        "3942": "Sárazsadány",
        "3943": "Bodrogolaszi",
        "3944": "Sátoraljaújhely",
        "3945": "Sátoraljaújhely",
        "3950": "Sárospatak",
        "3954": "Györgytarló",
        "3955": "Kenézlő",
        "3956": "Viss",
        "3957": "Zalkod",
        "3958": "Hercegkút",
        "3959": "Makkoshotyka",
        "3961": "Sárospatak",
        "3962": "Karos",
        "3963": "Karcsa",
        "3964": "Pácin",
        "3965": "Kisrozvágy",
        "3967": "Lácacséke",
        "3971": "Tiszakarád",
        "3972": "Tiszacsermely",
        "3973": "Cigánd",
        "3974": "Ricse",
        "3976": "Révleányvár",
        "3977": "Zemplénagárd",
        "3978": "Dámóc",
        "3980": "Sárospatak",
        "3985": "Alsóberecki",
        "3987": "Bodroghalom",
        "3988": "Sátoraljaújhely",
        "3989": "Alsóregmec",
        "3991": "Vilyvitány",
        "3992": "Kovácsvágás",
        "3993": "Füzérradvány",
        "3994": "Bózsva",
        "3995": "Pusztafalu",
        "3996": "Füzér",
        "3997": "Füzérkomlós",
        "3999": "Hollóháza",
        "4000": "Debrecen",
        "4002": "Debrecen",
        "4014": "Debrecen",
        "4060": "Balmazújváros",
        "4063": "Balmazújváros",
        "4064": "Nagyhegyes",
        "4065": "Újszentmargita",
        "4066": "Tiszacsege",
        "4067": "Egyek",
        "4069": "Egyek",
        "4071": "Hortobágy",
        "4074": "Hajdúböszörmény",
        "4075": "Görbeháza",
        "4078": "Debrecen",
        "4079": "Debrecen",
        "4080": "Hajdúnánás",
        "4085": "Hajdúnánás",
        "4086": "Hajdúböszörmény",
        "4087": "Hajdúdorog",
        "4090": "Folyás",
        "4095": "Folyás",
        "4096": "Újtikos",
        "4097": "Tiszagyulaháza",
        "4100": "Berettyóújfalu",
        "4110": "Biharkeresztes",
        "4114": "Bojt",
        "4115": "Ártánd",
        "4116": "Berekböszörmény",
        "4117": "Told",
        "4118": "Mezőpeterd",
        "4119": "Váncsod",
        "4121": "Szentpéterszeg",
        "4122": "Gáborján",
        "4123": "Hencida",
        "4124": "Esztár",
        "4125": "Pocsaj",
        "4126": "Kismarja",
        "4127": "Nagykereki",
        "4128": "Bedő",
        "4130": "Derecske",
        "4132": "Tépe",
        "4133": "Hosszúpályi",
        "4134": "Körösszegapáti",
        "4135": "Körösszegapáti",
        "4136": "Körösszakál",
        "4137": "Magyarhomorog",
        "4138": "Komádi",
        "4141": "Furta",
        "4142": "Zsáka",
        "4143": "Vekerd",
        "4144": "Darvas",
        "4145": "Csökmő",
        "4146": "Újiráz",
        "4150": "Püspökladány",
        "4161": "Báránd",
        "4162": "Szerep",
        "4163": "Szerep",
        "4164": "Bakonszeg",
        "4171": "Sárrétudvari",
        "4172": "Biharnagybajom",
        "4173": "Nagyrábé",
        "4174": "Bihartorda",
        "4175": "Bihardancsháza",
        "4176": "Sáp",
        "4177": "Földes",
        "4181": "Hajdúszoboszló",
        "4183": "Kaba",
        "4184": "Tetétlen",
        "4200": "Hajdúszoboszló",
        "4211": "Ebes",
        "4212": "Hajdúszovát",
        "4220": "Hajdúböszörmény",
        "4224": "Hajdúböszörmény",
        "4225": "Debrecen",
        "4231": "Bököny",
        "4232": "Geszteréd",
        "4233": "Balkány",
        "4234": "Szakoly",
        "4235": "Biri",
        "4241": "Bocskaikert",
        "4242": "Hajdúhadház",
        "4243": "Téglás",
        "4244": "Érpatak",
        "4245": "Érpatak",
        "4246": "Nyíregyháza",
        "4251": "Hajdúsámson",
        "4252": "Nyíradony",
        "4253": "Nyíradony",
        "4254": "Nyíradony",
        "4262": "Nyíracsád",
        "4263": "Nyírmártonfalva",
        "4264": "Bagamér",
        "4266": "Fülöp",
        "4267": "Penészlek",
        "4271": "Mikepércs",
        "4272": "Sáránd",
        "4273": "Hajdúbagos",
        "4274": "Hosszúpályi",
        "4275": "Monostorpályi",
        "4281": "Létavértes",
        "4283": "Létavértes",
        "4284": "Kokad",
        "4285": "Álmosd",
        "4286": "Bagamér",
        "4287": "Vámospércs",
        "4288": "Újléta",
        "4300": "Nyírbátor",
        "4311": "Nyírgyulaj",
        "4320": "Nagykálló",
        "4324": "Kállósemjén",
        "4325": "Kisléta",
        "4326": "Máriapócs",
        "4327": "Pócspetri",
        "4331": "Nyírcsászári",
        "4332": "Nyírderzs",
        "4333": "Nyírkáta",
        "4334": "Hodász",
        "4335": "Kántorjánosi",
        "4336": "Őr",
        "4337": "Jármi",
        "4338": "Papos",
        "4341": "Nyírvasvári",
        "4342": "Terem",
        "4343": "Bátorliget",
        "4351": "Vállaj",
        "4352": "Mérk",
        "4353": "Tiborszállás",
        "4354": "Fábiánháza",
        "4355": "Nagyecsed",
        "4356": "Nyírcsaholy",
        "4361": "Nyírbogát",
        "4362": "Nyírgelse",
        "4363": "Nyírmihálydi",
        "4371": "Nyírlugos",
        "4372": "Nyírbéltek",
        "4373": "Ömböly",
        "4374": "Encsencs",
        "4375": "Nyírbátor",
        "4376": "Nyírpilis",
        "4400": "Nyíregyháza",
        "4431": "Nyíregyháza",
        "4432": "Nyíregyháza",
        "4433": "Nyíregyháza",
        "4434": "Kálmánháza",
        "4440": "Tiszavasvári",
        "4441": "Szorgalmatos",
        "4445": "Nagycserkesz",
        "4446": "Tiszaeszlár",
        "4447": "Tiszalök",
        "4450": "Tiszalök",
        "4455": "Tiszadada",
        "4456": "Tiszadob",
        "4461": "Gávavencsellő",
        "4463": "Tiszanagyfalu",
        "4464": "Tiszaeszlár",
        "4465": "Rakamaz",
        "4466": "Timár",
        "4467": "Szabolcs",
        "4468": "Balsa",
        "4471": "Gávavencsellő",
        "4474": "Tiszabercel",
        "4475": "Paszab",
        "4481": "Nyíregyháza",
        "4482": "Kótaj",
        "4483": "Buj",
        "4484": "Ibrány",
        "4485": "Nagyhalász",
        "4486": "Nagyhalász",
        "4487": "Tiszatelek",
        "4488": "Beszterec",
        "4491": "Dombrád",
        "4492": "Dombrád",
        "4493": "Tiszakanyár",
        "4494": "Kékcse",
        "4495": "Döge",
        "4496": "Szabolcsveresmart",
        "4501": "Kemecse",
        "4502": "Vasmegyer",
        "4503": "Tiszarád",
        "4511": "Nyírbogdány",
        "4512": "Nyírbogdány",
        "4515": "Kék",
        "4516": "Demecser",
        "4517": "Gégény",
        "4521": "Berkesz",
        "4522": "Nyírtass",
        "4523": "Pátroha",
        "4524": "Ajak",
        "4525": "Rétközberencs",
        "4531": "Nyírpazony",
        "4532": "Nyírtura",
        "4533": "Sényő",
        "4534": "Székely",
        "4535": "Nyíribrony",
        "4536": "Ramocsaháza",
        "4537": "Nyírkércs",
        "4541": "Nyírjákó",
        "4542": "Petneháza",
        "4543": "Laskod",
        "4544": "Nyírkarász",
        "4545": "Gyulaháza",
        "4546": "Anarcs",
        "4547": "Szabolcsbáka",
        "4551": "Nyíregyháza",
        "4552": "Napkor",
        "4553": "Apagy",
        "4554": "Nyírtét",
        "4555": "Levelek",
        "4556": "Magy",
        "4557": "Besenyőd",
        "4558": "Ófehértó",
        "4561": "Baktalórántháza",
        "4562": "Vaja",
        "4563": "Rohod",
        "4564": "Nyírmada",
        "4565": "Pusztadobos",
        "4566": "Ilk",
        "4567": "Gemzse",
        "4600": "Kisvárda",
        "4611": "Jéke",
        "4621": "Fényeslitke",
        "4622": "Komoró",
        "4623": "Tuzsér",
        "4624": "Tiszabezdéd",
        "4625": "Győröcske",
        "4627": "Zsurk",
        "4628": "Tiszaszentmárton",
        "4631": "Pap",
        "4632": "Nyírlövő",
        "4633": "Lövőpetri",
        "4634": "Aranyosapáti",
        "4635": "Újkenéz",
        "4641": "Mezőladány",
        "4642": "Tornyospálca",
        "4643": "Benk",
        "4644": "Mándok",
        "4645": "Tiszamogyorós",
        "4646": "Eperjeske",
        "4700": "Mátészalka",
        "4721": "Szamoskér",
        "4722": "Nyírmeggyes",
        "4731": "Tunyogmatolcs",
        "4732": "Cégénydányád",
        "4733": "Gyügye",
        "4734": "Szamosújlak",
        "4735": "Hermánszeg",
        "4737": "Darnó",
        "4741": "Jánkmajtis",
        "4742": "Csegöld",
        "4743": "Csengersima",
        "4745": "Szamosbecs",
        "4746": "Szamostatárfalva",
        "4751": "Kocsord",
        "4752": "Győrtelek",
        "4754": "Fülpösdaróc",
        "4755": "Ököritófülpös",
        "4756": "Rápolt",
        "4761": "Porcsalma",
        "4762": "Tyukod",
        "4763": "Ura",
        "4764": "Csengerújfalu",
        "4765": "Csenger",
        "4766": "Pátyod",
        "4767": "Szamosangyalos",
        "4800": "Vásárosnamény",
        "4803": "Vásárosnamény",
        "4804": "Vásárosnamény",
        "4811": "Kisvarsány",
        "4812": "Nagyvarsány",
        "4813": "Gyüre",
        "4821": "Ópályi",
        "4822": "Nyírparasznya",
        "4823": "Nagydobos",
        "4824": "Szamosszeg",
        "4826": "Olcsva",
        "4831": "Tiszaszalka",
        "4832": "Tiszavid",
        "4833": "Tiszaadony",
        "4834": "Tiszakerecseny",
        "4835": "Mátyus",
        "4836": "Lónya",
        "4841": "Jánd",
        "4842": "Gulács",
        "4843": "Hetefejércse",
        "4844": "Csaroda",
        "4845": "Tákos",
        "4900": "Fehérgyarmat",
        "4911": "Nábrád",
        "4912": "Kérsemjén",
        "4913": "Panyola",
        "4914": "Olcsvaapáti",
        "4921": "Kisar",
        "4922": "Nagyar",
        "4931": "Tarpa",
        "4932": "Márokpapi",
        "4933": "Beregsurány",
        "4934": "Beregdaróc",
        "4935": "Gelénes",
        "4936": "Vámosatya",
        "4937": "Barabás",
        "4941": "Penyige",
        "4942": "Mánd",
        "4943": "Kömörő",
        "4944": "Túristvándi",
        "4945": "Szatmárcseke",
        "4946": "Tiszakóród",
        "4947": "Tiszacsécse",
        "4948": "Milota",
        "4951": "Tiszabecs",
        "4952": "Uszka",
        "4953": "Magosliget",
        "4954": "Sonkád",
        "4955": "Botpalád",
        "4956": "Kispalád",
        "4961": "Zsarolyán",
        "4962": "Nagyszekeres",
        "4963": "Kisszekeres",
        "4964": "Fülesd",
        "4965": "Kölcse",
        "4966": "Vámosoroszi",
        "4967": "Csaholc",
        "4968": "Túrricse",
        "4969": "Tisztaberek",
        "4971": "Rozsály",
        "4972": "Gacsály",
        "4973": "Császló",
        "4974": "Zajta",
        "4975": "Méhtelek",
        "4976": "Garbolc",
        "4977": "Kishódos",
        "5000": "Szolnok",
        "5002": "Besenyszög",
        "5008": "Szolnok",
        "5051": "Zagyvarékas",
        "5052": "Újszász",
        "5053": "Szászberek",
        "5054": "Jászalsószentgyörgy",
        "5055": "Jászladány",
        "5061": "Tiszasüly",
        "5062": "Kőtelek",
        "5063": "Hunyadfalva",
        "5064": "Csataszög",
        "5065": "Nagykörű",
        "5071": "Besenyszög",
        "5081": "Szajol",
        "5082": "Tiszatenyő",
        "5083": "Kengyel",
        "5084": "Rákócziújfalu",
        "5085": "Rákóczifalva",
        "5091": "Tószeg",
        "5092": "Tiszavárkony",
        "5093": "Vezseny",
        "5094": "Tiszajenő",
        "5095": "Tiszavárkony",
        "5100": "Jászberény",
        "5111": "Jászfelsőszentgyörgy",
        "5121": "Jászjákóhalma",
        "5122": "Jászdózsa",
        "5123": "Jászárokszállás",
        "5124": "Jászágó",
        "5125": "Pusztamonostor",
        "5126": "Jászfényszaru",
        "5130": "Jászapáti",
        "5135": "Jászivány",
        "5136": "Jászszentandrás",
        "5137": "Jászkisér",
        "5141": "Jásztelek",
        "5142": "Alattyán",
        "5143": "Jánoshida",
        "5144": "Jászboldogháza",
        "5152": "Jászberény",
        "5200": "Törökszentmiklós",
        "5211": "Tiszapüspöki",
        "5212": "Törökszentmiklós",
        "5222": "Örményes",
        "5231": "Fegyvernek",
        "5232": "Tiszabő",
        "5233": "Tiszagyenda",
        "5234": "Tiszaroff",
        "5235": "Tiszabura",
        "5241": "Abádszalók",
        "5242": "Abádszalók",
        "5243": "Tiszaderzs",
        "5244": "Tiszaszőlős",
        "5245": "Tiszaszőlős",
        "5246": "Tiszaszőlős",
        "5300": "Karcag",
        "5309": "Berekfürdő",
        "5310": "Kisújszállás",
        "5321": "Kunmadaras",
        "5322": "Tiszaszentimre",
        "5323": "Tiszaszentimre",
        "5324": "Tomajmonostora",
        "5331": "Kenderes",
        "5340": "Kunhegyes",
        "5349": "Kenderes",
        "5350": "Tiszafüred",
        "5359": "Tiszafüred",
        "5361": "Tiszaigar",
        "5362": "Tiszaörs",
        "5363": "Nagyiván",
        "5400": "Mesterszállás",
        "5411": "Kétpó",
        "5412": "Kuncsorba",
        "5420": "Túrkeve",
        "5430": "Tiszaföldvár",
        "5435": "Martfű",
        "5440": "Kunszentmárton",
        "5449": "Kunszentmárton",
        "5451": "Öcsöd",
        "5452": "Mesterszállás",
        "5453": "Mezőhék",
        "5461": "Cibakháza",
        "5462": "Cibakháza",
        "5463": "Nagyrév",
        "5464": "Tiszainoka",
        "5465": "Cserkeszőlő",
        "5471": "Tiszakürt",
        "5472": "Tiszakürt",
        "5474": "Tiszasas",
        "5475": "Csépa",
        "5476": "Szelevény",
        "5500": "Gyomaendrőd",
        "5502": "Gyomaendrőd",
        "5510": "Dévaványa",
        "5515": "Ecsegfalva",
        "5516": "Körösladány",
        "5520": "Szeghalom",
        "5525": "Füzesgyarmat",
        "5526": "Kertészsziget",
        "5527": "Bucsa",
        "5530": "Vésztő",
        "5534": "Okány",
        "5536": "Körösújfalu",
        "5537": "Zsadány",
        "5538": "Biharugra",
        "5539": "Körösnagyharsány",
        "5540": "Szarvas",
        "5551": "Csabacsűd",
        "5552": "Kardos",
        "5553": "Kondoros",
        "5555": "Hunya",
        "5556": "Örménykút",
        "5561": "Békésszentandrás",
        "5600": "Békéscsaba",
        "5609": "Csabaszabadi",
        "5621": "Csárdaszállás",
        "5622": "Köröstarcsa",
        "5623": "Békéscsaba",
        "5624": "Doboz",
        "5630": "Békés",
        "5641": "Tarhos",
        "5643": "Bélmegyer",
        "5650": "Mezőberény",
        "5661": "Újkígyós",
        "5662": "Csanádapáca",
        "5663": "Medgyesbodzás",
        "5664": "Medgyesbodzás",
        "5665": "Pusztaottlaka",
        "5666": "Medgyesegyháza",
        "5667": "Magyarbánhegyes",
        "5668": "Nagybánhegyes",
        "5671": "Békéscsaba",
        "5672": "Murony",
        "5673": "Kamut",
        "5674": "Kétsoprony",
        "5675": "Telekgerendás",
        "5700": "Gyula",
        "5711": "Gyula",
        "5712": "Szabadkígyós",
        "5720": "Sarkad",
        "5725": "Kötegyán",
        "5726": "Méhkerék",
        "5727": "Újszalonta",
        "5731": "Sarkadkeresztúr",
        "5732": "Geszt",
        "5734": "Geszt",
        "5741": "Kétegyháza",
        "5742": "Elek",
        "5743": "Lőkösháza",
        "5744": "Kevermes",
        "5745": "Dombiratos",
        "5746": "Kunágota",
        "5747": "Almáskamarás",
        "5751": "Nagykamarás",
        "5752": "Medgyesegyháza",
        "5800": "Mezőkovácsháza",
        "5811": "Végegyháza",
        "5820": "Mezőhegyes",
        "5830": "Battonya",
        "5836": "Dombegyház",
        "5837": "Kisdombegyház",
        "5838": "Magyardombegyház",
        "5900": "Orosháza",
        "5903": "Orosháza",
        "5911": "Orosháza",
        "5919": "Pusztaföldvár",
        "5920": "Csorvás",
        "5925": "Gerendás",
        "5931": "Nagyszénás",
        "5932": "Gádoros",
        "5940": "Tótkomlós",
        "5945": "Kardoskút",
        "5946": "Békéssámson",
        "5948": "Kaszaper",
        "6000": "Kecskemét",
        "6008": "Kecskemét",
        "6031": "Szentkirály",
        "6032": "Nyárlőrinc",
        "6033": "Városföld",
        "6034": "Helvécia",
        "6035": "Ballószög",
        "6041": "Kerekegyháza",
        "6042": "Fülöpháza",
        "6043": "Kunbaracs",
        "6044": "Kecskemét",
        "6045": "Ladánybene",
        "6050": "Felsőlajos",
        "6055": "Felsőlajos",
        "6060": "Tiszakécske",
        "6062": "Tiszakécske",
        "6064": "Tiszaug",
        "6065": "Lakitelek",
        "6066": "Tiszaalpár",
        "6067": "Tiszaalpár",
        "6070": "Izsák",
        "6075": "Páhi",
        "6076": "Ágasegyháza",
        "6077": "Orgovány",
        "6078": "Jakabszállás",
        "6080": "Szabadszállás",
        "6085": "Fülöpszállás",
        "6086": "Szalkszentmárton",
        "6087": "Dunavecse",
        "6088": "Apostag",
        "6090": "Kunszentmiklós",
        "6096": "Kunpeszér",
        "6097": "Kunadacs",
        "6098": "Tass",
        "6100": "Kiskunfélegyháza",
        "6111": "Gátér",
        "6112": "Pálmonostora",
        "6113": "Petőfiszállás",
        "6114": "Bugac",
        "6115": "Kunszállás",
        "6116": "Fülöpjakab",
        "6120": "Kiskunmajsa",
        "6131": "Szank",
        "6132": "Móricgát",
        "6133": "Jászszentlászló",
        "6134": "Kömpöc",
        "6135": "Csólyospálos",
        "6136": "Harkakötöny",
        "6200": "Kiskőrös",
        "6211": "Kaskantyú",
        "6221": "Akasztó",
        "6222": "Csengőd",
        "6223": "Soltszentimre",
        "6224": "Tabdi",
        "6230": "Soltvadkert",
        "6235": "Bócsa",
        "6236": "Tázlár",
        "6237": "Kecel",
        "6238": "Imrehegy",
        "6239": "Császártöltés",
        "6300": "Foktő",
        "6311": "Öregcsertő",
        "6320": "Dunatetétlen",
        "6321": "Újsolt",
        "6323": "Dunaegyháza",
        "6325": "Dunatetétlen",
        "6326": "Harta",
        "6327": "Harta",
        "6328": "Dunapataj",
        "6331": "Foktő",
        "6332": "Uszód",
        "6333": "Dunaszentbenedek",
        "6334": "Géderlak",
        "6335": "Ordas",
        "6336": "Szakmár",
        "6337": "Újtelek",
        "6341": "Homokmégy",
        "6342": "Drágszél",
        "6343": "Miske",
        "6344": "Hajós",
        "6345": "Nemesnádudvar",
        "6346": "Sükösd",
        "6347": "Érsekcsanád",
        "6348": "Érsekhalma",
        "6351": "Bátya",
        "6352": "Fajsz",
        "6353": "Dusnok",
        "6400": "Kiskunhalas",
        "6411": "Zsana",
        "6412": "Balotaszállás",
        "6413": "Kunfehértó",
        "6414": "Pirtó",
        "6421": "Kisszállás",
        "6422": "Tompa",
        "6423": "Kelebia",
        "6424": "Csikéria",
        "6425": "Bácsszőlős",
        "6430": "Bácsalmás",
        "6435": "Kunbaja",
        "6440": "Jánoshalma",
        "6444": "Kéleshalom",
        "6445": "Borota",
        "6446": "Rém",
        "6447": "Felsőszentiván",
        "6448": "Csávoly",
        "6449": "Mélykút",
        "6451": "Tataháza",
        "6452": "Mátételke",
        "6453": "Bácsbokod",
        "6454": "Bácsborsód",
        "6455": "Katymár",
        "6456": "Madaras",
        "6500": "Baja",
        "6511": "Bácsszentgyörgy",
        "6512": "Szeremle",
        "6513": "Dunafalva",
        "6521": "Vaskút",
        "6522": "Gara",
        "6523": "Csátalja",
        "6524": "Dávod",
        "6525": "Hercegszántó",
        "6527": "Nagybaracska",
        "6528": "Bátmonostor",
        "6600": "Szentes",
        "6612": "Nagytőke",
        "6621": "Derekegyház",
        "6622": "Nagymágocs",
        "6623": "Árpádhalom",
        "6624": "Eperjes",
        "6625": "Fábiánsebestyén",
        "6630": "Mindszent",
        "6635": "Szegvár",
        "6636": "Szakmár",
        "6640": "Csongrád",
        "6645": "Felgyő",
        "6646": "Tömörkény",
        "6647": "Csanytelek",
        "6648": "Csongrád",
        "6700": "Szeged",
        "6710": "Szeged",
        "6725": "Szeged",
        "6726": "Szeged",
        "6728": "Szeged",
        "6729": "Szeged",
        "6750": "Algyő",
        "6753": "Szeged",
        "6754": "Újszentiván",
        "6755": "Kübekháza",
        "6756": "Tiszasziget",
        "6757": "Szeged",
        "6758": "Röszke",
        "6760": "Kistelek",
        "6762": "Sándorfalva",
        "6763": "Szatymaz",
        "6764": "Balástya",
        "6765": "Csengele",
        "6766": "Dóc",
        "6767": "Ópusztaszer",
        "6768": "Baks",
        "6769": "Pusztaszer",
        "6771": "Szeged",
        "6772": "Deszk",
        "6773": "Klárafalva",
        "6774": "Ferencszállás",
        "6775": "Kiszombor",
        "6781": "Domaszék",
        "6782": "Mórahalom",
        "6783": "Ásotthalom",
        "6784": "Öttömös",
        "6785": "Pusztamérges",
        "6786": "Ruzsa",
        "6787": "Zákányszék",
        "6791": "Szeged",
        "6792": "Zsombó",
        "6793": "Forráskút",
        "6794": "Üllés",
        "6795": "Bordány",
        "6800": "Hódmezővásárhely",
        "6806": "Hódmezővásárhely",
        "6821": "Székkutas",
        "6900": "Makó",
        "6903": "Makó",
        "6911": "Királyhegyes",
        "6912": "Kövegy",
        "6913": "Csanádpalota",
        "6914": "Pitvaros",
        "6915": "Csanádalberti",
        "6916": "Ambrózfalva",
        "6917": "Nagyér",
        "6921": "Maroslele",
        "6922": "Földeák",
        "6923": "Óföldeák",
        "6931": "Apátfalva",
        "6932": "Magyarcsanád",
        "6933": "Nagylak",
        "7000": "Sárbogárd",
        "7003": "Sárbogárd",
        "7011": "Alap",
        "7012": "Alsószentiván",
        "7013": "Cece",
        "7014": "Sáregres",
        "7015": "Igar",
        "7016": "Igar",
        "7017": "Mezőszilas",
        "7018": "Sárbogárd",
        "7019": "Sárbogárd",
        "7020": "Dunaföldvár",
        "7025": "Bölcske",
        "7026": "Madocsa",
        "7027": "Paks",
        "7030": "Bölcske",
        "7038": "Pusztahencse",
        "7039": "Németkér",
        "7041": "Vajta",
        "7042": "Pálfa",
        "7043": "Bikács",
        "7044": "Nagydorog",
        "7045": "Györköny",
        "7047": "Sárszentlőrinc",
        "7051": "Kajdacs",
        "7052": "Kölesd",
        "7054": "Szedres",
        "7056": "Szedres",
        "7057": "Medina",
        "7061": "Belecska",
        "7062": "Keszőhidegkút",
        "7063": "Szárazd",
        "7064": "Gyönk",
        "7065": "Miszla",
        "7066": "Miszla",
        "7067": "Varsád",
        "7068": "Kistormás",
        "7071": "Szakadát",
        "7072": "Diósberény",
        "7081": "Simontornya",
        "7082": "Kisszékely",
        "7083": "Tolnanémedi",
        "7084": "Pincehely",
        "7085": "Nagyszékely",
        "7086": "Ozora",
        "7087": "Fürged",
        "7090": "Regöly",
        "7091": "Pári",
        "7092": "Nagykónyi",
        "7093": "Értény",
        "7094": "Koppányszántó",
        "7095": "Iregszemcse",
        "7097": "Nagyszokoly",
        "7098": "Magyarkeszi",
        "7099": "Felsőnyék",
        "7100": "Szekszárd",
        "7121": "Szálka",
        "7122": "Kakasd",
        "7130": "Tolna",
        "7132": "Bogyiszló",
        "7133": "Fadd",
        "7134": "Gerjen",
        "7135": "Dunaszentgyörgy",
        "7136": "Fácánkert",
        "7139": "Fadd",
        "7140": "Alsónyék",
        "7142": "Pörböly",
        "7143": "Őcsény",
        "7144": "Decs",
        "7145": "Sárpilis",
        "7146": "Várdomb",
        "7147": "Alsónána",
        "7148": "Alsónyék",
        "7149": "Báta",
        "7150": "Bonyhád",
        "7158": "Bonyhádvarasd",
        "7159": "Bonyhád",
        "7161": "Mecseknádasd",
        "7162": "Bonyhád",
        "7163": "Mőcsény",
        "7164": "Bátaapáti",
        "7165": "Mórágy",
        "7171": "Sióagárd",
        "7172": "Harc",
        "7173": "Bonyhád",
        "7174": "Kéty",
        "7175": "Felsőnána",
        "7176": "Murga",
        "7181": "Tevel",
        "7182": "Závod",
        "7183": "Kisvejke",
        "7184": "Lengyel",
        "7185": "Mucsfa",
        "7186": "Aparhant",
        "7187": "Bonyhád",
        "7188": "Szárász",
        "7191": "Hőgyész",
        "7192": "Szakály",
        "7193": "Hőgyész",
        "7194": "Kalaznó",
        "7195": "Hőgyész",
        "7200": "Attala",
        "7211": "Dalmand",
        "7212": "Kocsola",
        "7213": "Szakcs",
        "7214": "Lápafő",
        "7215": "Nak",
        "7224": "Dúzs",
        "7225": "Csibrák",
        "7226": "Kurd",
        "7227": "Gyulaj",
        "7228": "Döbrököz",
        "7251": "Kapospula",
        "7252": "Attala",
        "7253": "Csoma",
        "7255": "Nagyberki",
        "7256": "Kercseliget",
        "7257": "Mosdós",
        "7258": "Baté",
        "7261": "Kaposhomok",
        "7271": "Baté",
        "7272": "Gölle",
        "7273": "Büssü",
        "7274": "Kazsok",
        "7275": "Igal",
        "7276": "Gadács",
        "7279": "Kisgyalán",
        "7281": "Bonnya",
        "7282": "Fiad",
        "7283": "Bonnya",
        "7284": "Somogydöröcske",
        "7285": "Kára",
        "7300": "Komló",
        "7304": "Mánfa",
        "7331": "Liget",
        "7332": "Magyaregregy",
        "7333": "Kárász",
        "7334": "Köblény",
        "7341": "Csikóstőttős",
        "7342": "Mágocs",
        "7343": "Nagyhajmás",
        "7344": "Mekényes",
        "7345": "Alsómocsolád",
        "7346": "Bikal",
        "7347": "Egyházaskozár",
        "7348": "Hegyhátmaróc",
        "7349": "Szászvár",
        "7351": "Máza",
        "7352": "Györe",
        "7353": "Izmény",
        "7354": "Váralja",
        "7355": "Nagymányok",
        "7356": "Kismányok",
        "7357": "Jágónak",
        "7361": "Kaposszekcső",
        "7362": "Gerényes",
        "7370": "Felsőegerszeg",
        "7381": "Ág",
        "7383": "Baranyaszentgyörgy",
        "7384": "Baranyajenő",
        "7385": "Gödre",
        "7386": "Gödre",
        "7391": "Kisbeszterce",
        "7393": "Bakóca",
        "7394": "Bodolyabér",
        "7396": "Magyarszék",
        "7400": "Kaposvár",
        "7431": "Juta",
        "7432": "Csombárd",
        "7434": "Mezőcsokonya",
        "7435": "Somogysárd",
        "7436": "Somogysárd",
        "7439": "Bodrog",
        "7441": "Magyaregres",
        "7442": "Várda",
        "7443": "Alsóbogát",
        "7444": "Osztopán",
        "7452": "Somogyaszaló",
        "7453": "Mernye",
        "7454": "Somodor",
        "7455": "Somogygeszti",
        "7456": "Felsőmocsolád",
        "7457": "Ecseny",
        "7458": "Polány",
        "7463": "Magyaratád",
        "7464": "Igal",
        "7465": "Szentgáloskér",
        "7471": "Zimány",
        "7472": "Cserénfa",
        "7473": "Gálosfa",
        "7474": "Simonfa",
        "7475": "Bőszénfa",
        "7476": "Kaposszerdahely",
        "7477": "Bőszénfa",
        "7478": "Bárdudvarnok",
        "7479": "Sántos",
        "7500": "Nagyatád",
        "7511": "Ötvöskónyi",
        "7512": "Mike",
        "7513": "Rinyaszentkirály",
        "7514": "Tarany",
        "7515": "Somogyudvarhely",
        "7516": "Berzence",
        "7517": "Bolhás",
        "7521": "Kaposmérő",
        "7522": "Kaposújlak",
        "7523": "Kaposfő",
        "7524": "Kiskorpád",
        "7525": "Jákó",
        "7526": "Csököly",
        "7527": "Gige",
        "7530": "Kadarkút",
        "7532": "Hedrehely",
        "7533": "Hedrehely",
        "7535": "Hedrehely",
        "7536": "Patosfa",
        "7537": "Homokszentgyörgy",
        "7538": "Kálmáncsa",
        "7539": "Szulok",
        "7541": "Beleg",
        "7542": "Kisbajom",
        "7543": "Beleg",
        "7544": "Szabás",
        "7545": "Nagykorpád",
        "7551": "Lábod",
        "7552": "Rinyabesenyő",
        "7553": "Görgeteg",
        "7555": "Csokonyavisonta",
        "7556": "Rinyaújlak",
        "7557": "Barcs",
        "7561": "Nagybajom",
        "7562": "Segesd",
        "7563": "Ötvöskónyi",
        "7564": "Kaszó",
        "7570": "Barcs",
        "7582": "Komlósd",
        "7584": "Babócsa",
        "7585": "Bakháza",
        "7586": "Bolhó",
        "7587": "Heresznye",
        "7588": "Vízvár",
        "7589": "Bélavár",
        "7600": "Kővágótöttös",
        "7628": "Pécs",
        "7630": "Pécs",
        "7631": "Pécs",
        "7634": "Pécs",
        "7639": "Kökény",
        "7661": "Erzsébet",
        "7663": "Máriakéménd",
        "7664": "Berkesd",
        "7666": "Pogány",
        "7668": "Gyód",
        "7671": "Aranyosgadány",
        "7672": "Boda",
        "7673": "Cserkút",
        "7675": "Bakonya",
        "7677": "Orfű",
        "7678": "Abaliget",
        "7681": "Hetvehely",
        "7682": "Bükkösd",
        "7683": "Bükkösd",
        "7691": "Pécs",
        "7693": "Pécs",
        "7694": "Hosszúhetény",
        "7695": "Mecseknádasd",
        "7696": "Hidas",
        "7700": "Kölked",
        "7711": "Bár",
        "7712": "Dunaszekcső",
        "7714": "Homorúd",
        "7715": "Homorúd",
        "7716": "Homorúd",
        "7717": "Kölked",
        "7718": "Udvar",
        "7720": "Apátvarasd",
        "7723": "Erdősmecske",
        "7724": "Feked",
        "7725": "Szebény",
        "7726": "Véménd",
        "7727": "Palotabozsok",
        "7728": "Görcsönydoboka",
        "7731": "Nagypall",
        "7732": "Fazekasboda",
        "7733": "Geresdlak",
        "7735": "Erdősmárok",
        "7737": "Székelyszabar",
        "7741": "Nagykozár",
        "7742": "Bogád",
        "7743": "Romonya",
        "7744": "Ellend",
        "7745": "Hásságy",
        "7747": "Belvárdgyula",
        "7751": "Monyoród",
        "7752": "Versend",
        "7753": "Szajk",
        "7754": "Bóly",
        "7755": "Töttös",
        "7756": "Borjád",
        "7757": "Babarc",
        "7759": "Kisnyárád",
        "7761": "Kozármisleny",
        "7762": "Pécsudvard",
        "7763": "Áta",
        "7766": "Kiskassa",
        "7768": "Kistótfalu",
        "7771": "Palkonya",
        "7772": "Ivánbattyán",
        "7773": "Kisjakabfalva",
        "7774": "Márok",
        "7775": "Illocska",
        "7781": "Ivándárda",
        "7782": "Bezedek",
        "7783": "Majs",
        "7784": "Nagynyárád",
        "7785": "Sátorhely",
        "7800": "Alsószentmárton",
        "7811": "Bisse",
        "7812": "Garé",
        "7813": "Szava",
        "7814": "Babarcszőlős",
        "7815": "Harkány",
        "7817": "Diósviszló",
        "7818": "Siklós",
        "7822": "Nagyharsány",
        "7823": "Kistapolca",
        "7824": "Egyházasharaszti",
        "7826": "Alsószentmárton",
        "7827": "Beremend",
        "7831": "Pellérd",
        "7833": "Görcsöny",
        "7834": "Baksa",
        "7836": "Bogádmindszent",
        "7837": "Hegyszentmárton",
        "7838": "Besence",
        "7839": "Kemse",
        "7841": "Adorjás",
        "7843": "Cún",
        "7846": "Drávacsepely",
        "7847": "Drávaszerdahely",
        "7849": "Drávacsehi",
        "7850": "Drávapalkonya",
        "7851": "Drávaszabolcs",
        "7853": "Gordisa",
        "7854": "Matty",
        "7900": "Botykapeterd",
        "7912": "Nagypeterd",
        "7913": "Szentdénes",
        "7914": "Bánfa",
        "7915": "Bürüs",
        "7918": "Lakócsa",
        "7921": "Somogyhatvan",
        "7922": "Basal",
        "7923": "Patapoklosi",
        "7924": "Somogyviszló",
        "7925": "Magyarlukafa",
        "7926": "Vásárosbéc",
        "7932": "Almáskeresztúr",
        "7934": "Almamellék",
        "7935": "Almamellék",
        "7936": "Almamellék",
        "7937": "Almamellék",
        "7940": "Bakonya",
        "7951": "Gerde",
        "7953": "Királyegyháza",
        "7954": "Gilvánfa",
        "7957": "Okorág",
        "7958": "Kákics",
        "7960": "Drávaiványi",
        "7964": "Csányoszró",
        "7966": "Bogdása",
        "7967": "Drávafok",
        "7968": "Felsőszentmárton",
        "7971": "Hobol",
        "7972": "Gyöngyösmellék",
        "7973": "Bürüs",
        "7975": "Kétújfalu",
        "7976": "Szörény",
        "7977": "Drávagárdony",
        "7979": "Drávatamási",
        "7980": "Pettend",
        "7981": "Kistamási",
        "7985": "Kisdobsza",
        "7987": "Istvándi",
        "7988": "Darány",
        "8000": "Székesfehérvár",
        "8019": "Székesfehérvár",
        "8041": "Csór",
        "8042": "Moha",
        "8043": "Iszkaszentgyörgy",
        "8044": "Kincsesbánya",
        "8045": "Bakonykúti",
        "8051": "Sárkeresztes",
        "8052": "Fehérvárcsurgó",
        "8053": "Bodajk",
        "8054": "Balinka",
        "8055": "Balinka",
        "8056": "Bakonycsernye",
        "8060": "Mór",
        "8065": "Nagyveleg",
        "8066": "Pusztavám",
        "8071": "Magyaralmás",
        "8072": "Söréd",
        "8073": "Csákberény",
        "8074": "Csókakő",
        "8081": "Zámoly",
        "8082": "Gánt",
        "8083": "Csákvár",
        "8080": "Bodmér",
        "8086": "Felcsút",
        "8087": "Alcsútdoboz",
        "8087": "Göböljárás",
        "8087": "Hatvanpuszta",
        "8088": "Tabajd",
        "8089": "Vértesacsa",
        "8092": "Pátka",
        "8093": "Lovasberény",
        "8095": "Pákozd",
        "8096": "Sukoró",
        "8097": "Nadap",
        "8100": "Öskü",
        "8105": "Pétfürdő",
        "8109": "Tés",
        "8111": "Seregélyes",
        "8112": "Zichyújfalu",
        "8121": "Tác",
        "8122": "Csősz",
        "8123": "Soponya",
        "8124": "Káloz",
        "8125": "Sárkeresztúr",
        "8126": "Sárszentágota",
        "8127": "Aba",
        "8128": "Aba",
        "8130": "Enying",
        "8131": "Enying",
        "8132": "Lepsény",
        "8133": "Mezőszentgyörgy",
        "8134": "Mátyásdomb",
        "8135": "Dég",
        "8136": "Lajoskomárom",
        "8137": "Mezőkomárom",
        "8138": "Szabadhídvég",
        "8139": "Szabadhídvég",
        "8141": "Sárszentmihály",
        "8142": "Úrhida",
        "8143": "Sárszentmihály",
        "8144": "Sárkeszi",
        "8145": "Nádasdladány",
        "8146": "Jenő",
        "8151": "Szabadbattyán",
        "8152": "Kőszárhegy",
        "8153": "Polgárdi",
        "8154": "Polgárdi",
        "8155": "Polgárdi",
        "8156": "Kisláng",
        "8157": "Füle",
        "8161": "Ősi",
        "8162": "Küngös",
        "8163": "Csajág",
        "8164": "Balatonfőkajár",
        "8171": "Balatonvilágos",
        "8172": "Balatonfőkajár",
        "8174": "Balatonkenese",
        "8175": "Balatonfűzfő",
        "8181": "Berhida",
        "8182": "Berhida",
        "8183": "Papkeszi",
        "8191": "Öskü",
        "8192": "Hajmáskér",
        "8193": "Sóly",
        "8194": "Vilonya",
        "8195": "Királyszentistván",
        "8196": "Litér",
        "8200": "Veszprém",
        "8291": "Nagyvázsony",
        "8220": "Balatonalmádi",
        "8225": "Szentkirályszabadja",
        "8226": "Alsóörs",
        "8227": "Felsőörs",
        "8228": "Lovas",
        "8229": "Csopak",
        "8230": "Balatonfüred",
        "8233": "Balatonszőlős",
        "8237": "Tihany",
        "8241": "Aszófő",
        "8242": "Balatonudvari",
        "8243": "Balatonakali",
        "8244": "Dörgicse",
        "8245": "Pécsely",
        "8246": "Tótvázsony",
        "8247": "Hidegkút",
        "8248": "Nemesvámos",
        "8251": "Zánka",
        "8252": "Balatonszepezd",
        "8253": "Révfülöp",
        "8254": "Kékkút",
        "8255": "Balatonrendes",
        "8256": "Ábrahámhegy",
        "8257": "Badacsonytomaj",
        "8258": "Badacsonytomaj",
        "8261": "Badacsonytomaj",
        "8263": "Badacsonytördemic",
        "8264": "Szigliget",
        "8265": "Hegymagas",
        "8271": "Mencshely",
        "8272": "Balatoncsicsó",
        "8273": "Monoszló",
        "8274": "Köveskál",
        "8275": "Balatonhenye",
        "8281": "Szentbékkálla",
        "8282": "Mindszentkálla",
        "8283": "Káptalantóti",
        "8284": "Kisapáti",
        "8286": "Gyulakeszi",
        "8291": "Barnag",
        "8292": "Öcs",
        "8294": "Balatoncsicsó",
        "8295": "Taliándörögd",
        "8296": "Hegyesd",
        "8297": "Tapolca",
        "8300": "Raposka",
        "8308": "Sáska",
        "8311": "Nemesvita",
        "8312": "Balatonederics",
        "8313": "Balatongyörök",
        "8314": "Vonyarcvashegy",
        "8315": "Gyenesdiás",
        "8316": "Vállus",
        "8318": "Lesencefalu",
        "8319": "Lesenceistvánd",
        "8321": "Uzsa",
        "8330": "Csabrendek",
        "8341": "Kisvásárhely",
        "8342": "Óhíd",
        "8344": "Hetyefő",
        "8345": "Dabronc",
        "8346": "Gógánfa",
        "8347": "Ukk",
        "8348": "Megyer",
        "8349": "Zalagyömörő",
        "8351": "Sümeg",
        "8352": "Bazsi",
        "8353": "Vindornyalak",
        "8354": "Karmacs",
        "8355": "Vindornyaszőlős",
        "8356": "Kisgörbő",
        "8357": "Döbröce",
        "8360": "Keszthely",
        "8371": "Nemesbük",
        "8372": "Cserszegtomaj",
        "8373": "Rezi",
        "8380": "Felsőpáhok",
        "8391": "Sármellék",
        "8392": "Zalavár",
        "8393": "Szentgyörgyvár",
        "8394": "Alsópáhok",
        "8400": "Ajka",
        "8409": "Úrkút",
        "8411": "Kádárta",
        "8412": "Gyulafirátót",
        "8413": "Eplény",
        "8414": "Olaszfalu",
        "8415": "Nagyesztergár",
        "8416": "Dudar",
        "8417": "Csetény",
        "8418": "Bakonyoszlop",
        "8419": "Csesznek",
        "8420": "Csesznek",
        "8422": "Bakonynána",
        "8423": "Szápár",
        "8424": "Jásd",
        "8425": "Lókút",
        "8426": "Pénzesgyőr",
        "8427": "Bakonybél",
        "8428": "Borzavár",
        "8429": "Porva",
        "8430": "Bakonyszentkirály",
        "8431": "Bakonyszentlászló",
        "8432": "Fenyőfő",
        "8433": "Bakonygyirót",
        "8434": "Románd",
        "8435": "Bakonytamási",
        "8436": "Győrasszonyfa",
        "8438": "Veszprémvarsány",
        "8439": "Sikátor",
        "8440": "Herend",
        "8441": "Bánd",
        "8442": "Hárskút",
        "8443": "Bánd",
        "8444": "Szentgál",
        "8445": "Csehbánya",
        "8446": "Kislőd",
        "8447": "Ajka",
        "8448": "Ajka",
        "8449": "Magyarpolány",
        "8451": "Ajka",
        "8452": "Halimba",
        "8454": "Nyirád",
        "8455": "Pusztamiske",
        "8456": "Noszlop",
        "8457": "Bakonypölöske",
        "8458": "Oroszi",
        "8460": "Devecser",
        "8468": "Kolontár",
        "8469": "Kamond",
        "8471": "Bodorfa",
        "8473": "Gyepükaján",
        "8474": "Csabrendek",
        "8475": "Hosztót",
        "8476": "Zalaszegvár",
        "8477": "Apácatorna",
        "8478": "Somlójenő",
        "8479": "Borszörcsök",
        "8481": "Somlóvásárhely",
        "8482": "Doba",
        "8483": "Kisszőlős",
        "8484": "Dáka",
        "8485": "Dabrony",
        "8491": "Karakószörcsök",
        "8492": "Kerta",
        "8493": "Iszkáz",
        "8494": "Kiscsősz",
        "8495": "Csögle",
        "8496": "Kispirit",
        "8497": "Adorjánháza",
        "8500": "Mezőlak",
        "8511": "Pápa",
        "8512": "Nyárád",
        "8513": "Mihályháza",
        "8514": "Mezőlak",
        "8515": "Békás",
        "8516": "Kemeneshőgyész",
        "8517": "Magyargencs",
        "8518": "Kemenesszentpéter",
        "8521": "Nagyacsád",
        "8522": "Nemesgörzsöny",
        "8523": "Egyházaskesző",
        "8531": "Marcaltő",
        "8532": "Egyházaskesző",
        "8533": "Malomsok",
        "8541": "Takácsi",
        "8542": "Vaszar",
        "8543": "Gecse",
        "8551": "Nagygyimót",
        "8552": "Vanyola",
        "8553": "Kajárpéc",
        "8554": "Nagydém",
        "8555": "Bakonytamási",
        "8556": "Pápateszér",
        "8557": "Bakonyság",
        "8558": "Csót",
        "8561": "Adásztevel",
        "8562": "Nagytevel",
        "8563": "Homokbödöge",
        "8564": "Ugod",
        "8565": "Béb",
        "8571": "Bakonykoppány",
        "8572": "Bakonyszücs",
        "8581": "Bakonyjákó",
        "8582": "Farkasgyepű",
        "8591": "Nóráp",
        "8592": "Dáka",
        "8593": "Pápadereske",
        "8594": "Pápasalamon",
        "8595": "Kup",
        "8596": "Pápakovácsi",
        "8597": "Döbrönte",
        "8598": "Pápa",
        "8600": "Balatonszabadi",
        "8612": "Nyim",
        "8613": "Balatonendréd",
        "8614": "Bálványos",
        "8617": "Kőröshegy",
        "8618": "Kereki",
        "8619": "Pusztaszemes",
        "8621": "Zamárdi",
        "8622": "Szántód",
        "8623": "Balatonföldvár",
        "8624": "Balatonszárszó",
        "8625": "Szólád",
        "8626": "Teleki",
        "8627": "Kötcse",
        "8628": "Nagycsepely",
        "8630": "Balatonboglár",
        "8635": "Ordacsehi",
        "8636": "Balatonszemes",
        "8637": "Balatonőszöd",
        "8638": "Balatonlelle",
        "8640": "Fonyód",
        "8646": "Balatonfenyves",
        "8647": "Balatonmáriafürdő",
        "8648": "Balatonkeresztúr",
        "8649": "Balatonberény",
        "8651": "Balatonszabadi",
        "8652": "Siójut",
        "8653": "Ádánd",
        "8654": "Ságvár",
        "8655": "Som",
        "8656": "Nagyberény",
        "8658": "Bábonymegyer",
        "8660": "Kapoly",
        "8666": "Bedegkér",
        "8667": "Kánya",
        "8668": "Tengőd",
        "8669": "Miklósi",
        "8671": "Kapoly",
        "8672": "Zics",
        "8673": "Somogymeggyes",
        "8674": "Nágocs",
        "8675": "Andocs",
        "8676": "Karád",
        "8681": "Látrány",
        "8683": "Somogytúr",
        "8684": "Somogybabod",
        "8685": "Gamás",
        "8691": "Balatonboglár",
        "8692": "Gyugy",
        "8693": "Kisberény",
        "8694": "Hács",
        "8695": "Buzsák",
        "8696": "Táska",
        "8697": "Öreglak",
        "8698": "Öreglak",
        "8699": "Somogyvámos",
        "8700": "Csömend",
        "8705": "Somogyszentpál",
        "8706": "Nikla",
        "8707": "Libickozma",
        "8708": "Pusztakovácsi",
        "8709": "Marcali",
        "8710": "Balatonszentgyörgy",
        "8711": "Vörs",
        "8712": "Balatonújlak",
        "8713": "Kéthely",
        "8714": "Kelevíz",
        "8716": "Gadány",
        "8717": "Nemeskisfalud",
        "8718": "Tapsony",
        "8719": "Böhönye",
        "8721": "Vése",
        "8722": "Nemesdéd",
        "8723": "Varászló",
        "8724": "Inke",
        "8725": "Iharosberény",
        "8726": "Iharos",
        "8728": "Pogányszentpéter",
        "8731": "Hollád",
        "8732": "Főnyed",
        "8733": "Somogysámson",
        "8734": "Somogyzsitfa",
        "8735": "Csákány",
        "8736": "Szőkedencs",
        "8737": "Somogysimonyi",
        "8738": "Nemesvid",
        "8739": "Nagyszakácsi",
        "8741": "Bókaháza",
        "8742": "Esztergályhorváti",
        "8743": "Zalaszabar",
        "8744": "Orosztony",
        "8745": "Kerecseny",
        "8746": "Nagyrada",
        "8747": "Garabonc",
        "8749": "Zalakaros",
        "8751": "Zalakomár",
        "8752": "Galambok",
        "8753": "Balatonmagyaród",
        "8754": "Galambok",
        "8756": "Csapi",
        "8761": "Pacsa",
        "8762": "Gétye",
        "8764": "Dióskál",
        "8765": "Egeraracsa",
        "8767": "Alsórajk",
        "8771": "Hahót",
        "8772": "Börzönce",
        "8773": "Kacorlak",
        "8774": "Gelse",
        "8776": "Bocska",
        "8777": "Fűzvölgy",
        "8778": "Újudvar",
        "8782": "Ligetfalva",
        "8784": "Kehidakustány",
        "8785": "Kallósd",
        "8788": "Sénye",
        "8789": "Zalaszentgrót",
        "8790": "Zalaszentgrót",
        "8792": "Zalavég",
        "8793": "Zalaszentgrót",
        "8795": "Zalaszentgrót",
        "8796": "Türje",
        "8797": "Batyk",
        "8798": "Zalabér",
        "8799": "Dötk",
        "8800": "Nagykanizsa",
        "8808": "Nagykanizsa",
        "8821": "Nagybakónak",
        "8822": "Zalaújlak",
        "8824": "Sand",
        "8825": "Miháld",
        "8827": "Galambok",
        "8831": "Liszó",
        "8834": "Murakeresztúr",
        "8835": "Fityeház",
        "8840": "Csurgó",
        "8849": "Szenta",
        "8851": "Gyékényes",
        "8852": "Zákány",
        "8853": "Zákányfalu",
        "8854": "Őrtilos",
        "8855": "Belezna",
        "8856": "Nemespátró",
        "8858": "Porrog",
        "8861": "Szepetnek",
        "8862": "Semjénháza",
        "8863": "Molnári",
        "8864": "Tótszerdahely",
        "8865": "Tótszentmárton",
        "8866": "Becsehely",
        "8868": "Kistolmács",
        "8872": "Muraszemenye",
        "8873": "Csörnyeföld",
        "8874": "Dobri",
        "8876": "Tormafölde",
        "8877": "Tornyiszentmiklós",
        "8878": "Lovászi",
        "8879": "Iklódbördőce",
        "8881": "Sormás",
        "8882": "Eszteregnye",
        "8883": "Rigyác",
        "8885": "Borsfa",
        "8886": "Oltárc",
        "8887": "Bázakerettye",
        "8888": "Kiscsehi",
        "8891": "Bánokszentgyörgy",
        "8893": "Bucsuta",
        "8895": "Pusztamagyaród",
        "8896": "Pusztaszentlászló",
        "8897": "Söjtör",
        "8900": "Zalaegerszeg",
        "8911": "Kiskutas",
        "8912": "Kispáli",
        "8913": "Egervár",
        "8914": "Gősfa",
        "8915": "Nemesrádó",
        "8917": "Milejszeg",
        "8918": "Csonkahegyhát",
        "8919": "Kustánszeg",
        "8921": "Alibánfa",
        "8923": "Nemesapáti",
        "8924": "Alsónemesapáti",
        "8925": "Alsónemesapáti",
        "8929": "Pölöske",
        "8931": "Kemendollár",
        "8932": "Gyűrűs",
        "8934": "Bezeréd",
        "8935": "Almásháza",
        "8936": "Zalaszentmihály",
        "8943": "Bocfölde",
        "8944": "Sárhida",
        "8945": "Bak",
        "8946": "Baktüttös",
        "8947": "Szentkozmadombja",
        "8948": "Barlahida",
        "8949": "Mikekarácsonyfa",
        "8951": "Csertalakos",
        "8953": "Szentpéterfölde",
        "8954": "Ortaháza",
        "8956": "Kányavár",
        "8957": "Csömödér",
        "8958": "Iklódbördőce",
        "8960": "Hernyék",
        "8966": "Lenti",
        "8969": "Bödeháza",
        "8971": "Kerkabarabás",
        "8973": "Alsószenterzsébet",
        "8975": "Szentgyörgyvölgy",
        "8976": "Márokföld",
        "8977": "Baglad",
        "8978": "Belsősárd",
        "8981": "Gellénháza",
        "8983": "Babosdöbréte",
        "8984": "Gombosszeg",
        "8985": "Becsvölgye",
        "8986": "Pórszombat",
        "8988": "Kálócfa",
        "8989": "Dobronhegy",
        "8990": "Pálfiszeg",
        "8991": "Böde",
        "8992": "Bagod",
        "8994": "Kávás",
        "8995": "Keménfa",
        "8996": "Zalacséb",
        "8997": "Zalaháshágy",
        "8998": "Ozmánbük",
        "8999": "Csöde",
        "9000": "Győr",
        "9002": "Győr",
        "9011": "Győr",
        "9012": "Győr",
        "9019": "Győr",
        "9024": "Győr",
        "9025": "Győr",
        "9026": "Győr",
        "9027": "Győr",
        "9028": "Győr",
        "9061": "Győrzámoly",
        "9062": "Kisbajcs",
        "9063": "Nagybajcs",
        "9071": "Gönyű",
        "9072": "Nagyszentjános",
        "9073": "Bőny",
        "9074": "Rétalap",
        "9081": "Győrújbarát",
        "9082": "Nyúl",
        "9083": "Écs",
        "9084": "Győrság",
        "9085": "Pázmándfalu",
        "9086": "Töltéstava",
        "9088": "Bakonypéterd",
        "9089": "Lázi",
        "9090": "Pannonhalma",
        "9091": "Pannonhalma",
        "9092": "Tarjánpuszta",
        "9093": "Győrasszonyfa",
        "9094": "Tápszentmiklós",
        "9095": "Táp",
        "9096": "Nyalka",
        "9097": "Mezőörs",
        "9098": "Pér",
        "9099": "Pér",
        "9100": "Tét",
        "9111": "Tényő",
        "9112": "Ravazd",
        "9113": "Koroncó",
        "9121": "Győrszemere",
        "9122": "Felpéc",
        "9123": "Kajárpéc",
        "9124": "Gyömöre",
        "9125": "Szerecseny",
        "9126": "Gyarmat",
        "9127": "Csikvánd",
        "9131": "Mórichida",
        "9132": "Árpás",
        "9133": "Kisbabot",
        "9134": "Bodonhely",
        "9135": "Rábaszentmihály",
        "9136": "Mérges",
        "9141": "Ikrény",
        "9142": "Rábapatona",
        "9143": "Enese",
        "9144": "Kóny",
        "9145": "Bágyogszovát",
        "9146": "Rábapordány",
        "9147": "Csorna",
        "9151": "Abda",
        "9152": "Börcs",
        "9153": "Öttevény",
        "9154": "Mosonszentmiklós",
        "9155": "Lébény",
        "9161": "Győrsövényház",
        "9162": "Bezi",
        "9163": "Fehértó",
        "9164": "Markotabödöge",
        "9165": "Cakóháza",
        "9167": "Bősárkány",
        "9168": "Acsalag",
        "9169": "Barbacs",
        "9171": "Győrújfalu",
        "9172": "Győrzámoly",
        "9173": "Győrladamér",
        "9174": "Dunaszeg",
        "9175": "Dunaszentpál",
        "9176": "Hédervár",
        "9177": "Ásványráró",
        "9178": "Hédervár",
        "9181": "Kimle",
        "9182": "Károlyháza",
        "9183": "Lébény",
        "9184": "Kunsziget",
        "9200": "Mosonmagyaróvár",
        "9211": "Feketeerdő",
        "9221": "Levél",
        "9222": "Hegyeshalom",
        "9223": "Bezenye",
        "9224": "Rajka",
        "9225": "Dunakiliti",
        "9226": "Dunasziget",
        "9228": "Halászi",
        "9231": "Máriakálnok",
        "9232": "Darnózseli",
        "9233": "Lipót",
        "9234": "Kisbodak",
        "9235": "Dunaremete",
        "9241": "Jánossomorja",
        "9242": "Jánossomorja",
        "9243": "Jánossomorja",
        "9244": "Újrónafő",
        "9245": "Mosonszolnok",
        "9300": "Csorna",
        "9311": "Pásztori",
        "9312": "Szilsárkány",
        "9313": "Rábacsanak",
        "9314": "Egyed",
        "9315": "Sobor",
        "9316": "Rábaszentandrás",
        "9317": "Szany",
        "9321": "Farád",
        "9322": "Rábatamási",
        "9323": "Jobaháza",
        "9324": "Bogyoszló",
        "9325": "Sopronnémeti",
        "9326": "Szil",
        "9327": "Rábasebes",
        "9330": "Kapuvár",
        "9339": "Kapuvár",
        "9341": "Kisfalud",
        "9342": "Mihályi",
        "9343": "Beled",
        "9344": "Rábakecöl",
        "9345": "Páli",
        "9346": "Magyarkeresztúr",
        "9351": "Babót",
        "9352": "Veszkény",
        "9353": "Szárföld",
        "9354": "Osli",
        "9361": "Hövej",
        "9362": "Himod",
        "9363": "Gyóró",
        "9364": "Cirák",
        "9365": "Dénesfa",
        "9371": "Vitnyéd",
        "9372": "Csapod",
        "9373": "Pusztacsalád",
        "9374": "Iván",
        "9375": "Csáfordjánosfa",
        "9400": "Sopron",
        "9407": "Fertőrákos",
        "9408": "Sopron",
        "9421": "Fertőrákos",
        "9422": "Harka",
        "9423": "Ágfalva",
        "9431": "Fertőd",
        "9433": "Fertőd",
        "9434": "Sarród",
        "9435": "Sarród",
        "9436": "Fertőszéplak",
        "9437": "Hegykő",
        "9441": "Agyagosszergény",
        "9442": "Fertőendréd",
        "9443": "Petőháza",
        "9444": "Fertőszentmiklós",
        "9451": "Ebergőc",
        "9461": "Lövő",
        "9462": "Völcsej",
        "9463": "Sopronhorpács",
        "9464": "Und",
        "9471": "Nemeskér",
        "9472": "Újkér",
        "9473": "Egyházasfalu",
        "9474": "Gyalóka",
        "9475": "Répcevis",
        "9476": "Zsira",
        "9481": "Pinnye",
        "9482": "Nagylózs",
        "9483": "Sopronkövesd",
        "9484": "Pereszteg",
        "9485": "Nagycenk",
        "9491": "Hidegség",
        "9492": "Fertőhomok",
        "9493": "Fertőboz",
        "9494": "Sopron",
        "9495": "Kópháza",
        "9500": "Celldömölk",
        "9511": "Kemenesmihályfa",
        "9512": "Ostffyasszonyfa",
        "9513": "Csönge",
        "9514": "Kenyeri",
        "9515": "Pápoc",
        "9516": "Vönöck",
        "9517": "Kemenessömjén",
        "9521": "Kemenesszentmárton",
        "9522": "Kemenesmagasi",
        "9523": "Szergény",
        "9531": "Mersevát",
        "9532": "Külsővat",
        "9533": "Nemesszalók",
        "9534": "Marcalgergelyi",
        "9535": "Vinár",
        "9541": "Celldömölk",
        "9542": "Boba",
        "9544": "Kemenespálfa",
        "9545": "Jánosháza",
        "9547": "Karakó",
        "9548": "Nemeskeresztúr",
        "9549": "Keléd",
        "9551": "Mesteri",
        "9552": "Vásárosmiske",
        "9553": "Kemeneskápolna",
        "9554": "Borgáta",
        "9555": "Kissomlyó",
        "9556": "Duka",
        "9561": "Kemenesmihályfa",
        "9600": "Sárvár",
        "9608": "Sárvár",
        "9609": "Sárvár",
        "9611": "Csénye",
        "9612": "Bögöt",
        "9621": "Ölbő",
        "9622": "Szeleste",
        "9623": "Répceszentgyörgy",
        "9624": "Chernelházadamonya",
        "9625": "Bő",
        "9631": "Hegyfalu",
        "9632": "Sajtoskál",
        "9633": "Simaság",
        "9634": "Iklanberény",
        "9635": "Zsédeny",
        "9636": "Pósfa",
        "9641": "Rábapaty",
        "9643": "Jákfa",
        "9651": "Uraiújfalu",
        "9652": "Nick",
        "9653": "Répcelak",
        "9654": "Csánig",
        "9661": "Vasegerszeg",
        "9662": "Mesterháza",
        "9663": "Nemesládony",
        "9664": "Nagygeresd",
        "9665": "Vámoscsalád",
        "9671": "Sitke",
        "9672": "Bejcgyertyános",
        "9673": "Káld",
        "9674": "Vashosszúfalu",
        "9675": "Bögöte",
        "9676": "Csehimindszent",
        "9681": "Sótony",
        "9682": "Nyőgér",
        "9683": "Bejcgyertyános",
        "9684": "Egervölgy",
        "9685": "Szemenye",
        "9693": "Pécs",
        "9700": "Szombathely",
        "9721": "Gencsapáti",
        "9722": "Perenye",
        "9723": "Gyöngyösfalu",
        "9724": "Lukácsháza",
        "9725": "Cák",
        "9726": "Velem",
        "9727": "Bozsok",
        "9730": "Kőszeg",
        "9733": "Horvátzsidány",
        "9734": "Peresznye",
        "9735": "Csepreg",
        "9736": "Tormásliget",
        "9737": "Bük",
        "9738": "Tömörd",
        "9739": "Kőszegpaty",
        "9740": "Bük",
        "9741": "Vassurány",
        "9742": "Salköveskút",
        "9743": "Söpte",
        "9744": "Vasasszonyfa",
        "9745": "Meszlen",
        "9746": "Acsád",
        "9747": "Vasszilvágy",
        "9748": "Vát",
        "9749": "Nemesbőd",
        "9751": "Vép",
        "9752": "Bozzai",
        "9754": "Megyehíd",
        "9756": "Ikervár",
        "9757": "Meggyeskovácsi",
        "9761": "Táplánszentkereszt",
        "9762": "Tanakajd",
        "9763": "Vasszécseny",
        "9764": "Csempeszkopács",
        "9766": "Rábatöttös",
        "9771": "Balogunyom",
        "9772": "Kisunyom",
        "9773": "Sorokpolány",
        "9774": "Gyanógeregye",
        "9775": "Nemeskolta",
        "9776": "Püspökmolnári",
        "9777": "Rábahídvég",
        "9781": "Egyházashollós",
        "9782": "Nemesrempehollós",
        "9783": "Egyházasrádóc",
        "9784": "Harasztifalu",
        "9789": "Sé",
        "9791": "Dozmat",
        "9792": "Bucsu",
        "9793": "Narda",
        "9794": "Felsőcsatár",
        "9795": "Vaskeresztes",
        "9796": "Horvátlövő",
        "9797": "Nárai",
        "9798": "Ják",
        "9799": "Szentpéterfa",
        "9800": "Vasvár",
        "9811": "Andrásfa",
        "9812": "Telekes",
        "9813": "Gersekarát",
        "9814": "Halastó",
        "9821": "Győrvár",
        "9823": "Pácsony",
        "9824": "Olaszfa",
        "9825": "Alsóújlak",
        "9826": "Hegyhátszentpéter",
        "9831": "Bérbaltavár",
        "9832": "Nagytilaj",
        "9833": "Csehi",
        "9834": "Csehimindszent",
        "9835": "Mikosszéplak",
        "9836": "Csipkerek",
        "9841": "Alsóújlak",
        "9842": "Alsóújlak",
        "9900": "Körmend",
        "9909": "Körmend",
        "9912": "Magyarszecsőd",
        "9913": "Döröske",
        "9914": "Döbörhegy",
        "9915": "Hegyháthodász",
        "9917": "Daraboshegy",
        "9918": "Felsőmarác",
        "9919": "Csákánydoroszló",
        "9921": "Vasalja",
        "9922": "Pinkamindszent",
        "9923": "Kemestaródfa",
        "9931": "Hegyhátszentmárton",
        "9932": "Viszák",
        "9933": "Őrimagyarósd",
        "9934": "Felsőjánosfa",
        "9935": "Szőce",
        "9936": "Kisrákos",
        "9937": "Pankasz",
        "9938": "Nagyrákos",
        "9941": "Ispánk",
        "9942": "Szalafő",
        "9943": "Kondorfa",
        "9944": "Bajánsenye",
        "9945": "Kercaszomor",
        "9946": "Magyarszombatfa",
        "9951": "Rátót",
        "9952": "Gasztony",
        "9953": "Nemesmedves",
        "9954": "Rönök",
        "9955": "Szentgotthárd",
        "9961": "Rábagyarmat",
        "9962": "Csörötnek",
        "9970": "Szentgotthárd",
        "9981": "Szentgotthárd",
        "9982": "Apátistvánfalva",
        "9983": "Alsószölnök",
        "9985": "Felsőszölnök"
    }
}

postcodes["Magyarország"] = postcodes["Hungary"];
