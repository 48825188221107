import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import admin from "../../js/admin";
import auth, { messageDialog } from "../../js/auth";
import { I18n } from "react-redux-i18n";
import { playNewAcceptedOrderSound } from "../../js/order-list-util";

class MenuButton extends Component {
  state = {};
  render() {
    const { myStatus } = this.props;
    if (!myStatus || !myStatus.restaurant_settings || (this.props.roles || localStorage.restaurantSelected === "0")) {
      if (!myStatus || !myStatus.restaurant_settings || !admin.hasRole(myStatus.roles, this.props.roles)) return null;
    }
    var enabledFeature = false;
    const superadmin = myStatus && myStatus.roles && myStatus.roles.filter(r => r.role === "superadmin").length;
    if (this.props.features && myStatus && myStatus.restaurant_settings) {
      this.props.features.forEach(feat => {
        var feature = myStatus.restaurant_settings["enabled-features"]
        feat.split(".").forEach(f => {
          feature = feature[f];
        })
        enabledFeature = enabledFeature || (feature === true || feature?.enabled === true);
      })

    } else {
      enabledFeature = true;
    }
    const style = {
      ...this.props.style,
      backgroundImage: "url('" + this.props.backgroundImage + "')"
    };
    const style2 = {
      background: this.props.background
    };
    const iClass = "menuicon " + (this.props.i ? this.props.i : "hidden");
    const diva = (
      <div className={"menuButtonImage " + (this.props.error ? "pulse-infinite" : "") + " " + (this?.props?.className ? this?.props?.className : "")} style={style} onClick={this.props.onClick}>
        <div className="menuButtonBackground" style={style2}>
          <Button variant="none" id={this.props.id} className="menuButton" data-step={this.props["data-step"]} data-intro={this.props["data-intro"]}>
            <i className={iClass}></i>
            {this.props.name && this.props.name.split("<br/>").map(name => (<span key={name}>{name}</span>))}
            {this.props.children}
          </Button>
        </div>
      </div>
    );
    const appAccess = auth.device.platform === "browser" ? true : this.props.myStatus.restaurant_settings["enabled-features"].appAccess ? this.props.myStatus.restaurant_settings["enabled-features"].appAccess.enabled : false;
    if (this.props.href) {
      if (this.props.href.startsWith("http")) {
        if (this.props.external)
          return <div onClick={this.openExternalLink}>{diva}</div>;
        else
          return <a href={this.props.href}>{diva}</a>
      } else {
        if ((appAccess && enabledFeature) || superadmin) {
          if (this.props.external)
            return <div onClick={this.openExternalLink}>{diva}</div>;
          else
            return <Link to={this.props.href} style={{ opacity: enabledFeature ? 1 : .5 }}>{diva}</Link>;
        } else
          return <div onClick={this.featureNotEnabled} style={{ opacity: .5 }}>{diva}</div>;
      }
    } else {
      return diva;
    }
  }

  openExternalLink = () => {
    if (window.cordova.InAppBrowser)
      window.cordova.InAppBrowser.open(this.props.href, '_system', 'hidden=yes,location=no')
    else
      window.open(this.props.href, '_new');
  }

  featureNotEnabled = () => {
    messageDialog(I18n.t("local.information"), I18n.t("admin_local.you_are_not_authorized_to_access_this_feature"));
  }
}

export default MenuButton;
