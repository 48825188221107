import React, { Component } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import removeAccents from "remove-accents";

class LabeledAutoCompleteStyled extends Component {
  state = {};

  render() {
    return (
      <div id={this.props.id} className={this.props.className} >
        <label style={{ position: "relative" }}>{this.props.label}
          {this.props.tooltip ?
            <i style={{ position: "absolute", fontSize: "80%", top: "-6px", right: "2px" }} data-toggle="tooltip" title={this.props.tooltip} className="icon-help" />
            : null}
        </label>
        <AsyncTypeahead
          filterBy={this.filterBy}
          allowNew={true}
          isLoading={false}
          options={this.props.options}

          inputProps={{ tabIndex: this.props.tabindex }}

          isInvalid={this.props.valid === false}

          id={this.props.id}
          type={this.props.type}
          className="inputStyle"
          ref={node => (this.inputNode = node)}

          placeholder={this.props.placeholder}

          ignoreDiacritics={true}

          labelKey="address"
          delay={500}
          emptyLabel={this.props.placeholder}
          //selected={this.props.value ? [this.props.value] : []}
          defaultSelected={this.props.value ? [this.props.value] : []}
          selected={this.props.value ? [this.props.value] : []}
          //minLength={3}
          onSearch={this.c}
          onChange={this.props.onSelect}
          onInputChange={this.change}
          renderMenuItemChildren={(option, props) => (
            <span key={option.address}>
              {option.postcode} {option.town}, {option.address}
            </span>
          )} />
        <div tabindex="-1" class="btn btn-negative icon-cancel" onClick={this.onDelete} style={{ fontSize: ".5rem" }} />
      </div >
    );
  }
  filterBy = (f) => {
    //console.log(f);
    return true;
  }
  c = (e) => {
  }
  change = (search) => {
    this.props.change({ target: { id: this.props.id, value: search } }, !this.props.pattern || new RegExp(removeAccents(this.props.pattern), 'i').test(removeAccents(search)));
  }
  onDelete = (event) => {
    event.target = event.target.previousSibling;
    this.props.change({ target: { id: this.props.id, value: "" } }, !this.props.pattern || new RegExp(removeAccents(this.props.pattern), 'i').test(""));
    event.target.firstChild.firstChild.focus();
  }
}

export default LabeledAutoCompleteStyled;
