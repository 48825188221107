import React, { useState } from "react";

const TimeEditor = ({ value, onChange }) => {
    // Convert the initial decimal value to "HH:mm" format
    const decimalToTime = (decimal) => {
        const hours = Math.floor(decimal);
        const minutes = Math.round((decimal - hours) * 60);
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    };

    // Convert "HH:mm" format to decimal
    const timeToDecimal = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours + minutes / 60;
    };

    const [time, setTime] = useState(decimalToTime(value || 0));

    const handleChange = (event) => {
        setTime(event.target.value);
    };

    const handleBlur = () => {
        if (onChange) {
            try {
                const decimalValue = timeToDecimal(time);
                onChange(decimalValue); // Save the decimal value
            } catch (error) {
                console.error("Invalid time format:", error);
            }
        }
    };

    return (
        <input
            type="time"
            value={time}
            onChange={handleChange}
            onBlur={handleBlur}
            step="900" // 15-minute increments
            style={{ width: "100px" }}
        />
    );
};

export default TimeEditor;
