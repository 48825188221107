import $ from "jquery";
import { update, tmplparams, getTableOccupation } from "./order-list-util";
import admin, { getTableMate } from "./admin";
import { messageDialog } from "./auth";
import languages from "../langs/languages";

const { local } = languages;

export const chooseCustomer = {
  teamMateId: -1,
  seqNr: 0,

  defaultHandler: function (id, seqNr, customer) {
    admin.setCustomerForTableMate(chooseCustomer.teamMateId, customer.id, function (data) {
      if (data.error) {
        //eslint-disable-next-line no-eval
        messageDialog(local.error_message, eval(data.error));
        return;
      }
      $("#chooseCustomer.modal").modal("hide");
      $("#chooseCustomer.modal").hide();
      update(data);
    });
  },

  show: function (id, seqNr, handler) {
    if (!id) {
      id = getTableOccupation(localStorage.tableOccupationSelected).tableMates[0].id;
    }
    chooseCustomer.teamMateId = id;
    chooseCustomer.seqNr = seqNr;
    if (typeof handler !== "undefined") chooseCustomer.handler = handler;
    else chooseCustomer.handler = chooseCustomer.defaultHandler;
    $("#chooseCustomer.modal").remove();
    if (id !== -1)
      $("#chooseCustomerTemplate")
        .tmpl({ seqNr: getTableMate(id).seqNr, ...tmplparams() })
        .appendTo("body");
    else
      $("#chooseCustomerTemplate")
        .tmpl({ seqNr: seqNr, ...tmplparams() })
        .appendTo("body");
    $("#chooseCustomer.modal").on("shown.bs.modal", function () {
      $("#chooseCustomer.modal input#search").focus();
    });
    $("#chooseCustomer.modal input#search").keyup(chooseCustomer.keyupHandler);
    $("#chooseCustomer.modal input#search").change(chooseCustomer.keyupHandler);
    $("#chooseCustomer.modal").modal("show");
  },

  keyupHandler: (event, additionalParams) => {
    var search = $("#chooseCustomer.modal input#search").val();
    if (!isNaN(search) && (additionalParams || event.originalEvent.key) === "Enter") {
      if (!search) {
        chooseCustomer.handler(chooseCustomer.teamMateId, chooseCustomer.seqNr, { id: -1 });
        return;
      }
      admin.getUsersById(search, (data) => {
        chooseCustomer.data = data;
        if (data.length) {
          chooseCustomer.setCustomer(data[0].id);
        }
        $("#chooseCustomer.modal table#customerList tbody").empty();
        $("#chooseCustomerListTemplate")
          .tmpl(data)
          .appendTo("#chooseCustomer.modal table#customerList tbody");
      });
    } else if (search.length > 2) {
      admin.getUsersContaining3(search, function (data) {
        chooseCustomer.data = data;
        $("#chooseCustomer.modal table#customerList tbody").empty();
        $("#chooseCustomerListTemplate")
          .tmpl(data)
          .appendTo("#chooseCustomer.modal table#customerList tbody");
      });
    }
  },

  setCustomer: function (id) {
    if (id === -1) {
      chooseCustomer.handler(chooseCustomer.teamMateId, chooseCustomer.seqNr, { id: -1 });
      return;
    }
    const c = chooseCustomer.data.find(customer => customer.id === id);
    if (c) {
      chooseCustomer.handler(chooseCustomer.teamMateId, chooseCustomer.seqNr, c);
    }
  },

  setNewCustomer: function () {
    var search = $("#chooseCustomer.modal input#search").val();
    var email = $("#chooseCustomer.modal #email input").val();
    var family_name = $("#chooseCustomer.modal #family_name input").val();
    var first_name = $("#chooseCustomer.modal #first_name input").val();
    if (!email && email !== "" && !isNaN(search)) {
      chooseCustomer.handler(chooseCustomer.teamMateId, chooseCustomer.seqNr, { id: Number(search) });
      return;
    }
    admin.setNewCustomerForTableMate(chooseCustomer.teamMateId, email, family_name, first_name, data => {
      if (data.error) {
        //eslint-disable-next-line no-eval
        messageDialog(local.error_message, eval(data.error));
        return;
      }
      $("#chooseCustomer.modal").modal("hide");
      $("#chooseCustomer.modal").hide();
      data.tableOccupation.tableMates.forEach(mate => {
        admin.tableMatesMap.set(Number(mate.id), mate);
      })

      update(data);
    });
    return;
  }
};
