import React from "react";

const DateEditor = ({ value, onChange, readonly }) => {
    const handleDateChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <input
            type="date"
            value={value || ""}
            onChange={handleDateChange}
            disabled={readonly}
        />
    );
};

export default DateEditor;
