import React, { Component } from "react";
import removeAccents from "remove-accents";

class LabeledInputStyled extends Component {
  state = {};

  render() {
    const clazz = this.props.valid === false ? "form-control is-invalid" : "";
    return (
      <div id={this.props.id} className={this.props.className} >
        <label style={{ position: "relative" }}>{this.props.label}
          {this.props.tooltip ?
            <i style={{ position: "absolute", fontSize: "80%", top: "-6px", right: "2px" }} data-toggle="tooltip" title={this.props.tooltip} className="icon-help" />
            : null}
        </label>
        < input
          className={clazz}
          placeholder={this.props.placeholder}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          tabIndex={this.props.tabindex}
          ref={input => this.props.focus && input && input.focus()}
          id={this.props.id}
          type={this.props.type}
          value={this.props.value}
          //defaultValue={this.props.value}
          onChange={this.onChange}></input>
        <div class="btn btn-negative icon-cancel" onClick={this.onDelete} style={{ fontSize: ".5rem" }} />
      </div >
    );
  }

  onChange = (event) => {
    this.props.change(event, !this.props.pattern || new RegExp(removeAccents(this.props.pattern), 'i').test(removeAccents(event.target.value)));
  }

  onDelete = (event) => {
    event.target = event.target.previousSibling;
    if (this.props.delete) this.props.delete(event);
    event.target.value = "";
    this.props.change(event, !this.props.pattern || new RegExp(removeAccents(this.props.pattern), 'i').test(removeAccents(event.target.value)));
    event.target.focus();
  }

}

export default LabeledInputStyled;
