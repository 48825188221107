import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Map as MapContainer, TileLayer, Marker, Polyline, Polygon } from "react-leaflet";
import { I18n } from "react-redux-i18n";
import L from 'leaflet';


const ConfirmationDialog = ({ show, onConfirm, onCancel, defaultPosition, tableOccupation, occupation, text, changeState }) => {

    const [position, setPosition] = useState(defaultPosition);

    // Effect to update position if defaultPosition changes from null to a value
    useEffect(() => {
        if (defaultPosition && !position) {
            setPosition(defaultPosition);
        }
    }, [defaultPosition, position]);

    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
    };

    const createArrowhead = (from, to, size = 30) => {
        const toRadians = (deg) => (deg * Math.PI) / 180;
        const toDegrees = (rad) => (rad * 180) / Math.PI;

        const lat1 = toRadians(from[0]);
        const lon1 = toRadians(from[1]);
        const lat2 = toRadians(to[0]);
        const lon2 = toRadians(to[1]);

        // Calculate bearing (angle between two points)
        const dLon = lon2 - lon1;
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        const bearing = Math.atan2(y, x);

        // Arrowhead points
        const arrowBearing1 = bearing + toRadians(150); // 150° offset
        const arrowBearing2 = bearing - toRadians(150); // -150° offset

        // Function to calculate the destination point given a start point, bearing, and distance
        const destinationPoint = (lat, lon, brng, distance) => {
            const R = 6371e3; // Earth’s radius in meters
            const δ = distance / R; // Angular distance
            const φ1 = lat;
            const λ1 = lon;

            const φ2 = Math.asin(
                Math.sin(φ1) * Math.cos(δ) + Math.cos(φ1) * Math.sin(δ) * Math.cos(brng)
            );
            const λ2 =
                λ1 +
                Math.atan2(
                    Math.sin(brng) * Math.sin(δ) * Math.cos(φ1),
                    Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2)
                );

            return [toDegrees(φ2), toDegrees(λ2)];
        };

        const arrowPoint1 = destinationPoint(lat2, lon2, arrowBearing1, size);
        const arrowPoint2 = destinationPoint(lat2, lon2, arrowBearing2, size);

        return [arrowPoint1, to, arrowPoint2];
    };



    return (
        <Modal show={show} onHide={onCancel} centered >
            <Modal.Header style={{ alignSelf: "center" }} >
                <h1 dangerouslySetInnerHTML={{
                    __html:
                        String.format(String.format(I18n.t("admin_local.confirm_position_update"), occupation.bookedProperties.address))

                }} />
            </Modal.Header>
            <Modal.Body>
                {text}
                <div style={{ height: "300px", width: "100%", marginBottom: "10px", justifySelf: "center" }}>
                    {defaultPosition ? <MapContainer
                        center={position || [0, 0]}
                        zoom={17}
                        style={{ height: "300px", width: "100%" }}
                        onclick={handleMapClick} // Add the onClick handler
                    >
                        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />

                        {position && (
                            <Marker position={position}>
                            </Marker>
                        )}
                        {occupation?.bookedProperties.latitude && (
                            <Marker position={[occupation?.bookedProperties.latitude, occupation?.bookedProperties.longitude]}>
                            </Marker>
                        )}
                        {position && occupation?.bookedProperties?.latitude && occupation && (<React.Fragment>
                            <Polyline positions={[position, [occupation?.bookedProperties.latitude, occupation?.bookedProperties.longitude]]} color="blue" weight={3} opacity=".7" />
                            <Polygon positions={createArrowhead([Number(occupation?.bookedProperties.latitude), Number(occupation?.bookedProperties.longitude)], position)} color="red" weight={1} fill="blue" fillOpacity="1" opacity="1" />
                        </React.Fragment>)}

                    </MapContainer> :
                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {I18n.t("admin_local.getting_location")}
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    {I18n.t("local.cancel")}
                </Button>
                {text ? (
                    <React.Fragment>
                        <Button variant="primary" onClick={() => onConfirm(position, tableOccupation)}>
                            {I18n.t("admin_local.delivered_refresh_position")}
                        </Button>
                        <Button variant="primary" onClick={() => onConfirm(position, tableOccupation)}>
                            {I18n.t("local." + changeState)}
                        </Button>
                    </React.Fragment>
                ) : <Button variant="primary" onClick={() => onConfirm(position, tableOccupation)}>
                    {I18n.t("admin_local.refresh_position")}
                </Button>}
            </Modal.Footer>
        </Modal >
    );
};

export default ConfirmationDialog;
