import { get, post, messageDialog, confirmDialog } from "../auth";
import languages from "../../langs/languages";

const { local } = languages;

const superadmin = {};

export const getAllRestaurants = (handler, errorHandler) => {
  get("superadminService/getRestaurants?instance=" + localStorage.instance, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getAllRestaurants = getAllRestaurants;

function getAllUsers(handler, errorHandler) {
  get("superadminService/getUsers", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.getAllUsers = getAllUsers;

export const getUsersByFilter = (contains, online, active, role, entityType, entityName, handler, errorHandler) => {
  get("superadminService/getUsersByFilter?contains=" + contains + "&online=" + online + "&active=" + active + "&role=" + role + "&entityType=" + entityType + "&entityName=" + entityName, localStorage.id).done(function (
    data
  ) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getUsersByFilter = getUsersByFilter;

export const getRolesByRestaurant = (restaurant, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/getRolesByRestaurant", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getRolesByRestaurant = getRolesByRestaurant;

export const getPaymentMethodsByRestaurant = (restaurant, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/getPaymentMethodsByRestaurant?lang=" + localStorage.language, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getPaymentMethodsByRestaurant = getPaymentMethodsByRestaurant;

export const getUsersContaining = (contains, handler, errorHandler) => {
  get("superadminService/getUsersContaining?contains=" + contains, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getUsersContaining = getUsersContaining;

export const getEntitiesContaining = (entityType, contains, handler, errorHandler) => {
  get("superadminService/get" + entityType + "sContaining?contains=" + contains, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getEntitiesContaining = getEntitiesContaining;

export const modifyRestaurant = (
  { id, subscription, invoiceDueSince, kubernetesLive, kubernetesBackup, instance, globalId, name, company, postcode, phone, domainprefix, address, town, country, languages, longitude, latitude, css, invoiceHeading, invoiceFooter, baseCurrency, barionPOSKey, settings, isActive, test, image, secretKey, serverUrl, backupUrl, helmtag, helmset },
  handler,
  errorHandler
) => {
  post("superadminService/" + id + "/modifyRestaurant", {
    id,
    subscription,
    instance,
    kubernetesLive,
    kubernetesBackup,
    invoiceDueSince,
    globalId,
    domainprefix,
    company,
    postcode,
    phone,
    name,
    address,
    town,
    country,
    languages,
    longitude,
    latitude,
    css,
    invoiceHeading,
    invoiceFooter,
    baseCurrency,
    barionPOSKey,
    settings,
    isActive,
    test,
    image,
    secretKey,
    serverUrl,
    backupUrl,
    helmtag: helmtag,
    helmset: helmset
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.modifyRestaurant = modifyRestaurant;

export const addRestaurant = (
  { globalId, name, domainprefix, subscription, invoiceDueSince, company, postcode, phone, address, town, country, languages, longitude, latitude, css, invoiceHeading, invoiceFooter, baseCurrency, barionPOSKey, settings, active, test, image, secretKey, serverUrl, localUrl, helmtag, helmset },
  handler,
  errorHandler
) => {
  post("superadminService/addRestaurant", {
    globalId: globalId,
    name: name,
    domainprefix,
    subscription,
    invoiceDueSince,
    address: address,
    company,
    postcode,
    phone,
    town: town,
    country: country,
    languages: languages,
    longitude: longitude,
    latitude: latitude,
    image: image,
    css: css,
    invoiceHeading: invoiceHeading,
    invoiceFooter: invoiceFooter,
    settings: settings,
    isActive: active,
    test: test,
    secretKey: secretKey,
    serverUrl: serverUrl,
    localUrl: localUrl,
    barionPOSKey: barionPOSKey,
    baseCurrency: baseCurrency,
    helmtag: helmtag,
    helmset: helmset
  }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.addRestaurant = addRestaurant;

export const modifyCustomer = (id, name, surname, email, password, gitlabSecureToken, labels, active, handler, errorHandler) => {
  post("superadminService/" + id + "/modifyCustomer", { id: id, name: name, surname: surname, email: email, password: password, isActive: active, gitlabSecureToken: gitlabSecureToken, labels: labels }).done(function (
    data
  ) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.modifyCustomer = modifyCustomer;

export const addCustomer = (name, surname, email, password, gitlabSecureToken, labels, active, handler, errorHandler) => {
  post("superadminService/addCustomer", { name: name, surname: surname, email: email, password: password, isActive: active, gitlabSecureToken: gitlabSecureToken, labels: labels }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.addCustomer = addCustomer;

export const deleteCustomer = (id, handler, errorHandler, anonimize) => {
  get("superadminService/" + id + "/deleteCustomer?anonimize=" + (typeof anonimize == "undefined" ? false : anonimize)).done(function (data) {
    if (data == null) {
      console.log("Failed to delete customer");
    } else if (data.error) {
      // eslint-disable-next-line no-eval
      messageDialog(local.error_message, eval(data.error)).done(errorHandler);
    } else if (data.confirm) {
      // eslint-disable-next-line no-eval
      confirmDialog(local.confirmation, eval(data.confirm)).done(deleteCustomer(id, handler, errorHandler, true));
    } else {
      handler(data);
    }
  });
};
superadmin.deleteCustomer = deleteCustomer;

export const deleteRestaurant = (id, handler, errorHandler) => {
  get("superadminService/" + id + "/deleteRestaurant").done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.deleteRestaurant = deleteRestaurant;

export const deleteRole = (id, handler, errorHandler) => {
  get("superadminService/deleteRole?role=" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.deleteRole = deleteRole;

export const addNewRole = (customerEmail, role, entityType, entityId, handler, errorHandler) => {
  get("superadminService/addNewRole?email=" + customerEmail + "&role=" + role + "&entityType=" + entityType + "&entityId=" + entityId, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.addNewRole = addNewRole;

export const modifyRole = (id, customer, role, entityType, entityId, handler, errorHandler) => {
  get("superadminService/modifyRole/" + id + "?customer=" + customer + "&role=" + role + "&entityType=" + entityType + "&entityId=" + entityId, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.addNewRole = addNewRole;

function getAllPaymentMethods(lang, handler, errorHandler) {
  if (typeof lang == "undefined") lang = "";
  get("superadminService/getAllPaymentMethods?lang=" + lang, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.getAllPaymentMethods = getAllPaymentMethods;

function modifyPaymentMethod(id, name, type, active, image, settings, handler, errorHandler) {
  post("superadminService/" + id + "/modifyPaymentMethod", { id: id, name: name, image: image, type: type, isActive: active, settings }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.modifyPaymentMethod = modifyPaymentMethod;

function addPaymentMethod(name, type, active, image, handler, settings, errorHandler) {
  post("superadminService/addPaymentMethod", { name: name, image: image, type: type, isActive: active, settings }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.addPaymentMethod = addPaymentMethod;

function deletePaymentMethod(id, handler, errorHandler) {
  get("superadminService/" + id + "/deletePaymentMethod").done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.deletePaymentMethod = deletePaymentMethod;

export const addRestaurantPaymentMethod = (restaurant, paymentMethod, active, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/addRestaurantPaymentMethod?method=" + paymentMethod + "&active=" + active + "&lang=" + localStorage.language).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.addRestaurantPaymentMethod = addRestaurantPaymentMethod;

export const modifyRestaurantPaymentMethod = (restaurant, id, paymentMethod, active, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/modifyRestaurantPaymentMethod/" + id + "?method=" + paymentMethod + "&active=" + active + "&lang=" + localStorage.language).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.modifyRestaurantPaymentMethod = modifyRestaurantPaymentMethod;

function getAllCurrencies(lang, handler, errorHandler) {
  if (typeof lang == "undefined") lang = "";
  get("superadminService/getAllCurrencies?lang=" + lang, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.getAllCurrencies = getAllCurrencies;

function modifyCurrency(id, name, javaScriptRoundFunction, active, handler, errorHandler) {
  post("superadminService/" + id + "/modifyCurrency", { id: id, name: name, isActive: active, javaScriptRoundFunction: javaScriptRoundFunction }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.modifyCurrency = modifyCurrency;

function addCurrency(name, javaScriptRoundFunction, active, handler, errorHandler) {
  post("superadminService/addCurrency", { name: name, isActive: active, javaScriptRoundFunction: javaScriptRoundFunction }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.addCurrency = addCurrency;

function getAllReports(handler, errorHandler) {
  get("superadminService/getAllReports", localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.getAllReports = getAllReports;

function modifyReport(id, name, properties, active, handler, errorHandler) {
  post("superadminService/modifyReport", { id: id, name: name, isActive: active, properties: properties }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.modifyReport = modifyReport;

function deleteReport(id, handler, errorHandler) {
  get("superadminService/deleteReport/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.deleteReport = deleteReport;

function addReport(name, properties, active, handler, errorHandler) {
  post("superadminService/addReport", { name: name, isActive: active, properties: properties }).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.addReport = addReport;

export const getCurrenciesByRestaurant = (restaurant, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/getCurrenciesByRestaurant?lang=" + localStorage.language, localStorage.id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.getCurrenciesByRestaurant = getCurrenciesByRestaurant;

function addRestaurantCurrency(restaurant, currency, exchangeRate, active, handler, errorHandler) {
  get("superadminService/" + restaurant + "/addRestaurantCurrency?currency=" + currency + "&exchangeRate=" + exchangeRate + "&active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.addRestaurantCurrency = addRestaurantCurrency;

function modifyRestaurantCurrency(restaurant, id, currency, exchangeRate, active, handler, errorHandler) {
  get("superadminService/" + restaurant + "/modifyRestaurantCurrency/" + id + "?currency=" + currency + "&exchangeRate=" + exchangeRate + "&active=" + active).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.modifyRestaurantCurrency = modifyRestaurantCurrency;

function deleteRestaurantCurrency(restaurant, id, handler, errorHandler) {
  get("superadminService/" + restaurant + "/deleteRestaurantCurrency/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.deleteRestaurantCurrency = deleteRestaurantCurrency;

export const deleteRestaurantPaymentMethod = (restaurant, id, handler, errorHandler) => {
  get("superadminService/" + restaurant + "/deleteRestaurantPaymentMethod/" + id).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
};
superadmin.deleteRestaurantPaymentMethod = deleteRestaurantPaymentMethod;

function deleteAllOrders(restaurant, handler, errorHandler) {
  get("superadminService/" + restaurant + "/deleteAllOrders", undefined, undefined, 180000).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.deleteAllOrders = deleteAllOrders;

function deleteAllTableOccupations(restaurant, handler, errorHandler) {
  get("superadminService/" + restaurant + "/deleteAllTableOccupations").done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}
superadmin.deleteAllTableOccupations = deleteAllTableOccupations;

function isSuperAdmin(roles) {
  var result = false;
  roles.forEach(function (role) {
    if (role.role === "superadmin") result = true;
  });
  return result;
}
superadmin.isSuperAdmin = isSuperAdmin;

function resultHandler(data, successHandler, errorHandler) {
  if (data == null) {
    console.log("Failed to modify table");
  } else if (data.error) {
    // eslint-disable-next-line no-eval
    messageDialog(local.error_message, eval(data.error)).done(errorHandler);
  } else {
    successHandler(data);
  }
}
/*
function capturePhotoWithData() {
  // Take picture using device camera and retrieve image as base64-encoded
  // string
  navigator.camera.getPicture(onPhotoDataSuccess, onFail, {
    quality: 50,
    correctOrientation: true,
    destinationType: Camera.DestinationType.DATA_URL
  });
}

function onPhotoDataSuccess(imageData, crop) {
  // Get image handle
  //
  var smallImage = document.getElementById("smallImage");
  // Unhide image elements
  //
  smallImage.style.display = "block";
  // Show the captured photo
  // The inline CSS rules are used to resize the image
  //
  if (imageData.startsWith("data:image")) smallImage.src = imageData;
  else smallImage.src = "data:image/jpeg;base64," + imageData;
  if (typeof crop == "undefined" || crop == true) {
    $("#smallImage").rcrop({
      full: true,
      minSize: [160, 160],
      preserveAspectRatio: true,
      preview: {
        display: false,
        size: [100, 100]
      }
    });
    // $('#smallImage').rcrop("crop");
    $("#crop").show();
  }
  $("#smallImageDiv").removeClass("hidden");

  rcrop = true;
}

function onFail(message) {
  messageDialog(local.error_message, message);
}

function getPhoto() {
  $("div#camera").empty();
  $('<input id="camera" type="file" name="image" accept="image" capture="environment"></input>').appendTo($("div#camera"));
  $("input#camera").click();
}

function crop() {
  var srcOriginal = $("#smallImage").rcrop("getDataURL", 160, 160);
  var smallImage = document.getElementById("smallImage");
  smallImage.src = srcOriginal;
  var sm = $("#smallImage").get(0);
  var r = $("#smallImage").rcrop("destroy");
  $.removeData(sm, "rcrop");
  $(sm).attr("style", "height:160px");
}
*/
export default superadmin;
