import admin, { getMenuItems, getEntities, resultHandler, getQuantityForCount, getMenuCategory, clearSelect2Cache, getMenuItemById, getSelect2Cache } from "../admin";
import $ from "jquery";
import moment from "moment";
import languages from "../../langs/languages";
import { tmplparams } from "../order-list-util";
import auth, { startpoll, isVisible, confirmDialog, executeLongJob, get, getLocale, parseNumber, isTop, whatDecimalSeparator, getKeyboardFocusableElements, post, messageDialog, navigateTo, calcCount } from "../auth";
import Cleave from "cleave.js";
import { createTree } from "jquery.fancytree";
import menuitemutil, { availableQuantities } from "./menuitem";
import { saveAs } from "file-saver";
import { I18n } from "react-redux-i18n";
import { LegacyGoogleProvider } from "leaflet-geosearch";


const { admin_local, local } = languages;

var loaded = false;

var incomingStocks = {
	incomingStocks: null
}

export const clearData = () => {
	loaded = false;
	incomingStocks = {};
	$('body').off('keyup', keyUpHandler);
}

var numeralDecimalMark = whatDecimalSeparator();

export const ready = (props) => {

	loaded = true;
	clearSelect2Cache();

	const def = $.Deferred();
	localStorage.input_price = typeof localStorage.input_price == "undefined" ? 'net_unit_price' : localStorage.input_price;
	getMenuItems(function (data) {
		admin.categories = data;
		incomingStocks.categories = data;
		getEntities("Suppliers", function (data) {
			incomingStocks.suppliers = data;
			$('#incomingStockTemplate').tmpl(tmplparams()).appendTo('#main');

			$('#date.datepicker').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4',
			});
			$('#time.timepicker').timepicker({
				format: 'HH:MM',
				uiLibrary: 'bootstrap4'
			});

			const modal = $('#editIncomingStock');
			modal.find('#draft').on("change", () => {
				modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').each((ind, item) => checkEntry({ currentTarget: item }));
			});


			$('#editIncomingStock select#supplier').on('change', (data) => {
				if ($('#editIncomingStock #entriesFreezeTable > table#entriesTable tbody tr').length === 0) {
					var supplier = $(data.currentTarget).select2('val');
					updateSupplierCodes(supplier)
				}

				var modal = $('div#editIncomingStock');
				modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').each((ind, item) => checkEntry({ currentTarget: item }));

			})

			startpoll(serverSideMessageHandler, [{ Restaurant: localStorage.restaurantSelected }]);

			updateHooks();

			if (typeof localStorage.admin_stocklist_start == 'undefined') {
				localStorage.admin_stocklist_end = moment().toDate().getTime();
				localStorage.admin_stocklist_start = moment().subtract(1, 'month').toDate().getTime();
			}

			if (typeof localStorage.admin_stocklist_range != 'undefined') {
				$("[name='range'][id='" + localStorage.admin_stocklist_range + "']").parent().button('toggle');
			}

			$("[name='range']").change((event) => {
				localStorage.admin_stocklist_range = $("[name='range']:checked").attr('id');
				reload();
			});
			//floatMainTableHead();

			//floatTableHead();
			admin.initializeSelect2($('#IncomingStocks select.select2')).done(() => {
				loadIncomingStocks();
				$("div#listFreezeTable table thead input,div#listFreezeTable table thead select.select2").change(function (e) {
					reload();
				});
			});

			def.resolve();
		});

		$('body').on('keyup', keyUpHandler);

	});

	return def.promise();

}

function keyUpHandler(e) {
	switch (e.key) {
		case '+':
			if (isVisible($('#editIncomingStock.modal'))) {
				const entry = addNewEntry();
				entry.find('input.supplier_code').focus();
			}
			break;
		case 'ArrowDown':
			if (isVisible($('#editIncomingStock'))) {
				var column = $("table#entriesTable").find(':focus')
				var tr = column.parents('tr');
				var td = column.parents('td');
				if (tr.length === 1) {
					var tds = tr.find('td');
					var index = tds.toArray().indexOf(td[0]) + 1;
					tr = $(tr).next();
					td = tr.find('td:nth-child(' + index + ')');
					$(getKeyboardFocusableElements(td)).trigger('focus');
				}
				e.preventDefault();
				e.stopPropagation();
			} else {
			}
			break;
		case 'ArrowUp':
			if (isVisible($('#editIncomingStock'))) {
				column = $("table#entriesTable").find(':focus')
				tr = column.parents('tr');
				td = column.parents('td');
				if (tr.length === 1) {
					tds = tr.find('td');
					index = tds.toArray().indexOf(td[0]) + 1;
					tr = $(tr).prev();
					td = tr.find('td:nth-child(' + index + ')');
					$(getKeyboardFocusableElements(td)).trigger('focus');
				}
				e.preventDefault();
				e.stopPropagation();
			} else {
			}
			break;
		case 'Insert':
			if (e.ctrlKey) {
				if (isVisible($('#editIncomingStock'))) {
					addNewEntry();
					e.preventDefault();
					e.stopPropagation();
				} else {
					$('button#add').click();
				}
			}
			break;
		case 'Delete':
			if (e.ctrlKey) {
				if (isVisible($('#editIncomingStock'))) {
					var data = $("table#entriesTable").find(':focus');
					e.currentTarget = data;
					deleteEntry(e);
				} else {
					$('button#delete').click();
				}
				e.preventDefault();
				e.stopPropagation();
			}
			break;
		case 'Enter':
			if (!isTop($('#editIncomingStock'))) {
				$('button#edit').click();
				e.preventDefault();
			}
			break;
		default:
	}
}

export const reload = () => {
	loadIncomingStocks();
}

function emptyIfNull(val) {
	if (!val || val === "null" || val === "undefined")
		return "";
	return val;
}

function loadIncomingStocks() {
	if (!loaded)
		return;
	var filter = {
		from: localStorage.admin_stocklist_start,
		to: localStorage.admin_stocklist_end,
		supplier: emptyIfNull($('select#supplier_filter').val()),
		menuitem: emptyIfNull($('select#product_filter').val()),
		invoice: emptyIfNull($('input#invoice-filter').val()),
		deliveryNote: emptyIfNull($('input#deliveryNote-filter').val())
	};
	admin.getEntitiesFiltered("IncomingStock", filter, processIncomingStocks);
}

function serverSideMessageHandler(data) {
}

function processIncomingStocks(data) {
	data.forEach(function (v, i) {
		v.title = moment(v.date).format('YYYY-MM-DD   HH:mm');
		v.folder = false;
		v.key = v.id;
	});
	if (!(incomingStocks.incomingStocks))
		incomingStocks.incomingStocks = createTree("#listFreezeTable > table#listTable", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: data,
			zindex: 1000,
			icon: false,
			keyboard: true,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			persist: {
				cookiePrefix: 'fancytree-1-incomingStock',
				expandLazy: false,
				overrideSource: false, // true: cookie takes precedence
				// over `source` data
				// attributes.
				store: "auto" // 'cookie', 'local': use localStore,
				// 'session': sessionStore
			},
			gridnav: {
				autofocusInput: true, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				$('#edit').removeAttr('disabled');
				$('#delete').removeAttr('disabled');
				if (node.data.isActive) {
					$('#deactivate').removeAttr('disabled');
					$('#activate').prop('disabled', 'true');
				} else {
					$('#deactivate').prop('disabled', 'true');
					$('#activate').removeAttr('disabled');
				}

			},
			click: (event, data) => {
				if (incomingStocks.clickTimestamp && incomingStocks.selectedNodeKey === data.node.key && new Date().getTime() - incomingStocks.clickTimestamp < 1000) {
					$('button#edit').click();
				};
				incomingStocks.clickTimestamp = new Date().getTime();
				incomingStocks.selectedNodeKey = data.node.key;
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = true;

				// $tdList.eq(1).text(moment(node.data.date).format('DD/MM/YYYY'));623

				$tdList.eq(1).find('.supplier').html("<span>" + node.data.supplier.name + "</span><span style='font-style: italic'>" + (node.data.modifiedBy ? "" : "*" + admin_local.new) + "</span>");
				//$tdList.eq(1).find('.invoice').text(node.data.invoice_id);
				//$tdList.eq(1).find('.delivery-note').text(node.data.delivery_note_id);
				$tdList.eq(2).find("input").prop('checked', node.data.draft);
				$tdList.eq(3).text(node.data.invoice_id);
				$tdList.eq(4).text(node.data.delivery_note_id);
				$tdList.eq(5).find('input').val(node.data.entries.length);
				var net_price = 0;
				node.data.entries.forEach(function (entry) {
					net_price += entry.net_price;
				})
				var entries = "";
				node.data.entries.forEach((e, ind) => {
					if (e.menuItem)
						getMenuItem(e.menuItem.id, menuItem => {
							if (menuItem) {
								menuItem.availableQuantities.forEach((quantity) => {

									var qt = getLocale(quantity.quantityType.name);
									if (!qt) {
										qt = local[menuItem.quantityType];
									}
									if (quantity.quantity === e.quantity)
										entries += "<div>" + getQuantityForCount(menuItem, e.quantity, e.count) + " " + getLocale(menuItem.name) + "</div>";
								});
								$tdList.eq(7).html(entries);

							}
						});
				})
				if (node.data.entries.length > 2)
					entries += '<div class="total">&nbsp;...</div>';
				$tdList.eq(7).html(entries);
				const td6 = $tdList.eq(6);
				td6.find('input').val(net_price.toLocaleString());
				var cleave = new Cleave(td6.find('input'), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 3,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				});
				$($tdList.eq(6).find('input')).data("cleave", cleave)
				cleave = new Cleave($tdList.eq(5).find('input'), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 0,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				});
				$($tdList.eq(5).find('input')).data("cleave", cleave)
				var other = "";
				if (node.data.createdBy) {
					var t = moment(node.data.created).format('YYYY-MM-DD   HH:mm');
					other += "<div>" + t + " - " + node.data.createdBy.name + " - " + admin_local.created_by + "</div>";
				}
				if (node.data.modifiedBy) {
					t = moment(node.data.modified).format('YYYY-MM-DD   HH:mm');
					other += "<div>" + t + " - " + node.data.modifiedBy.name + " - " + admin_local.modified_by + "</div>";
				}
				$tdList.eq(8).html(other);

			}
		});
	else {
		incomingStocks.incomingStocks.reload(data);
		data = incomingStocks.incomingStocks.getActiveNode();
		$('#edit').prop('disabled', data == null);
		$('#delete').prop('disabled', data == null);
	}
}

var tt = null;
var pattern = null;
var saveDraft = null;

function updateHooks() {

	const exportit = (array, stores, stocks) => {

		const quantities = [];
		stores.forEach(store => {
			quantities.push("s_" + store.name);
		})
		quantities.sort((a, b) => ("" + a).localeCompare("" + b));
		return array.map(m => {
			const path = menuitemutil.getPath(m).split("/");
			getLocale(m.name).split("/").forEach(() => path.pop());
			const iq = m.availableQuantities.find(q => q.quantity == menuitemutil.getInventoryQuantity(m));
			const c = {
				id: m.id,
				category: path.join("/"),
				name: getLocale(m.name),
				manufacturer: m.manufacturer,
				label: m.labels.map(l => l.name).join(" / "),
				quantityType: m.quantityType,
				"inventory quantity unit": menuitemutil.getInventoryQuantity(m),
				cost: iq ? iq.cost : 0,
				vat: ""
			}
			stores.forEach(store => {
				c["s_" + store.name] = 0;
			})

			return c;
		})
	}
	$("#download").click(function (e) {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		get("adminService/" + localStorage.restaurantSelected + "/getStores").done(stores => {
			get("adminService/" + localStorage.restaurantSelected + "/getStocks").done(stock => {
				const ws = window.XLSX.utils.json_to_sheet(exportit(menuitemutil.getFlatMenuItems([], admin.categories.children).filter(m => m.defaultInventoryItem), stores, stock));

				const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
				const excelBuffer = window.XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
				const data = new Blob([excelBuffer], { type: fileType });

				saveAs(data, auth.myStatus.restaurant_name + "s.xlsx");
			});
		});

	});

	$("#upload").click(function () {
		$("#file").attr("files", "");
		$("#file").get(0).value = null;
		$("#file").click();
	});

	$("#file").change(function () {
		if (document.getElementById("file").files[0] == "") return;
		var reader = new FileReader();

		reader.addEventListener("load", () => {
			try {
				localStorage.input_price = "net_unit_price";
				var workbook = window.XLSX.read(reader.result, { type: "binary" });

				workbook.SheetNames.forEach(sheet => {
					let rowObject = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

					incomingStocks.uploadeddata = {
						entries: []
					};

					admin.getVATCategories(vats => {
						admin.getStores(stores => {
							rowObject.map(row => {
								getMenuItem(row.id, menuItem => {
									Object.keys(row).filter(key => key.startsWith("s_") && row[key] != "0").forEach(key => {
										const storeName = key.substring(2);
										const store = stores.find(s => s.name === storeName);
										var vat = undefined;
										if (row.vat) {
											vat = vats.find(v => v.percent === Number(row.vat));
										}
										if (store)
											incomingStocks.uploadeddata.entries.push({
												ignored: false,
												original_quantity: row[key],
												menuItem: { id: menuItem.id, type: menuItem.entityType.toLowerCase() },
												quantityType: row.quantityType,
												quantity: row["inventory quantity unit"],
												store: store,
												count: row[key],
												net_unit_price: row.cost,
												brutto_unit_price: isNaN(row.vat) ? undefined : row.cost * (100 + row.vat) / 100,
												net_price: isNaN(row.vat) ? undefined : row[key] * row.cost * (100 + row.vat) / 100,
												brutto_price: isNaN(row.vat) ? undefined : row[key] * row.cost * (100 + row.vat) / 100,
												vatCategory: vat
											});
									})
								});
							});
							//$("#edit").prop("disabled", false);
							$("#add").trigger('click');
							//$("#edit").prop("disabled", true);
						});
					});

				});

				/*
				  const newmenuItems = JSON.parse(reader.result);
				  */
			} catch (ex) {
				console.log(ex);
			}

		},
			false
		);


		//if (file) {
		reader.readAsBinaryString(document.getElementById("file").files[0]);
		//}

	});



	$('table#entriesTable tfoot .input-numeral').each(function (ind, i) {
		$(i).data("cleave", new Cleave(i, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: false,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		}));
	})

	$('div#editIncomingStock').on('hidden.bs.modal', function (event) {
		$(incomingStocks.incomingStocks).focus();
		$('#listTable').removeClass("hidden");
		var modal = $(this)
		var id = modal.find('#id').val() || "new";
		if (!id && draftId) {
			_deleteIncomingStock(draftId, loadIncomingStocks);
		}
		$('.menuItemHeader').removeClass("hidden");
		$('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').remove();
		if (saveDraft) {
			clearInterval(saveDraft);
			saveDraft = null;
		}
		if (!incomingStocks?.data?.id || incomingStocks?.data?.id == id) {
			incomingStocks.data = undefined;
			//eslint-disable-next-line no-restricted-globals
			navigateTo(location.pathname + "#stockManagementMenu/incomingStocks");
		}
	});

	$('div#editIncomingStock').find('input#autoimport').on("change", event => {
		post("adminService/" + localStorage.restaurantSelected + "/modifySupplier", { id: $('div#editIncomingStock').find('select#supplier').select2('val'), processInvoices: event.currentTarget.checked });
	});
	$('div#editIncomingStock').find('input#autocommit').on("change", event => {
		post("adminService/" + localStorage.restaurantSelected + "/modifySupplier", { id: $('div#editIncomingStock').find('select#supplier').select2('val'), autoCommit: event.currentTarget.checked });
	});

	$("*[data-toggle='tooltip'][title]").tooltip()

	$('div#editIncomingStock').on('shown.bs.modal', function (event) {

		draftId = null;

		$('#listTable').addClass("hidden");
		$('.menuItemHeader').addClass("hidden");

		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		$('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').remove();
		//floatTableHead();

		// attributes
		if (!isNaN(incomingStocks?.data?.id) || incomingStocks.uploadeddata) {
			var data = incomingStocks.data;
			if (incomingStocks.uploadeddata) {
				data = incomingStocks.uploadeddata;
				delete incomingStocks.uploadeddata;
			} else {
				/*
				if (!incomingStocks.incomingStocks.getActiveNode()) {
					modal.hide();
					$('.modal-backdrop').remove();
					return;
				}
				data = incomingStocks.incomingStocks.getActiveNode().data;
				*/
				modal.find('#id').val(data.id);
			}
			modal.find('#invoice_id').val(data.invoice_id);
			modal.find('#delivery_note_id').val(data.delivery_note_id);
			modal.find('#name').val(data.name);
			var formattedDate = moment(data.date).format('YYYY-MM-DD');
			modal.find('input#date').val(formattedDate);
			formattedDate = moment(data.date).format('HH:mm');
			modal.find('input#time').val(formattedDate);

			modal.find('input#draft').prop('checked', data.draft);

			if (data.supplier) {
				modal.find('select#supplier').val(data.supplier.id);
				modal.find('input#autoimport').prop('checked', data.supplier.processInvoices);
				modal.find('input#commit').prop('checked', data.supplier.autoCommit);
			}

			/*			
			modal.find("#entriesFreezeTable > table tbody#entriesTable tr.head select.store, table tbody#entriesTable tr.head select#vat_category,table tbody#entriesTable tr.head select.quantity_type").on('select2:select', function (e) {
				if (modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').length === 0)
					if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.store").select2('val') !== null && $("#entriesFreezeTable > table tbody#entriesTable tr.head select#vat_category").select2('val') !== null && $("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2('val') !== null)
						addNewEntry();
				e.preventDefault();
			});
			*/
			if (data.entries.length)
				data.entries.forEach(function (entry) {
					addNewEntry(entry);
				})

			modal.find('select#supplier').trigger('change');



		} else {
			modal.find('#id').val("");
			modal.find('#invoice_id').val("");
			modal.find('#delivery_note_id').val("");
			modal.find('#id').val("");
			modal.find('#active').prop("checked", 'true');
			modal.find("#entriesFreezeTable > table tbody#entriesTable tr.head select.store, table tbody#entriesTable tr.head select#vat_category,table tbody#entriesTable tr.head select.quantity_type").change(function (e) {
				if (modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').length === 0)
					if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.store").select2('val') !== null && $("#entriesFreezeTable > table tbody#entriesTable tr.head select#vat_category").select2('val') !== null && $("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2('val') !== null)
						addNewEntry();
				e.preventDefault();
			});
			modal.find('select#supplier').val("");
			modal.find('select#supplier').trigger('change');
			var date = moment();
			formattedDate = date.format('YYYY-MM-DD');
			modal.find('input#date').val(formattedDate);
			formattedDate = date.format('HH:mm');
			modal.find('input#time').val(formattedDate);
			//$(".datepicker").datepicker("setDate", new Date());
			$("textarea#regexp").keyup(function (e) {
				var p = $("textarea#regexp").val();
				if (pattern !== p && p !== "" && $("textarea#rawtextdata").val() !== "") {
					tt = new Date().getTime();
					var ttt = tt;
					setTimeout(function () {
						if (ttt === tt) {
							pattern = $("textarea#regexp").val();
							tryExecuteRegexp();
						}
					}, 1000);
				}
				e.preventDefault();
			});
			modal.find('input#draft').prop('checked', false);
			$('#entriesFreezeTable > table tfoot#entriesTable input#total_net_price').data('cleave').setRawValue(0);
			$('#entriesFreezeTable > table tfoot#entriesTable input#total_brutto_price').data('cleave').setRawValue(0);
			$('#entriesFreezeTable > table tfoot#entriesTable div#total').html(String.format(admin_local.total_incoming_stock_entries, 0));
			saveDraft = setInterval(() => saveIncomingStock(true), 20000);

		}

		/*
		$('#editIncomingStock textarea').resize(function (e) {
			//unfloatTableHead();
			//floatTableHead();
		});
		*/
		//updateSupplierCodes($('#editIncomingStock select#supplier').select2('val'));

	})
}

function _addNewEntry2(data, index, tbody) {
	var existingRow = tbody.find('tr:nth-child(' + index + ')');
	if (existingRow.length === 1) {
		existingRow.removeClass('existing');
		Object.keys(data).forEach(function (key) {
			var value = data[key];
			var input = existingRow.find('input#' + key);
			if (input.attr('type') === 'number')
				value = parseNumber(value);
			existingRow.find('input#' + key).val(value);
		});
	} else {
		data.forEach(data => {
			var entry = $("script#incomingStockEntryTemplate").tmpl({ ...data, ...tmplparams() });
			$('table tbody#entriesTable').append(entry);
		});
		// admin.initializeSelect2(entry.find('select.select2'));
	}
}
function _updateEntries2() {
	admin.initializeSelect2($('table#entriesTable tbody tr:not(.head) select.select2:not(.select2-hidden-accessible)'));
}

function _addNewEntry(data) {
	var entry = $("script#incomingStockEntryTemplate").tmpl({ ...data, ...tmplparams() });
	entry.appendTo('div#editIncomingStock table tbody');
	admin.initializeSelect2(entry.find('select.select2'));
	var default_store = data.store ? data.store : $("tr.head select.store").select2('val');
	if (!default_store && $("tr.head select.store option").length == 1) {
		default_store = $("tr.head select.store option").attr("value");
	}
	if (default_store !== null) {
		var s = entry.find('select.store').select2();
		s.val(default_store);
		s.trigger('change');
	}
	var default_vat_category = data.vat_category ? data.vat_category : $("tr.head select#vat_category").select2('val');
	if (default_vat_category !== null) {
		s = entry.find('select#vat_category').select2();
		s.val(default_vat_category);
		s.trigger('change');
	}
	var default_quantity_type = data.quantity_type ? data.quantity_type : $("tr.head select.quantity_type").select2('val');
	if (default_quantity_type !== null) {
		s = entry.find('select.quantity_type').select2();
		s.val(default_quantity_type);
		s.trigger('change');
	}
}

function _updateEntries() {
	var entry = $("table tbody tr");
	entry.find('.datepicker').datepicker({
		format: 'yyyy-mm-dd',
		uiLibrary: 'bootstrap4',
		width: "small"
	});
	entry.find('select.product').off("select2:close");
	entry.find('select.product').on("select2:close", function (data) {
		if (!$(data.currentTarget).parents('tr').hasClass('error'))
			return;
		var nullFound = false;
		$('table tbody tr select.product').each(function (ind, item) {
			if ($(item).select2('val') == null) {
				nullFound = true;
			}
		});
		if (!nullFound) {
			addNewEntry();
		}
	});
	entry.find('select.product').off('select2:select');
	entry.find('select.product').on('select2:select', function (e) {
		var menuItemId = $(this).select2('val');
		menuItemId && getMenuItem(menuItemId, menuItem => {
			var t = $(this).parents('tr');
			var val = t.find('select.quantity_type.select2').select2("val");
			t.find('select.quantity_type.select2-hidden-accessible').select2('destroy');
			t.find('select.quantity_type option').remove();
			var quantityTypes = [];
			menuItem.availableQuantities.forEach((quantity) => {
				if (quantity.inventoryItem === true) {
					var qt = getLocale(quantity.quantityType.name);
					if (!qt) {
						qt = local[menuItem.quantityType];
					}
					quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
				}
			});
			if (quantityTypes.length === 0) {
				quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
			}
			t.find('select.quantity_type').select2({
				data: quantityTypes
			});
			var s = t.find('select.quantity_type.select2').select2();
			s.val(val);
			s.trigger('change');
			updateCount(e);
			handleProductChange(e, $(this).parents("tr"));
		});
	});
	//entry.find('select.product').trigger('change');

	entry.find('input').change(updateCount);
	entry.find('select').off('select2:select', updateCount);
	entry.find('select').on('select2:select', updateCount);
	/*
	entry.find('.input-numeral:not(.count)').each(function (ind, i) {
		$(i).data(
			"cleave", new Cleave(i, {
				numeral: true,
				numeralThousandsGroupStyle: 'thousand',
				numeralPositiveOnly: false,
				numeralDecimalScale: 3,
				delimiter: ' ',
				numeralDecimalMark: numeralDecimalMark
			}));
	})*/
	checkEntry({ currentTarget: entry[0] });

}
/*
export const getSelectVal = (data, name) => {
	return $(data.currentTarget).parents('tr').find('select#' + name).select2('val');
}
*/
const getVATVal = (data) => {
	if (!$(data.currentTarget).parents('tr').find('select.vat_category').select2())
		return undefined;
	var r = $(data.currentTarget).parents('tr').find('select.vat_category').select2('val');
	if (r)
		return r.split(';')[1];
	return undefined;
}

export const categorySelected = () => {
	const categoryId = $("#parentCategory.select2").select2('val');

	if (!categoryId) {
		$("#parentCategory.select2 + span").removeClass('highlight-3');
		setTimeout(() => {
			$("#parentCategory.select2 + span").addClass('highlight-3');
		}, 100)
		return;
	}

	const category = getMenuCategory(categoryId);
	const entityType = category.entityType.split("Category")[0];
	$('#editMenuItem').remove();
	menuitemutil.entityType = entityType;
	menuitemutil.entitytype = entityType.toLowerCase();
	$('#editMenuItemTemplate').tmpl({ onsave: "afternewitem", entityType: entityType, entitytype: entityType.toLowerCase(), availableQuantities: availableQuantities, ...tmplparams() }).appendTo("#main");
	menuitemutil.hooks();
	const modal = $('#editMenuItem');
	modal.find("#parentCategoryId").val(categoryId);
	modal.find("#mi_active").prop("checked", "true");
	modal.find("#mi_isInventoryItem").prop("checked", "true");
	modal.find("#quantityType").prop("disabled", null);
	menuitemutil.processMenuItemQuantities(
		[
			{
				title: "",
				folder: false,
				label: {},
				quantity: 1,
				price: undefined,
				def: true,
				inventoryItem: true
			}
		],
		{}
	);
	$('#newMenuItem').modal('hide');
	$('#editMenuItem').modal('show')
}

function handleProductChange(event, entry) {
	var menuItemId;
	if (entry) {
		menuItemId = entry.find('select.product.select2').select2('val');
	} else {
		entry = $(this).parents('tr');
		menuItemId = $(this).val();
	}

	if (menuItemId === "-1") {
		if ($('#newMenuItem').length === 0) {
			$('#newMenuItemTemplate').tmpl({ ...tmplparams() }).appendTo("#main");
			admin.initializeSelect2($('#newMenuItem').find('select.select2'));
			$('#newMenuItem').find('select.select2').on('select2:select', categorySelected);
		};
		$('#newMenuItem').modal('show')
		return;
	}


	function r(menuItem) {
		entry.find('select.quantity_type.select2-hidden-accessible').select2('destroy');
		entry.find('select.quantity_type option').remove();
		var quantityTypes = [];
		if (menuItem) {
			updatePattern(entry, menuItem.quantityType);

			menuItem.availableQuantities.forEach((quantity) => {
				if (quantity.inventoryItem === true) {

					quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
				}
			});
		}
		if (quantityTypes.length === 0) {
			quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
		}
		entry.find('select.quantity_type').select2({
			data: quantityTypes
		});

	}

	if (menuItemId)
		getMenuItem(menuItemId, r);
}

function updateCount(data) {
	setTimeout(() => _updateCount(data), 200);
}

function _updateCount(data) {
	try {
		var count = parseNumber($(data.currentTarget).parents('tr').find('input.count').val());
		var vat = getVATVal(data)
		var lastset = localStorage.input_price;
		if (!$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave'))
			return;
		switch (lastset) {
			case 'net_unit_price':
				var net_unit_price = parseNumber($(data.currentTarget).parents('tr').find('input.net_unit_price').val());
				$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(net_unit_price * (Number(vat) + 100) / 100);
				$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(count * net_unit_price);
				$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(+ count * net_unit_price * (Number(vat) + 100) / 100);
				break;
			case 'brutto_unit_price':
				var brutto_unit_price = parseNumber($(data.currentTarget).parents('tr').find('input.brutto_unit_price').val());
				$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(brutto_unit_price / (Number(vat) + 100) * 100);
				$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(count * brutto_unit_price / (Number(vat) + 100) * 100);
				$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(count * brutto_unit_price);
				break;
			case 'net_price':
				var net_price = parseNumber($(data.currentTarget).parents('tr').find('input.net_price').val());
				$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(net_price / count);
				$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(net_price * (Number(vat) + 100) / 100 / count);
				$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(net_price * (Number(vat) + 100) / 100);
				break;
			case 'brutto_price':
				var brutto_price = parseNumber($(data.currentTarget).parents('tr').find('input.brutto_price').val());
				$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(brutto_price / (Number(vat) + 100) * 100 / count);
				$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(brutto_price / count);
				$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(brutto_price / (Number(vat) + 100) * 100);
				break;
			default:
		}
		setTimeout(() => { checkEntry(data) }, 100);
	} catch (e) {
		//console.error(e);
	}
}

function updatePattern(entry, quantityType) {
	if (quantityType === "dl" || quantityType === "cl" || quantityType === "l") {
		entry.find('.input-numeral.count').attr("pattern", "-?\\d*[,.]?\\d+ ?(l|dl|cl)?")
	} else if (quantityType === "dkg" || quantityType === "g" || quantityType === "kg") {
		entry.find('.input-numeral.count').attr("pattern", "-?\\d*[.,]?\\d+ ?(g|dkg|kg)?")
	} else if (quantityType === "pcs") {
		entry.find('.input-numeral.count').attr("pattern", "(\\d*[.,]?\\d+) ?(" + local.pcs + ")?")
	} else {
		entry.find('.input-numeral.count').attr("pattern", "-?\\d*[,.]?\\d+")
	}
}

export const addNewEntry = (data2, supplierId) => {
	var data = null;
	$('.select2.select2-container.select2-container--default.select2-container--below.select2-container--open').prev('select').select2('close');

	if (typeof data2 == "undefined") {
		data = { store: {}, vatCategory: {}, quantityType: {}, ignored: false }
	} else
		data = data2;
	var entry = $("script#incomingStockEntryTemplate").tmpl({ ...data, ...tmplparams() });
	$('div#editIncomingStock table tbody').append(entry);
	entry.find('.input-numeral.multiplicator').each(function (ind, i) {
		$(i).data(
			"cleave", new Cleave($(i), {
				numeral: true,
				numeralThousandsGroupStyle: 'thousand',
				numeralPositiveOnly: false,
				numeralDecimalScale: 3,
				delimiter: ' ',
				numeralDecimalMark: numeralDecimalMark,
			}));
	})

	entry.find('.input-numeral.input_price').each(function (ind, i) {
		$(i).data(
			"cleave", new Cleave($(i), {
				numeral: true,
				numeralThousandsGroupStyle: 'thousand',
				numeralPositiveOnly: false,
				numeralDecimalScale: 3,
				delimiter: ' ',
				numeralDecimalMark: numeralDecimalMark,
			}));
	})
	entry.find('.input-numeral.count').attr("pattern", "-?\\d*[,.]?\\d+");
	entry.find('.input-numeral.count').each(function (ind, i) {
		$(i).data(
			"cleave", new Cleave($(i), {
				numeral: false,
				numeralPositiveOnly: false,
				/*
				numeralThousandsGroupStyle: 'thousand',
				numeralPositiveOnly: true,
				numeralDecimalScale: 3,
				delimiter: ' ',
				numeralDecimalMark: numeralDecimalMark,
				*/
			}));
	})
	entry.find('.input-numeral.count').on("change", (event) => {
		if (!event.currentTarget.checkValidity()) {
			var value = $(event.currentTarget).data("value");
			$(event.currentTarget).val(value);
			$(event.currentTarget).trigger('change');
		} else {
			var value = event.currentTarget.value.replace(/,/g, '.');
			if (isNaN(value)) {
				const quantityType = entry.find('select.quantity_type').select2('val').split(';')[1];
				const quantity = entry.find('select.quantity_type').select2('val').split(';')[0];
				const found = value.match("(\\d*[,\\.]?\\d+) ?(cl|dl|l|g|dkg|kg|" + local.pcs + ")");
				if (!found) {
					$(event.currentTarget).val($(event.currentTarget).data("value"));
					$(event.currentTarget).trigger('change');
					return;
				}
				value = calcCount(value, quantityType, quantity);
				$(event.currentTarget).val(value);
				$(event.currentTarget).data("value", value);
				$(event.currentTarget).trigger('change');

			} else {
				if (value != event.currentTarget.value) {
					$(event.currentTarget).val(value);
					$(event.currentTarget).data("value", value);
					$(event.currentTarget).trigger('change');
				} else
					$(event.currentTarget).data("value", event.currentTarget.value);
			}
			if (entry.find('input.original_quantity').val()) {
				entry.find('input.original_quantity').data('multiplicator', entry.find('input.original_quantity').val() / entry.find('input.count').val())
			}
		}
	})
	entry.find('.input-numeral.count').on("keyup", (event) => {
		if (!event.currentTarget.checkValidity()) {
			$(event.currentTarget).addClass("invalid");
		} else {
			$(event.currentTarget).removeClass("invalid");
		}
	})


	entry.find('input.count').val(data.count);
	if (data.original_quantity) {
		entry.find('input.original_quantity').val(Math.round(data.original_quantity * 1000) / 1000);
	} else if (data.multiplicator != 1 && data.count) {
		entry.find('input.original_quantity').val(Math.round(data.count / data.multiplicator * 1000) / 1000);
	} else {
		entry.find('input.original_quantity').val(Math.round(data.count * 1000) / 1000);
	}
	entry.find('input.original_quantity').data("multiplicator", data.multiplicator);
	entry.find('input.net_unit_price').data('cleave').setRawValue(data.net_unit_price);
	entry.find('input.brutto_unit_price').data('cleave').setRawValue(data.brutto_unit_price);
	entry.find('input.net_price').data('cleave').setRawValue(data.net_price);
	entry.find('input.brutto_price').data('cleave').setRawValue(data.brutto_price);

	//entry.appendTo();
	admin.initializeSelect2(entry.find('select.select2')).done(function () {
		var menuItemId = $(this).select2('val');
		if (!menuItemId && data.menuItem) menuItemId = data.menuItem.id
		if (menuItemId) {
			getMenuItem(menuItemId, menuItem => {
				const newValue = calcCount(data.count, menuItem.quantityType, data.quantity);
				if (newValue != data.count) {
					entry.find('input.count').val(newValue);
					_updateCount({ currentTarget: entry.find('input.count').get(0) });
				}
				if (!menuItem.defaultInventoryItem || menuItem.type === "deleted") {
					var newOption = new Option(getLocale(menuItem.name), menuItem.id, false, false);
					entry.find('select.product').append(newOption).trigger('change');
					entry.find('select.product').select2().val(menuItem.id);
					entry.find('select.product').append(newOption).trigger('change');
					handleProductChange(null, entry);
					checkEntry({ currentTarget: entry });
				}
			});
		}

		var default_store = data.store && data.store.id ? data.store.id : $("tr.head  select#store").select2('val');
		if (!default_store && getSelect2Cache("Stores.undefined")?.length == 1) {
			default_store = getSelect2Cache("Stores.undefined")[0].id;
		}
		if (default_store !== null) {
			var s = entry.find('select.store').select2();
			s.val(default_store);
			s.trigger('change');
		}
		var default_vatCategory = data.vatCategory && data.vatCategory.id ? data.vatCategory.id + ";" + data.vatCategory.percent : $("tr.head select#vat_category").select2('val');
		if (default_vatCategory !== null) {
			s = entry.find('select.vat_category').select2();
			s.val(default_vatCategory);
			s.trigger('change');
		}
		_updateCount({ currentTarget: entry.find('input.count').get(0) });
		entry.find('select.product').on('select2:select', handleProductChange);
		if (data && data.menuItem) {
			entry.find('select.product').val(data.menuItem.id);
			entry.find('select.product').trigger('select2:select');
			entry.find('select.product').trigger('change');
			entry.find('select.quantity_type').val(data.quantity);
			entry.find('select.quantity_type').trigger('change');
		}
		var default_quantity_type = data.quantityType ? data.quantityType : null;
		if (default_quantity_type !== null && data.menuItem) {
			updatePattern(entry, default_quantity_type);

			s = entry.find('select.quantity_type');
			getMenuItem(data.menuItem.id, menuItem => {
				s.val(data.quantity + ";" + menuItem.quantityType);
				s.trigger('change');
			})
		}
		/*
		entry.find('input.net_unit_price').val(data.net_unit_price ? data.net_unit_price.toLocaleString() : "");
		entry.find('input.brutto_unit_price').val(data.brutto_unit_price ? data.brutto_unit_price.toLocaleString() : "");
		entry.find('input.net_price').val(data.net_price ? data.net_price.toLocaleString() : "");
		entry.find('input.brutto_price').val(data.brutto_price ? data.brutto_price.toLocaleString() : "");
		*/
		entry.find('.datepicker').datepicker({
			format: 'yyyy-mm-dd',
			uiLibrary: 'bootstrap4'
		});
		if (data.expiryDate) {
			var formattedDate = moment(data.expiryDate).format('YYYY-MM-DD');
			entry.find('input.expiry').val(formattedDate);
		}

		entry.find('input.net_unit_price').change(function (data) {
			var count = parseNumber($(data.currentTarget).parents('tr').find('input.count').val());
			$(data.currentTarget).parents('tr').find('input').removeClass('lastset');
			$(data.currentTarget).addClass('lastset');
			var value = parseNumber($(data.currentTarget).val());
			var vat = getVATVal(data)
			$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(value * (Number(vat) + 100) / 100);
			$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(count * value);
			$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(count * value * (Number(vat) + 100) / 100);
			setTimeout(() => { checkEntry(data) }, 100);
		});
		entry.find('input.brutto_unit_price').change(function (data) {
			var count = parseNumber($(data.currentTarget).parents('tr').find('input.count').val());
			$(data.currentTarget).parents('tr').find('input').removeClass('lastset');
			$(data.currentTarget).addClass('lastset');
			var value = parseNumber($(data.currentTarget).val());
			var vat = getVATVal(data)
			$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(value * 100 / (Number(vat) + 100));
			$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(count * value * 100 / (Number(vat) + 100));
			$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(count * value);
			setTimeout(() => { checkEntry(data) }, 100);
		});
		entry.find('input.net_price').change(function (data) {
			var count = parseNumber($(data.currentTarget).parents('tr').find('input.count').val());
			$(data.currentTarget).parents('tr').find('input').removeClass('lastset');
			$(data.currentTarget).addClass('lastset');
			var value = parseNumber($(data.currentTarget).val());
			var vat = getVATVal(data)
			$(data.currentTarget).parents('tr').find('input.brutto_price').data('cleave').setRawValue(value * (Number(vat) + 100) / 100);
			$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(value / count);
			$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(value * (Number(vat) + 100) / 100 / count);
			setTimeout(() => { checkEntry(data) }, 100);
		});
		entry.find('input.brutto_price').change(function (data) {
			var count = parseNumber($(data.currentTarget).parents('tr').find('input.count').val());
			$(data.currentTarget).parents('tr').find('input').removeClass('lastset');
			$(data.currentTarget).addClass('lastset');
			var value = parseNumber($(data.currentTarget).val());
			var vat = getVATVal(data)
			$(data.currentTarget).parents('tr').find('input.net_price').data('cleave').setRawValue(value * 100 / (Number(vat) + 100));
			$(data.currentTarget).parents('tr').find('input.net_unit_price').data('cleave').setRawValue(value * 100 / (Number(vat) + 100) / count);
			$(data.currentTarget).parents('tr').find('input.brutto_unit_price').data('cleave').setRawValue(value / count);
			setTimeout(() => { checkEntry(data) }, 100);
		});
		entry.find('input.input.input_price').parent().on('dblclick', (e) => {
			var priceSelected = $(e.currentTarget).find('input').attr('class');
			setInputPrice(priceSelected.split(' ')[3]);
		})

		if (typeof data.product == "undefined")
			entry.find('input.supplier_code').change(function (data) {
				var code = $(data.currentTarget).val();
				if (code === "")
					return;
				//var product = $(data.currentTarget).parents('tr').find('select.product').val();
				var supplier = supplierId || $('#editIncomingStock').find('select#supplier').select2('val');
				// iincf (product==undefined) {
				var p = $(data.currentTarget).parents('tr').find('select.product');
				var v = $(data.currentTarget).parents('tr').find('select#vat_category');
				var qt = $(data.currentTarget).parents('tr').find('select.quantity_type');
				var s = $(data.currentTarget).parents('tr').find('select.store');
				var c = $(data.currentTarget).parents('tr').find('input.count');
				var m = $(data.currentTarget).parents('tr').find('input.original_quantity');
				getDefaultProductForSupplierCode(supplier, code, function (data) {
					if (data.length > 0) {
						p.val(data[0].menuItem);
						if (!data[0].menuItem) {
							entry.addClass("ignored");
						}
						p.trigger('change');
						m.data('multiplicator', data[0].multiplicator);
						m.trigger('change');
						if (m.val() && data[0].multiplicator != 0) {
							const count = Math.round(m.val() * data[0].multiplicator * 1000) / 1000;
							//c.data('cleave').setRawValue("" + count);
							c.val("" + count);
							c.trigger('change');
						}
						p.trigger('select2:select');
						if (data[0].store) {
							s.val(data[0].store);
							s.trigger('change');
							//s.trigger('select2:select');
						}
						v.val(data[0].vatCategory);
						v.trigger('change');
						setTimeout(() => {
							qt.val(data[0].quantity + ";" + data[0].quantityType);
							qt.trigger('change');
						}, 100)
					}
				});
				// }
			});
		entry.find('input').change(updateCount);
		entry.find('select').change(updateCount);

		if (data && data.code) {
			entry.find('input.supplier_code').val(data.code);
		} else {
		}

		checkEntry({ currentTarget: entry[0] });

		if (data2 === undefined) {
			const div = $("#entriesFreezeTable").get(0);
			div.scrollTop = div.scrollHeight - div.clientHeight;
			entry.find('select.product').select2('open');
		}

	});
	return entry;

}

export const afternewitem = (menuitem) => {
	// $('select.product.select2.select2-hidden-accessible').select2('destroy');
	//$('select.product.select2').select2({ data: [{ text: "jhgjhg", id: 3242 }] });
	const oldValues = [];
	getMenuItems(function (data) {
		admin.categories = data;
		incomingStocks.categories = data;
		$('select.product.select2.select2-hidden-accessible').each((ind, item) => {
			oldValues.push([$(item).attr('id'), $(item).select2('val')]);
		})
		$('select.product.select2-hidden-accessible').select2('destroy');
		$('select.product option').remove();
		$('select.product optgroup').remove();
		admin.initializeSelect2($('select.product'), true).done(() => {
			oldValues.forEach(item => {
				if (item[1] === "-1") {
					$('#' + item[0]).val("" + menuitem.id);
					$('#' + item[0]).trigger('change');
					$('#' + item[0]).trigger('select2:select');
					//$('select.product').on('select2:select', handleProductChange);
				} else {
					$('#' + item[0]).val(item[1]);
					$('#' + item[0]).trigger('select2:select');
					$('#' + item[0]).trigger('change');
				}
			})
		});
	});

}

var draftId = null;

export const saveIncomingStock = (draft) => {
	if (!draft) {
		clearInterval(saveDraft);
		saveDraft = null;
	}
	var modal = $('div#editIncomingStock');
	var id = modal.find('#id').val();
	//var name = modal.find('#name').val();
	var incomingStock = {};
	incomingStock.supplier = { id: modal.find('select#supplier').select().val() };
	if (!incomingStock.supplier.id)
		return;
	incomingStock.invoice_id = modal.find('input#invoice_id').val();
	incomingStock.delivery_note_id = modal.find('input#delivery_note_id').val();
	incomingStock.date = moment(modal.find('input#date').val() + " " + modal.find('input#time').val()).toDate().getTime();
	incomingStock.draft = id || !draft ? modal.find('input#draft').prop('checked') : true;
	incomingStock.entries = [];
	const entries = [];
	modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').each(function (ind, e) {
		var product = $(e).find('select.product').select2('val');;
		//if (!incomingStock.draft && product == null)
		//	return;
		var entry = {};
		entry.count = parseNumber($(e).find('input.count').val());
		if (entry.count == null || entry.count === 0)
			return;
		if (product)
			getMenuItem(product, menuItem => {
				try {
					entry.menuItem = { id: product, type: menuItem.entityType.toLowerCase() };
					entry.code = $(e).find('input.supplier_code').val();
					const oc = $(e).find('input.original_quantity').val();
					if (oc)
						entry.multiplicator = entry.count / oc;
					else
						entry.multiplicator = 1;
					entry.store = { id: $(e).find('select.store').select2('val') };
					entry.quantity = $(e).find('select.quantity_type').select2('val').split(';')[0];
					entry.vatCategory = { id: $(e).find('select.vat_category').select2('val').split(';')[0] };
					entry.quantityType = $(e).find('select.quantity_type').select2('val').split(';')[1];
					entry.id = $(e).attr('id');
					entry.net_unit_price = parseNumber($(e).find('input.net_unit_price').val());
					entry.brutto_unit_price = parseNumber($(e).find('input.brutto_unit_price').val());
					entry.net_price = parseNumber($(e).find('input.net_price').val());
					entry.brutto_price = parseNumber($(e).find('input.brutto_price').val());
					entry.expiryDate = new Date($(e).find('input.expiry').val()).getTime();
				} catch (ex) {

				}
				if (entry.menuItem && entry.menuItem.id && entry.store && entry.store.id && entry.vatCategory && entry.vatCategory.id) {
					entries.push(e);
					incomingStock.entries.push(entry);
				}
			});
		else {
			try {
				entry.menuItem = null;
				entry.code = $(e).find('input.supplier_code').val();
				const oc = $(e).find('input.original_quantity').val();
				if (oc)
					entry.multiplicator = entry.count / oc;
				else
					entry.multiplicator = 1;
				entry.store = { id: $(e).find('select.store').select2('val') };
				entry.quantity = null;
				entry.vatCategory = { id: ($(e).find('select.vat_category').select2('val') && $(e).find('select.vat_category').select2('val').split(';')[0]) || null };
				entry.quantityType = null;
				entry.id = $(e).attr('id');
				entry.net_unit_price = parseNumber($(e).find('input.net_unit_price').val());
				entry.brutto_unit_price = parseNumber($(e).find('input.brutto_unit_price').val());
				entry.net_price = parseNumber($(e).find('input.net_price').val());
				entry.brutto_price = parseNumber($(e).find('input.brutto_price').val());
				entry.expiryDate = new Date($(e).find('input.expiry').val()).getTime();
			} catch (ex) {
				console.error(ex);
			}
			if (entry.store && entry.store.id && entry.vatCategory && entry.vatCategory.id) {
				entries.push(e);
				incomingStock.entries.push(entry);
			}
		}
	});

	if (!id && draftId) {
		id = draftId;
	}

	if (draft && incomingStock.entries.length === 0)
		return;

	if (!draft)
		draftId = null;

	if (id) {
		incomingStock.id = id;
		modifyIncomingStock(incomingStock, data => {
			entries.forEach((e, ind) => {
				$(e).attr('id', data.entries[ind].id);
			});
			if (!draft) {
				loadIncomingStocks(data);
				draftId = null;
			}
		});
	} else {
		addIncomingStock(incomingStock, data => {
			if (draft)
				draftId = data.id;
			else
				draftId = null;
			entries.forEach((e, ind) => {
				$(e).attr('id', data.entries[ind].id);
			});
			if (!draft)
				loadIncomingStocks(data);
		});
	}
}
/*
export const activateIncomingStock = (active) => {
	var data = incomingStocks.incomingStocks.getActiveNode().data;
	modifyIncomingStock(data.id, data.name, data.percent, active, loadIncomingStocks);
}*/

export const deleteIncomingStock = () => {
	var data = incomingStocks.incomingStocks.getActiveNode().data;
	confirmDialog(local.confirmation, String.format(admin_local.are_you_sure_you_want_to_delete_the_incoming_stock, data.name)).done(function () {
		_deleteIncomingStock(data.id, loadIncomingStocks);
	});

}

function addIncomingStock(incomingStock, handler, errorHandler) {
	//eslint-disable-next-line no-useless-concat,dot-location
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/addIncomingStock" + "?lang=" + localStorage.language, false, incomingStock).
		done(function (data) { resultHandler(data, handler, errorHandler) });
}

function modifyIncomingStock(incomingStock, handler, errorHandler) {
	//eslint-disable-next-line no-useless-concat,dot-location
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/modifyIncomingStock" + "?lang=" + localStorage.language, false, incomingStock).
		done(function (data) { resultHandler(data, handler, errorHandler) });
}

function _deleteIncomingStock(id, handler, errorHandler) {
	//eslint-disable-next-line no-useless-concat,dot-location
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/deleteIncomingStock/" + id + "?lang=" + localStorage.language).
		done(function (data) { resultHandler(data, handler, errorHandler) });
}

export const setInputPrice = (input_price) => {
	localStorage.input_price = input_price;
	$('input.input_price:not(.quantity):not(.count)').attr('disabled', true);
	$('input.input_price.' + input_price).attr('disabled', null);
}

function checkEntry(e) {
	var modal = $('div#editIncomingStock');
	var ok = true;
	var entry = e.currentTarget.localName === 'tr' ? $(e.currentTarget) : $(e.currentTarget).parents('tr');
	var v = modal.find('select#supplier').select2('val');
	ok = ok && v > 0;
	v = parseNumber(entry.find('input.count').val());
	//ok = ok && v !== undefined && (v );
	if (v > 0 || v < 0) {
		entry.removeClass('gray');

		const draft = modal.find('input#draft').prop('checked');

		const productSet = !!entry.find('select.product.select2-hidden-accessible').select2('val');
		const quantitySet = !!entry.find('select.quantity_type.select2-hidden-accessible').select2('val');
		const storeSet = entry.find('select.store.select2-hidden-accessible').length && entry.find('select.store').select2('val');

		//ok = ok && (draft || quantitySet);
		//ok = ok && (draft || productSet);

		ok = ok && !!entry.find('select.vat_category.select2-hidden-accessible').select2('val');

		v = parseNumber(entry.find('input.net_price').val());
		ok = ok && v !== undefined && v !== "";
		v = parseNumber(entry.find('input.brutto_price').val());
		ok = ok && v !== undefined && v !== "";
		v = parseNumber(entry.find('input.net_unit_price').val());
		ok = ok && v !== undefined && v !== "";
		v = parseNumber(entry.find('input.brutto_unit_price').val());
		ok = ok && v !== undefined && v !== "";
		//v = draft || productSet;
		//ok = ok && v !== undefined && v !== "" && v > 0;
		v = draft || !productSet || storeSet;
		ok = ok && v !== undefined && v !== "" && v > 0;
		v = draft || !productSet || quantitySet;
		ok = ok && v !== undefined && v !== "";
		v = new Date(entry.find('input.expiry').val()).getTime();
		ok = ok && (isNaN(v) || v > 0);
	} else {
		entry.addClass('gray');
	}
	if (ok)
		entry.removeClass('error');
	else {
		entry.addClass('error');
	}
	$('button#save').attr('disabled', $('#entriesFreezeTable > table tbody#entriesTable tr.error').length > 0)
	var net_price = 0;
	var brutto_price = 0;
	var count = 0;
	$('#entriesFreezeTable > table tbody#entriesTable tr').each(function (ind, entry) {
		net_price += Number(parseNumber($(entry).find('input.net_price').val()));
		brutto_price += Number(parseNumber($(entry).find('input.brutto_price').val()));
		count++;
	});
	$('#entriesFreezeTable > table tfoot#entriesTable input#total_net_price').data('cleave').setRawValue(net_price);
	$('#entriesFreezeTable > table tfoot#entriesTable input#total_brutto_price').data('cleave').setRawValue(brutto_price);
	$('#entriesFreezeTable > table tfoot#entriesTable div#total').html(String.format(admin_local.total_incoming_stock_entries, count));
}

export const deleteEntry = (e) => {
	var entry = e.currentTarget.localName === 'tr' ? $(e.currentTarget) : $(e.currentTarget).parents('tr');
	var p = entry.next();
	if (p.length === 0)
		p = entry.prev();

	var column = entry.find(':focus')
	var td = column.parents('td');
	var tds = entry.find('td');
	var index = tds.toArray().indexOf(td[0]) + 1;

	entry.remove();
	$('button#save').attr('disabled', $('table tbody#entriesTable tr.error').length > 0);
	if (p.length === 1) {
		try {
			$(getKeyboardFocusableElements(p)).trigger('focus');
			td = p.find('td:nth-child(' + index + ')');
			$(getKeyboardFocusableElements(td)).trigger('focus');
		} catch (ex) { }
	}
	_updateEntries();
}


function tryExecuteRegexp() {
	try {
		// $('table tbody tr:not(.head)').remove();
		var tbody = $('table tbody#entriesTable');
		var regexp = $('textarea#regexp').val();
		var text = $('textarea#rawtextdata').val();
		regexp = new RegExp(regexp, 'ig');
		tbody.find('tr:not(.head)').addClass('existing');
		if (regexp.test(text)) {
			var m;
			var index = 2;
			do {
				m = regexp.exec(text);
				if (m !== null && m.groups) {
					_addNewEntry2(m.groups, index, tbody);
				}
				index++;
			} while (m !== null && m.groups);
		}
		tbody.find('tr.existing').remove();
		_updateEntries2();
	} catch (ex) {
		console.error(ex);
	}
}


export const executeRegexp = () => {
	try {
		$('table tbody tr:not(.head)').remove();
		var regexp = $('textarea#regexp').val();
		var text = $('textarea#rawtextdata').val();
		regexp = new RegExp(regexp, 'ig');
		if (regexp.test(text)) {
			var m;
			do {
				m = regexp.exec(text);
				if (m !== null && m.groups) {
					_addNewEntry(m.groups);
				}
			} while (m !== null && m.groups);
		}
		_updateEntries();
		$('table tr input.count').trigger('change');
	} catch (ex) {
		console.error(ex);
	}
}

var deletedMenuItems = {}
function getMenuItem(id, handler) {
	var menuItem = _getMenuItem(id, incomingStocks.categories.activeMenuCategories ? incomingStocks.categories.activeMenuCategories : incomingStocks.categories.children);
	if (menuItem === null || menuItem === '') {
		if (deletedMenuItems[id]) {
			handler(deletedMenuItems[id]);
			return;
		}
		getMenuItemById(id, menuItem => {
			menuItem.type = 'deleted';
			menuItem.name = admin_local.deleted + ' --- ' + getLocale(menuItem.name) + " --- ";
			deletedMenuItems[id] = menuItem;
			handler(menuItem);
		});
		return;
	}
	handler(menuItem);
}

function _getMenuItem(id, incomingStockscategories) {
	var val = null;
	incomingStockscategories.forEach(function (item) {
		if (val !== null)
			return;
		if (item.entityType.indexOf('Category') === -1 && item.id === Number(id)) {
			val = Object.assign({}, item);
			delete val.image;
		} else {
			if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
				val = _getMenuItem(id, item.activeMenuCategories);
			if (val === null && item.activeMenuItems && item.activeMenuItems.length > 0)
				val = _getMenuItem(id, item.activeMenuItems);
			if (val === null && item.children && item.children.length > 0)
				val = _getMenuItem(id, item.children);
		}
	});
	return val;
}

/*
function floatMainTableHead() {

	if (window.mobileAndTabletcheck())
		return;

	floatTableHead("#entriesFreezeTable");

}
*/
/*
function floatTableHead() {
	if (window.mobileAndTabletcheck())
		return;
	//_floatTableHead("#entriesFreezeTable");
	_floatTableHead("#listFreezeTable");

}

function unfloatTableHead() {
	if (window.mobileAndTabletcheck())
		return;
	//_unfloatTableHead("#entriesFreezeTable");
	_unfloatTableHead("#listFreezeTable");
}*/

function getDefaultProductForSupplierCode(supplier, code, handler, errorHandler) {
	if (supplier)
		post("adminService/" + localStorage.restaurantSelected + "/getDefaultProductForSupplierCode/" + supplier, code).done(function (data) {
			resultHandler(data, handler, errorHandler)
		});
}

function getSupplierCodes(supplier, handler, errorHandler) {
	if (isNaN(supplier))
		return;
	get("adminService/" + localStorage.restaurantSelected + "/getSupplierCodes/" + supplier).done(function (data) {
		resultHandler(data, handler, errorHandler)
	});
}
function updateSupplierCodes(supplier) {
	var codes = $('datalist#codes');
	var empty = true;
	$('#entriesFreezeTable > table#entriesTable tbody tr td input.count').each((id, e) => {
		const value = $(e).data('cleave').getRawValue()
		if (value != 0)
			empty = false;
	});
	if (empty)
		$('#entriesFreezeTable table tbody tr').remove();
	else
		return;
	codes.empty();
	if (supplier !== "" && supplier !== null)
		getSupplierCodes(supplier, function (data) {
			var empty = true;
			$('#entriesFreezeTable > table#entriesTable tbody tr td input.count').each((id, e) => {
				if ($(e).val() > 0)
					empty = false;
			});
			if (!empty)
				return;
			var firstEntry = null;
			var vat_category = undefined;
			var store = undefined;
			data.filter(a => a.menuItem).sort((a, b) => a.menuItem && a.menuItem.name.localeCompare(b.menuItem.name)).forEach(function (entry) {
				if (entry.menuItem.type === "deleted")
					return;
				if (!entry.menuItem.defaultInventoryItem)
					return;

				if (!entry.menuItem.availableQuantities.find(q => q.inventoryItem && q.quantity == entry.quantity))
					return;

				var option = $('<option>' + entry.code + '</option>');
				codes.append(option);
				if (empty) {
					entry.count = undefined;
					entry.expiryDate = null;
					entry.net_price = undefined;
					entry.brutto_price = undefined;
					entry.id = undefined;
					var e = addNewEntry(entry);
					var v = entry.vatCategory.id + ";" + entry.vatCategory.percent;
					if (vat_category === undefined || vat_category === v) {
						vat_category = v;
					} else {
						vat_category = null;
					}
					if (store === undefined || Number(store) === entry.store.id) {
						store = entry.store.id;
					} else {
						store = null;
					}
					if (firstEntry === null)
						firstEntry = e;
				}
			})
			_updateEntries();
			$("#entriesFrezzeTable tr.head select#store").val(store);
			$("#entriesFrezzeTable tr.head select#vat_category").val(vat_category);
			if (firstEntry == null) {
			} else {
				var c = firstEntry.find('input.count');
				c.focus();
			}

		})

}

export const queryInvoiceData = (e) => {
	var invoice = $(e.currentTarget).prev().val();
	if (invoice)
		post("adminService/" + localStorage.restaurantSelected + "/queryInvoiceData", invoice).done(data => {
			//console.log(data);
			const supplierId = incomingStocks.suppliers.find(s => s.name.toLowerCase() === data.invoiceHead.supplierInfo.supplierName.toLowerCase())?.id
			if (supplierId) {
				$("select#supplier").select2().val(supplierId);
				$("select#supplier").select2().trigger('change');
			}
			var entries = data.invoiceLines.lines.filter(d => d.unitPriceHUF + d.unitPrice > 0).map(line => {
				const vatCategory = auth.myStatus.restaurant_vat_categories.find(v => v.percent == (line.lineAmountsNormal || line.lineAmountsSimplified)?.lineVatRate?.vatPercentage * 100);
				const multiplicator = 1;
				const original_quantity = line.quantity;
				const count = line.quantity;

				var net_price;
				var brutto_price;

				if (line.lineAmountsSimplified) {
					brutto_price = line.lineAmountsSimplified.lineGrossAmountSimplifiedHUF || line.lineAmountsSimplified.lineGrossAmountSimplified;
					net_price = brutto_price * 100 / (100 + (vatCategory?.percent || 0));
				} else {
					net_price = line.lineAmountsNormal.lineNetAmountData.lineNetAmountHUF || line.lineAmountsNormal.lineNetAmountData.lineNetAmount;
					brutto_price = net_price * (100 + (vatCategory?.percent || 0)) / 100;
				}


				const net_unit_price = count != 0 ? net_price / count : 0;
				const brutto_unit_price = count != 0 ? brutto_price / count : 0;

				const supplier_code = line.lineDescription;
				const ignored = false;
				const d = { supplier_code, multiplicator, original_quantity, count, brutto_unit_price, net_unit_price, net_price, brutto_price, vatCategory, ignored };
				var entry = addNewEntry(d, supplierId);
				return entry;
			});
			_updateEntries();
			entries.forEach(entry => {
				entry.find(".supplier_code").trigger("change")
			});

		}).fail(data => {
			console.log(data);
			if (data.status == 404) {
				messageDialog(I18n.t("local.error_message"), I18n.t("admin_local.invoice_not_found"));
			}
			auth.ajaxError = false;
		})
}

export const showOnlineSzamlaFeature = () => {
	messageDialog(I18n.t("local.information"), I18n.t("admin_local.online_szamla_feature"));
}

export const editIncomingStock = (event) => {
	try {
		//eslint-disable-next-line no-restricted-globals
		navigateTo(location.pathname + "#stockManagementMenu/incomingStocks/" + incomingStocks.incomingStocks.getActiveNode().data.id);
	} catch (ex) {
		console.error(ex);
	}
}

export const newIncomingStock = (event) => {
	//eslint-disable-next-line no-restricted-globals
	navigateTo(location.pathname + "#stockManagementMenu/incomingStocks/new");
}

export const incomingStockAction = id => {
	if (!id) {
		//close editor
		$("#editIncomingStock").modal("hide");
	} else if (id === "new") {
		if (incomingStocks?.data?.id) {
			incomingStocks.data = { id: "new" };
			$("#editIncomingStock").modal("hide");
			setTimeout(() => $("#editIncomingStock").modal("show"), 500);
		} else {
			incomingStocks.data = { id: "new" };
			$("#editIncomingStock").modal("show");
		}
	} else {
		if (incomingStocks?.data?.id == id) {
			if (incomingStocks?.data?.id && incomingStocks?.data?.id != id)
				$("#editIncomingStock").modal("hide");
			if (incomingStocks?.data?.id && incomingStocks?.data?.id == id)
				return;
			$("#editIncomingStock").modal("show");
		} else {
			get("adminService/" + localStorage.restaurantSelected + "/incomingStock/" + id).done(data => {
				if (incomingStocks?.data?.id && incomingStocks?.data?.id == id)
					return;
				if (data.entries.find(e => e.code)) {
					setInputPrice("net_price");
				}
				if (!incomingStocks?.data?.id || incomingStocks?.data?.id != id) {
					incomingStocks.data = data;
					$("#editIncomingStock").modal("hide");
					setTimeout(() => $("#editIncomingStock").modal("show"), 500);
				} else {
					incomingStocks.data = data;
					$("#editIncomingStock").modal("show");
				}
			}).fail(() => {
				auth.ajaxError = false;
				incomingStocks.data = undefined;
				$("#editIncomingStock").modal("hide");
			});
		}
	}
}

export const sendEmail = () => {
	var data = incomingStocks.incomingStocks.getActiveNode().data;
	get("adminService/" + localStorage.restaurantSelected + "/incomingStock/sendEmail/" + data.id);
}
