import $ from "jquery";
import moment from "moment";
import admin, { resultHandler, printPdf, getLabel, getLocalPrinterSettings, getPrinter } from "../admin";
import { I18n } from "react-redux-i18n";
import auth, { updateHooks, startpoll, post, getReportUrl } from "../auth";
import Cleave from "cleave.js";
import SortedSet from "js-sorted-set";
import X2JS from "x2js";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

const NumeralFormatter = new Cleave.NumeralFormatter(0, 0, 0, 'thousand', false, true, " ");
const NumeralFormatter2 = new Cleave.NumeralFormatter(0, 0, 2, 'thousand', false, true, " ");


class DailyClosingCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['print']);
	}

	paymentMethodsData;
	currenciesData;
	cashRegistersData;

	stopIntervals = () => {
	}

	labelsSelected = [];
	labels;

	ready = (props, state) => {

		if (typeof localStorage.tableOccupations_toDate == 'undefined') {
			localStorage.tableOccupations_toDate = moment().toDate().getTime();
			localStorage.tableOccupations_fromDate = moment().subtract(1, 'month').toDate().getTime();
		}

		const def = $.Deferred();

		moment.locale(localStorage.language);

		const instance = this;
		admin.getCashRegisters(function (cr) {
			instance.cashRegistersData = cr;
			admin.getAllPaymentMethods(localStorage.language, function (pm) {
				instance.paymentMethodsData = pm;
				admin.getAllCurrencies(localStorage.language, function (c) {
					instance.currenciesData = c;
					$('#main').replaceWith($('#dailyClosingTemplate').tmpl({
						paymentMethods: instance.paymentMethodsData,
						currencies: instance.currenciesData,
						...tmplparams()
					}));

					if (typeof localStorage.tableOccupations_fromDate == 'undefined') {
						localStorage.tableOccupations_toDate = moment().toDate().getTime();
						localStorage.tableOccupations_fromDate = moment().subtract(1, 'month').toDate().getTime();
					}

					var ranges = {};
					ranges[I18n.t("admin_local.today")] = [moment(), moment()];
					ranges[I18n.t("admin_local.yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
					ranges[I18n.t("admin_local.last_7_days")] = [moment().subtract(6, 'days'), moment()];
					ranges[I18n.t("admin_local.last_30_days")] = [moment().subtract(29, 'days'), moment()];
					ranges[I18n.t("admin_local.last_3_months")] = [moment().subtract(2, 'month').startOf('month'), moment()];
					ranges[I18n.t("admin_local.this_month")] = [moment().startOf('month'), moment().endOf('month')];
					ranges[I18n.t("admin_local.last_month")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

					if (typeof localStorage.admin_dailyClosing_range != 'undefined') {
						$("[name='range'][id='" + localStorage.admin_dailyClosing_range + "']").parent().button('toggle');
					}

					$("[name='range']").change((event) => {
						localStorage.admin_dailyClosing_range = $("[name='range']:checked").attr('id');
						//drawChart();
					});

					/*
					new window.FreezeTable($('#freezeTable'), {
						namespace: "dailyClosing",
						scrollable: true,
						scrollBar: true,
						freezeColumn: false,
						freezeColumnHead: false
					})-*

					/*
					t = $('div#tableOccupations table:not(".floatThead-table")');
					t.find('thead').hide();
					var d = $('<div class="scrollable-y" id="tableOccupationsScrollable" style="margin-top: 0px;height: 100%;overflow:auto"/>');
					d.appendTo($(t.parent()));
					t.appendTo(d);
					$('div.floatThead-container').css("overflow", "hidden");
					*/

					//auth.setupScroll($('#tableOccupations'));

					var options = {
						placeholder: "",
						tokensAllowCustom: true,
						searchMinLength: 0,
						dataSource: function (term, object) {
							object.trigger('tokenize:dropdown:fill', [instance.getLabels(term)]);
						},
						displayNoResultsMessage: true,
						noResultsMessageText: I18n.t("local.noResultsMessageText"),
						dropdownMaxItems: 7,
						searchFromStart: false,
					};
					$('#menuitem_labels').tokenize2(options);

					instance.labelsSelected = []
					if (typeof localStorage.labelsSelected != "undefined")
						instance.labelsSelected = JSON.parse(localStorage.labelsSelected);
					admin.getLabels(function (data) {
						instance.labels = data;
						instance.labels.forEach(function (label) {
							if (instance.labelsSelected.indexOf("" + label.id) >= 0) {
								$('#menuitem_labels').tokenize2().trigger('tokenize:tokens:add', ["label;" + label.id + ";", label.name, true]);
							}
						})

						$('#menuitem_labels').on('tokenize:tokens:add', function () {
							instance.labelsSelected = []
							$('#menuitem_labels').tokenize2().toArray().forEach(function (label, ind) {
								instance.labelsSelected.push(label.split(';')[1]);
							});
							localStorage.labelsSelected = JSON.stringify(instance.labelsSelected);
						});
						$('#menuitem_labels').on('tokenize:tokens:remove', function () {
							instance.labelsSelected = []
							$('#menuitem_labels').tokenize2().toArray().forEach(function (label, ind) {
								instance.labelsSelected.push(label.split(';')[1]);
							});
							localStorage.labelsSelected = JSON.stringify(instance.labelsSelected);
						});
					});


					updateHooks();

					startpoll(instance.serverSideMessageHandler);

					instance.reload(state);

					def.resolve();
					// updateHooks();
				});
			});
		}, localStorage.restaurantSelected);
		return def.promise();
	}

	reload = (props) => {
		this.getAllTableOccupations(props, this.processTableOccupations);
	}

	fullreload = (props, state) => {
		this.oldSearch = null;
		this.ready(props, state);
	}

	oldSearch = null;
	getAllTableOccupations = (props, handler, errorHandler) => {
		const start = props.startDate.clone().startOf("day");
		const end = props.endDate.clone().startOf("day");
		start.add(props.timeValue.start.hours, "hour");
		start.add(props.timeValue.start.minutes, "minutes");
		end.add(props.timeValue.end.hours, "hour");
		end.add(props.timeValue.end.minutes, "minutes");
		var search = {
			fromDate: start.valueOf(),
			toDate: end.valueOf(),
			labels: this.labelsSelected
		}
		if (this.oldSearch === JSON.stringify(search))
			return;
		this.oldSearch = JSON.stringify(search);
		post("adminService/" + localStorage.restaurantSelected + "/filterDayClosing?lang=" + localStorage.language, search, undefined, undefined, undefined, undefined, 60000).done(function (data) {
			resultHandler(data, handler, errorHandler)
		});
	}

	serverSideMessageHandler = (data) => {

	}


	r = null;
	t = null;
	processTableOccupations = (tableOccupations) => {

		const instance = this;

		if (typeof tableOccupations == "undefined") {
			tableOccupations = this.tt;
		}

		tableOccupations.forEach(t => t._type = t.type ? t.type : "onsite");

		var accumulated = tableOccupations.reduce(function (accumulator, element) {
			var currentValue = accumulator[element.date];


			if (currentValue !== undefined) {
				Object.keys(currentValue).forEach(key => {
					const type = typeof currentValue[key];
					if (key === "date")
						return;
					switch (type) {
						case "number":
							currentValue[key] += element[key];
							break;
						case "object":
							switch (key) {
								case "menuItems":
									element[key].forEach(v => currentValue[key].push(v));
									break;
								case "deliveryCounts": {
									if (currentValue[key]) {
										currentValue[key] = [...currentValue[key], ...element[key]];
									} else {
										currentValue[key] = element[key];
									}
								}
									break;
								case "payments":
								case "deliveries": {
									const currentValuePayments = {};
									currentValue[key].forEach(a => currentValuePayments[a.currency + "-" + a.exchangeRate + "-" + a.paymentMethod + "-" + a.cashRegister + "-" + a.receivedById] = a);
									const elementPayments = {};
									element[key].forEach(a => elementPayments[a.currency + "-" + a.exchangeRate + "-" + a.paymentMethod + "-" + a.cashRegister + "-" + a.receivedById] = a);
									Object.keys(elementPayments).forEach(key2 => {
										if (currentValuePayments[key2]) {
											currentValuePayments[key2]["amount"] += elementPayments[key2]["amount"];
											currentValuePayments[key2]["paymentCount"] += elementPayments[key2]["paymentCount"];
										} else {
											currentValuePayments[key2] = elementPayments[key2];
										}
									})
									currentValue[key] = Object.values(currentValuePayments);
								}
									break;
								case "mealsIncome":
								case "drinksIncome":
									Object.keys(element[key]).forEach(key2 => {
										currentValue[key][key2] = (currentValue[key][key2] ? currentValue[key][key2] : 0) + element[key][key2]
									})

									break;
								default:
							}
							break;
						default:
					}
				})
			}
			else {
				accumulator[element.date] = Object.assign({}, element);
			}
			return accumulator;
		}, {}
		);

		this.t = Object.values(accumulated).sort((a, b) => a.date - b.date);
		this.tt = tableOccupations;

		this.t.forEach(tt => {
			tt.title = moment(tt.date).format('YYYY-MM-DD');
		})

		if (this.r == null) {
			this.r = createTree("table#tableOccupations", {
				checkbox: false,
				source: this.t,
				zindex: 1000,
				extensions: ["persist", "table", "gridnav", "filter"],
				icon: false,
				filter: {
					counter: false,
					mode: "hide"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 0, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets
					// activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					instance.processPayments(data.node.data.payments);
					instance.processDeliveryCount(data.node.data.deliveryCounts);
					instance.processDeliveries(data.node.data.deliveries);
					var ids = [];
					data.node.data.menuItems.forEach(function (m) {
						ids.push(m.menuItem);
					})
					admin.getMenuItemsByIds(ids, function () {
						instance.processLabels(data.node.data.menuItems);
					});
					const vats = [];
					if (data.node.data.drinksIncome)
						Object.keys(data.node.data.drinksIncome).forEach(key => {
							vats.push({ _type: "drink", vat: key, amount: data.node.data.drinksIncome[key] })
						})
					if (data.node.data.mealsIncome)
						Object.keys(data.node.data.mealsIncome).forEach(key => {
							vats.push({ _type: "meal", vat: key, amount: data.node.data.mealsIncome[key] })
						})
					instance.processVats(vats);
					instance.processTypes(instance.tt.filter(d => d.date === data.node.data.date));
				},

				// renderStatusColumns: false, // false: default renderer
				// true: generate renderColumns events, even for status nodes
				// function: specific callback for status nodes

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = false;

					// (Column #0 is rendered by fancytree by adding the checkbox)

					// Column #1 should contain the index as plain text, e.g.
					// '2.7.1'
					// $tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

					// (Column #2 is rendered by fancytree)
					// ...otherwise render remaining columns
					var c = 1;
					//$tdList.eq(c++).text($.datepicker.formatDate("yy-mm-dd", new Date(node.data.date)));
					//$tdList.eq(c++).text(moment(node.data.date).format('YYYY-MM-DD'));
					$tdList.eq(c++).text(node.data.occupationCount);
					$tdList.eq(c++).text(node.data.orderCount);
					$tdList.eq(c++).text(node.data.deletedOrderCount);
					$tdList.eq(c++).text(node.data.persons);
					var price = node.data.price + node.data.pending;
					$tdList.eq(c++).text(NumeralFormatter.format("" + price));
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.discount + node.data.tm_discount)));
					$tdList.eq(c++).text(NumeralFormatter.format("" + (price - node.data.discount - node.data.tm_discount)));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.paid));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.serviceFee));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.serviceFeeNet));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.tip));
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.pending)));
				}
			});
		} else {
			this.r.reload(this.t);
		}
	}

	payments = null;
	paymentsData;
	processPayments = (data) => {

		data.sort((a, b) => {
			const r = a.receivedByName.localeCompare(b.receivedByName);
			if (r !== 0) return r;
			const p = a.cashRegister - b.cashRegister;
			if (p !== 0) return p;
			const pm = a.paymentMethod - b.paymentMethod;
			return pm;
		})

		const instance = this;

		if (typeof data != "undefined") {
			this.paymentsData = data;
		}
		if (this.payments == null) {
			this.payments = createTree("table#payments", {
				checkbox: false,
				source: this.paymentsData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				selectMode: 1,
				filter: {
					counter: false,
					mode: "dimm"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
				},

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = false;
					var c = 0;
					$tdList.eq(c++).text(node.data.receivedByName);
					$tdList.eq(c++).text(instance.getCashRegisterName(node.data.cashRegister));
					$tdList.eq(c++).text(instance.getPaymentMethodName(node.data.paymentMethod));
					$tdList.eq(c++).text(instance.getCurrencyName(node.data.currency));
					$tdList.eq(c++).text(node.data.paymentCount);
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.amount));
					if (node.data.exchangeRate !== 1)
						$tdList.eq(c++).text(NumeralFormatter2.format("" + node.data.exchangeRate));
				},

			});

		} else
			this.payments.reload(this.paymentsData);
	}

	processDeliveryCount = (data = []) => {

		var deliveryCountData = Object.values(data.reduce((a, b) => {
			if (!a[b.receivedById]) {
				b.deliveryCount = [b.deliveryId]
				a[b.receivedById] = { ...b };
			} else {
				a[b.receivedById].amount += b.amount;
				a[b.receivedById].avgDeliveryTime = (a[b.receivedById].avgDeliveryTime * a[b.receivedById].count + b.avgDeliveryTime * b.count) / (a[b.receivedById].count + b.count);
				a[b.receivedById].distance += b.distance;
				a[b.receivedById].count += b.count;
				//if (a[b.receivedById].deliveryCount.indexOf(b.deliveryId) === -1)
				a[b.receivedById].deliveryCount.push(b.deliveryId);
			}
			return a;
		}, {}));

		deliveryCountData.sort((a, b) => a.receivedByName.localeCompare(b.receivedByName))

		const instance = this;

		if (this.deliveryCount == null) {
			this.deliveryCount = createTree("table#delivery_count", {
				checkbox: false,
				source: deliveryCountData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				selectMode: 1,
				filter: {
					counter: false,
					mode: "dimm"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
				},

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = false;
					var c = 0;
					$tdList.eq(c++).text(node.data.receivedByName);
					$tdList.eq(c++).text(node.data.deliveryCount.length);
					$tdList.eq(c++).text(node.data.count);
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.amount));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.distance));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.avgDeliveryTime));
				},

			});

		} else
			this.deliveryCount.reload(deliveryCountData);
	}

	processDeliveries = (data) => {

		data.sort((a, b) => {
			const r = a.receivedByName.localeCompare(b.receivedByName);
			if (r !== 0) return r;
			const p = a.cashRegister - b.cashRegister;
			if (p !== 0) return p;
			const pm = a.paymentMethod - b.paymentMethod;
			return pm;
		})

		const instance = this;

		if (typeof data != "undefined") {
			this.deliveriesData = data;
		}
		if (this.deliveries == null) {
			this.deliveries = createTree("table#deliveries", {
				checkbox: false,
				source: this.deliveriesData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				selectMode: 1,
				filter: {
					counter: false,
					mode: "dimm"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
				},

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = false;
					var c = 0;
					$tdList.eq(c++).text(node.data.receivedByName);
					$tdList.eq(c++).text(instance.getCashRegisterName(node.data.cashRegister));
					$tdList.eq(c++).text(instance.getPaymentMethodName(node.data.paymentMethod));
					$tdList.eq(c++).text(instance.getCurrencyName(node.data.currency));
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.amount));
					if (node.data.exchangeRate !== 1)
						$tdList.eq(c++).text(NumeralFormatter2.format("" + node.data.exchangeRate));
				},

			});

		} else
			this.deliveries.reload(this.deliveriesData);
	}

	vats = null;
	vatsData;
	processVats = (data) => {

		if (typeof data != "undefined") {
			this.vatsData = data;
		}
		if (this.vats == null) {
			this.vats = createTree("table#vats", {
				checkbox: false,
				source: this.vatsData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				selectMode: 1,
				filter: {
					counter: false,
					mode: "dimm"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
				},

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = false;
					var c = 0;
					$tdList.eq(c++).text(I18n.t("admin_local." + node.data._type));
					$tdList.eq(c++).text(node.data.vat);
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.amount));
				},

			});

		} else
			this.vats.reload(this.vatsData);
	}

	types = null;
	typesData;
	processTypes = (data) => {

		if (typeof data !== "undefined") {
			this.typesData = data;
		}
		if (this.types == null) {
			this.types = createTree("table#types", {
				checkbox: false,
				source: this.typesData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				selectMode: 1,
				filter: {
					counter: false,
					mode: "dimm"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
				},

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					node.expanded = false;
					var c = 0;
					$tdList.eq(c++).text(I18n.t("admin_local." + node.data._type));
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.occupationCount)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.persons)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.orderCount)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.price + node.data.pending)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.discount + node.data.tm_discount)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + (node.data.paid)))
					$tdList.eq(c++).text(NumeralFormatter.format("" + Math.round(node.data.paid / node.data.persons)))
				},

			});

		} else
			this.types.reload(this.typesData);
	}

	menuitems = null;
	menuitemsData;
	processLabels = (data) => {

		var groupBy = function (xs, key) {
			return xs.reduce(function (rv, x) {
				(rv[key(x)] = rv[key(x)] || []).push(x);
				return rv;
			}, {});
		};

		data = groupBy(data, x => x.menuItem + (x.amount > 0 ? "-1" : "-0"));
		data = Object.entries(data).map(aa => aa[1].reduce((a, b) => { return { menuItem: a.menuItem, count: a.count + b.count, amount: a.amount + b.amount } }, { menuItem: Number(aa[0].split("-")[0]), count: 0, amount: 0 }));

		if (typeof data !== "undefined") {
			this.menuitemsData = data;
		} else {
			if (typeof this.menuitemsData === "undefined")
				return;
		}
		if (this.menuitems == null) {
			this.menuitems = createTree("table#menuitems", {
				checkbox: false,
				source: this.menuitemsData,
				zindex: 1000,
				extensions: ["table", "gridnav", "filter"],
				filter: {
					counter: true,
					mode: "hide"
				},
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				icon: false,
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					var c = 0;
					$tdList.eq(c++).text(getLabel(node.data.menuItem) ? getLabel(node.data.menuItem).name : "");
					$tdList.eq(c++).text(node.data.count);
					$tdList.eq(c++).text(NumeralFormatter.format("" + node.data.amount));
				},

			});

		} else
			this.menuitems.reload(this.menuitemsData);
	}


	getLabels = (search) => {
		//s = search;
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getLabels(search, set, this.labels);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}

	_getLabels = (s, set, labels) => {
		labels.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			var name = item.name;
			var r = regexp.test(name);
			if (r) {
				set.insert({
					value: "label;" + item.id + ";",
					text: name
				})
			}
		});
	}

	print = () => {
		var node = this.r.getActiveNode();
		if (!node)
			return;
		var $tdList = $(node.tr).find(">td");
		var data = {
			date: $tdList.eq(0).text(),
			tables: $tdList.eq(1).text(),
			orders: $tdList.eq(2).text(),
			cancelledOrders: $tdList.eq(3).text(),
			personCount: $tdList.eq(4).text(),
			consumption: $tdList.eq(5).text(),
			discount: $tdList.eq(6).text(),
			payable: $tdList.eq(7).text(),
			paid: $tdList.eq(8).text(),
			serviceFee: $tdList.eq(9).text(),
			serviceFeeNet: $tdList.eq(10).text(),
			tip: $tdList.eq(11).text(),
			pending: $tdList.eq(12).text(),
			payments: [],
			deliveries: [],
			vats: [],
			menuItems: [],
			types: []
		}
		var nodes = this.payments.getRootNode().children;
		nodes.forEach(function (node) {
			var $tdList = $(node.tr).find(">td");
			data.payments.push({
				cashier: $tdList.eq(0).text(),
				cashRegister: $tdList.eq(1).text(),
				method: $tdList.eq(2).text(),
				currency: $tdList.eq(3).text(),
				paymentCount: $tdList.eq(4).text(),
				amount: $tdList.eq(5).text(),
				exchangeRate: $tdList.eq(6).text(),
			});
		});
		var nodes = this.deliveries.getRootNode().children;
		nodes.forEach(function (node) {
			var $tdList = $(node.tr).find(">td");
			data.deliveries.push({
				cashier: $tdList.eq(0).text(),
				cashRegister: $tdList.eq(1).text(),
				method: $tdList.eq(2).text(),
				currency: $tdList.eq(3).text(),
				amount: $tdList.eq(4).text(),
				exchangeRate: $tdList.eq(5).text(),
			});
		});
		nodes = this.vats.getRootNode().children;
		nodes.forEach(function (node) {
			var $tdList = $(node.tr).find(">td");
			data.vats.push({
				type: $tdList.eq(0).text(),
				vat: $tdList.eq(1).text(),
				amount: $tdList.eq(2).text(),
			});
		});
		nodes = this.menuitems.getRootNode().children;
		nodes.forEach(function (node) {
			if ($(node.tr).hasClass('fancytree-hide'))
				return;
			var $tdList = $(node.tr).find(">td");
			data.menuItems.push({
				name: $tdList.eq(0).text(),
				count: $tdList.eq(1).text(),
				amount: $tdList.eq(2).text(),
			});
		});
		nodes = this.types.getRootNode().children;
		nodes.forEach(function (node) {
			if ($(node.tr).hasClass('fancytree-hide'))
				return;
			var $tdList = $(node.tr).find(">td");
			data.types.push({
				name: $tdList.eq(0).text(),
				count: $tdList.eq(1).text(),
				entry: $tdList.eq(3).text(),
				payable: $tdList.eq(4).text(),
				discount: $tdList.eq(5).text(),
				amount: $tdList.eq(6).text(),
			});
		});
		var x2js = new X2JS();
		var xmlAsStr = '<?xml version="1.0" encoding="utf-8"?><root>' + x2js.js2xml(data) + "</root>";
		var file = "dailyClosing_" + localStorage.id + (new Date().getTime());
		const printer = getLocalPrinterSettings().printerActions.pa_printInvoice ? getPrinter(getLocalPrinterSettings().printerActions.pa_printInvoice).name : auth.myStatus.restaurant_printer_actions.printInvoice.name;
		printPdf("dailyClosing", "html", localStorage.language, "../birt/preview?__report=" + getReportUrl('dailyClosing') + "&__format=html&__locale=" + localStorage.language, file, xmlAsStr, printer);
	}

	getPaymentMethodName = (id) => {
		var name;
		this.paymentMethodsData.forEach(function (pm) {
			if (pm.id === id)
				name = pm.name;
		})
		return name;
	}

	getCashRegisterName = (id) => {
		if (id)
			return this.cashRegistersData.find(pm => pm.id === id).name;
		return "";
	}

	getCurrencyName = (id) => {
		var name;
		this.currenciesData.forEach(function (c) {
			if (c.id === id)
				name = c.name;
		})
		return name;
	}

}

export default DailyClosingCode;