import React, { useState } from "react";

const JsonTabEditor = ({ readonly, TitleField, properties, title, description }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="tabbed-object-field-template">
            {/* Optional Title */}
            {title && <TitleField title={title} />}
            {/* Optional Description */}
            {description && <p>{description}</p>}

            {/* Tabs */}
            <div className="tabs">
                {properties.filter(p => !p.readonly || !readonly).map((property, index) => (
                    <button
                        key={property.name}
                        className={`btn btn-${index === activeTab ? "primary" : "info"}`}
                        onClick={() => setActiveTab(index)}
                    >
                        {property.name}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {properties.map((property, index) => (
                    <div key={property.name} style={{ display: index === activeTab ? "block" : "none" }}>
                        {property.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JsonTabEditor;
