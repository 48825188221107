import $ from "jquery";
import orderListUtil, {
  processAllOrders,
  updateOrders,
  updateTableMates,
  updatePrintersStatus,
  _show,
  processOrders,
  update,
  deleteOrders,
  setSelected,
  selectPreviousOrder,
  selectNextOrder,
  prevState,
  toggleDetails,
  nextState,
  selectNextUnprocessedOrder,
  tmplparams,
  resetOrderListUtil,
  addRejectionReason,
  deleteTableOccupations
} from "./order-list-util";
import auth, { startpoll, isDuplicateClickEvent } from "./auth";
import admin, { getTableOccupations } from "./admin";
import tableLayout from "./table-layout";

const menuItemType = "Drink";
const menuItemtype = "drink";

const isFiltered = () => {
  return true;
};

const recoverFromEventSourceError = () => {
  reload();
};

var updateCycle;
export const stopIntervals = () => {
  clearInterval(updateCycle);
};

export const ready = () => {
  resetOrderListUtil();

  updateCycle = setInterval(function () {
    if (typeof orderListUtil.ordersCache != "undefined") {
      processAllOrders(orderListUtil.ordersCache);
      updateOrders([]);
    }
  }, 10000);

  orderListUtil.processOrdersDone = function () {
    floatTableHead();
    topUpdateHooks();
  };

  orderListUtil.show = function (order) {
    return true;
    /*
    console.log(order, order.notDelivered, order.asapNotDelivered);
    if (order.notDelivered || order.asapNotDelivered) return true;
    else {
      console.log(new Date().getTime() - order.inStateTimeStamp["delivered"] < auth.myStatus.restaurant_settings["drinkbar"].showDeliveredTimeout * 1000)
      return new Date().getTime() - order.inStateTimeStamp["delivered"] < auth.myStatus.restaurant_settings["drinkbar"].showDeliveredTimeout * 1000;
    }
    */
  };

  orderListUtil.filter = function (order) {
    //console.log(order.state, order)
    return order.menuItem.type === menuItemtype && ((order.state !== 'delivered' && order.state !== 'cancelled') || new Date().getTime() - order.inStateTimeStamp < auth.myStatus.restaurant_settings["drinkbar"].showDeliveredTimeout * 1000)
    //return order.menuItem.type === menuItemtype;
    //return (!orderInState(order, "delivered") || order.state!='accepted') && (order.menuItem.type==menuItemtype && (order.state != 'delivered' || (new Date().getTime() - order.inStateTimeStamp) < myStatus.restaurant_settings['drinkbar'].showDeliveredTimeout * 1000))
  };

  orderListUtil.updateOrdersDone = function () {
    topUpdateHooks();
  };

  window.isDuplicateClickEvent = isDuplicateClickEvent;
  window.addRejectionReason = addRejectionReason;

  orderListUtil.isFiltered = isFiltered;
  orderListUtil.showTableNumber = true;
  auth.recoverFromEventSourceError = recoverFromEventSourceError;
  orderListUtil.keyDownHandler = keyDownHandler;
  auth.resize = resize;

  orderListUtil.states = auth.myStatus.restaurant_settings["drinkbar"].states;
  orderListUtil.statesShownOriginal = orderListUtil.statesShown = auth.myStatus.restaurant_settings["drinkbar"].statesShown;
  orderListUtil.hidePaid = auth.myStatus.restaurant_settings["drinkbar"]["hide-paid-state"];

  startpoll(serverSideMessageHandler, [
    {
      Restaurant: localStorage.restaurantSelected,
      Order: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      TableOccupation: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Menu: "*"
    }
  ]);
  $("#restaurantStyle").text(auth.myStatus.restaurant_css);
  $("#barMainTemplate")
    .tmpl({ ...tmplparams(), hidePaid: true })
    .appendTo($("#main"));
  admin.getTables(function (tableLayouts) {
    tableLayout.tables = tableLayouts.restaurantTables;
    tableLayouts.restaurantTables.forEach(function (table) {
      tableLayout.tableMap.set(Number(table.number), table);
    });

    getTableOccupations(function (data) {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        admin.getUnprocessedOrders([menuItemType + "Order"], orders => { orderListUtil.ordersCache = orders; orderListUtil.processOrders(orders) }, {
          show: function (order) {
            return order.type === "drink";
          },
          done: function () {
            floatTableHead();
          }
        });
      }, localStorage.language);

      $("div#rejectionReason").on("shown.bs.modal", function (event) {
        var modal = $(this);
        var r = $(modal).find("#reason1");
        $(r).trigger("focus");
      });
    });
  });

  admin.getPrintersStatus(updatePrintersStatus);
};

function serverSideMessageHandler(message) {
  if (message.type === "timeout" && message.message === "true") return;
  console.log(message.message);
  if (message.type === "refresh") {
    if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
      if (message.data) update(message.data);
      else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
    } else if (message.message === "table unlocked") {
      //if (message.data) update(message.data);
      deleteTableOccupations(message.TableOccupation);
    } else if (message.message === "order cancelled") {
      if (message.data) {
        orderListUtil.deleteOrders(message.Order, true);
        update(message.data);
      } else {
        deleteOrders(message.Order);
      }
    } else if (message.message === "menu changed") {
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        updateOrders([]);
      }, localStorage.language);
    } else if (message.message === "printer status changed") {
      admin.getPrintersStatus(updatePrintersStatus);
    }
  }
}

function reload() {
  if (typeof orderListUtil.selected != "undefined") {
    var id = orderListUtil.selected.attr("id");
    var orderId = orderListUtil.selected.attr("orderId");
    var details = $("tr#" + id + ".detailrow.nothidden").length > 0;
  }
  admin.getUnprocessedOrders(function (data) {
    processOrders(data);
    if (typeof orderListUtil.selected !== "undefined") {
      setSelected(id);
      if (details) {
        _show(orderListUtil.selected, true);
        $("tr#" + id + ".detailrow.nothidden").each(function (ind, row) {
          if ($(row).attr("orderId") === orderId) {
            //selectedId = "";
            _show($(row));
          }
        });
      }
    }
  });
}

function topUpdateHooks() {
  orderListUtil.updateHooks();
}

const keyDownHandler = e => {
  switch (e.which) {
    case 37: // left
      prevState();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 38: // up
      selectPreviousOrder();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 39: // right
      nextState2();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 40: // down
      selectNextOrder();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 13: // down
      toggleDetails();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;
    default:
  }
  //if (!e.isDefaultPrevented()) orderListUtil.keyDownHandler(e);
};
var tableHeadFloated = false;

const resize = () => {
  unfloatTableHead();
  floatTableHead();
};

function nextState2() {
  nextState().done(function () {
    selectNextUnprocessedOrder(true);
  });
}

function floatTableHead() {
  const $id = $(".freeze-orderTable");
  if (tableHeadFloated === true) return;

  if ($id.length === 0) return;

  tableHeadFloated = true;

  $id.data("table.orderTable", true);

  $id.data(
    "freezeTable",
    new window.FreezeTable(".freeze-orderTable", {
      namespace: "orderTable",
      scrollable: true
    })
  );
}

function unfloatTableHead() {
  const $id = $(".freeze-orderTable");
  if ($id.data("tableHeadFloated") !== true) return;

  $id.data("freezeTable").destroy();
  $id.data("tableHeadFloated", false);
}

orderListUtil.printNewOrders = () => { };
