import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridWeek from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import "@fullcalendar/common/main.css";
import "@fullcalendar/timegrid/main.css";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import moment from "moment";
import auth, { get, post, messageDialog, put, del, patch, executeLongJob } from "../../js/auth";
import "./style.css";
import { Add, Create, Delete, Done, Error, Refresh, Send } from "@material-ui/icons";
import { IconButton } from "material-ui";
import { Button, Tooltip } from "@material-ui/core";

const r = /http:\/\/instance.eatwithme.online(:\d+)?\/eatwithme.server\//;

class OpeningHoursComponent extends Component {
  state = {
    view2: "timeGridWeek",
  };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      minTime: props.businessHours.filter(b => b.showStart).map(b => moment(b.startTime, "HH:mm")).reduce((prev, current) => {
        return prev.isBefore(current) ? prev : current
      }),
      maxTime: props.businessHours.filter(b => b.showEnd).map(b => moment(b.endTime, "HH:mm").add("hour", 1)).reduce((prev, current) => {
        return prev.isAfter(current) ? prev : current
      }),
      events: []
    };
  }

  componentDidUpdate = (props) => {

  }

  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <div ref={this.myTopRef} style={{ flexGrow: 1, flexShrink: 1, position: "relative" }}>
          <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "hidden" }}>
            <FullCalendar
              ref={this.myRef}
              initialView={this.state.view2}
              locale={localStorage.language}
              plugins={[timeGridWeek, interaction]}
              height="100%"
              slotMinTime="00:00:00"
              slotMaxTime={this.maxTimeText()}
              slotDuration="01:00:00"
              slotLabelInterval="02:00"
              allDaySlot={true}
              allDayContent={I18n.t("admin_local.closed")}
              dateClick={this.dateClick}
              initialEvents={this.events}
              slotLabelFormat={this.slotLabelFormat}
              eventStartEditable={true}
              eventResizableFromStart={true}
              editable={true}
              selectable={true}
              displayEventTime={false}
              eventOverlap={false}
              slotEventOverlap={false}
              eventDrop={this.update}
              eventResize={this.update}
              eventContent={this.eventContent}
              customButtons={this.customButtons(this)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  customButtons = instance => {
    return {
      today: {
        text: I18n.t("admin_local.today"),
        click: function () {
          instance.myRef.current.getApi().gotoDate(moment().toDate());
          instance.updateEvents();
        }
      }
    }
  }

  events = (d, successCallBack) => {
    if (this.myRef.current) {
      this.myRef.current.getApi().removeAllEvents();
    }
    get("api/businessDays/search/findByRestaurantBetween?restaurant=" + this.props.myStatus.restaurant_id + "&start=" + moment(d.start).format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B") + "&end=" + moment(d.end).format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(data => {
      const res = data._embedded.businessDays.map(this.convertToEvent);
      get("api/napiZarases/search/findByRestaurantBetween?restaurant=" + this.props.myStatus.restaurant_id + "&start=" + moment(d.start).format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B") + "&end=" + moment(d.end).format("YYYY-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(napiZaras => {
        napiZaras._embedded.napiZarases.forEach(nz => {
          const businessDay = res.find(b => nz.businessDayId == b.id.split("/").pop())
          if (businessDay) {
            businessDay.napiZaras.push(nz);
          }
        });
        res.forEach(e => {
          const napiZarasExist = e.napiZaras.length > 0;
          const napiZarasDone = napiZarasExist && e.napiZaras.find(n => n.feldolgozasAzonosito && n.statusz === "TELJESEN_SIKERES");
          if (napiZarasDone) {
            e.editable = false;
            e.color = "green";
          }
        });

        successCallBack(res);
      });
    })
  }

  eventContent = e => {
    const napiZarasExist = e.event.extendedProps.napiZaras.length > 0;
    const napiZarasDone = napiZarasExist && e.event.extendedProps.napiZaras.find(n => n.feldolgozasAzonosito);
    const napiZarasSuccess = napiZarasDone && e.event.extendedProps.napiZaras.find(n => n.feldolgozasAzonosito && n.statusz === "TELJESEN_SIKERES");
    const napiZarasTooltip = <div style={{ fontSize: ".7rem" }}>
      {e.event.extendedProps.napiZaras.filter(n => n.feldolgozasAzonosito).map(nz => <React.Fragment>{(nz.valasz ? nz.valasz.trim() : "").split('\n').map(a => <React.Fragment>{a}<br />&nbsp;</React.Fragment>)}</React.Fragment>)}
    </div>;
    const icon = napiZarasExist ? napiZarasDone ? napiZarasSuccess ? <Done /> : <Tooltip title={napiZarasTooltip} arrow><Error /></Tooltip> : <Send /> : <Add />;
    return <div className="fc-event-main-frame" style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">
          {e.event.title}
        </div>
      </div>
      {!napiZarasDone ?
        <IconButton>
          <Delete color="primary" onClick={() => this.delete(e)} />
        </IconButton> : null}
      <Button onClick={() => napiZarasExist ? this.sendToNtak(e) : this.createNapiZaras(e)} style={{ position: "absolute", bottom: "0", right: "0" }} color="secondary" variant="contained"
        startIcon={icon}>NTAK</Button>
    </div>
  }

  update = e => {
    put(e.event.id.replace(r, ""), {
      date: moment(e.event.start).startOf("day").format(),
      start: moment(e.event.start).add(this.state.minTime.hour(), 'hour').format(),
      end: e.event.end ? moment(e.event.end).add(this.state.minTime.hour(), 'hour').format() : null
    }).fail(d => {
      e.revert()
    })
  }

  sendToNtak = e => {
    e.event.extendedProps.napiZaras.forEach(napiZaras => {
      const id = napiZaras.id;
      executeLongJob("napiZaras", "ntak/napiZaras/" + id + "/send", false).done(() => {
        this.myRef.current.getApi().refetchEvents();
      })
    })
  }


  createNapiZaras = e => {
    if (e.event.extendedProps.napiZaras.length) {
      e.event.extendedProps.napiZaras.forEach(n => {
        patch(n.id.replace(r, ""), {})
      })
    } else
      post("api/napiZarases", {
        businessDay: e.event.id
      }).done(nz => {
        this.myRef.current.getApi().refetchEvents();
      })
  }


  delete = e => {
    del(e.event.id.replace(r, "")).done(() => {
      console.log(this.myRef.current.getApi());
      e.event.remove();
      //this.myRef.current.getApi().deleteEvent(e.event);
    })
  }

  new = () => {
    post("api/businessDays/search/new", {});
  }

  convertToEvent = (d) => {
    return {
      id: d.id ? "api/businessDays/" + d.id : d._links.self.href,
      allDay: d.start ? false : true,
      start: d.start ? moment(d.start).add('hour', - this.state.minTime.hour()).format() : moment(d.date).format(),
      end: d.start ? moment(d.end).add('hour', - this.state.minTime.hour()).format() : null,
      editDuration: d.start ? true : false,
      title: d.start ? I18n.t("admin_local.opened") : I18n.t("admin_local.closed"),
      napiZaras: []
    }
  }

  convertNapiZarasToEvent = d => {

  }

  dateClick = d => {
    //var events = [...this.state.events]
    const day = moment(d.date);
    var start = d.allDay ? null : moment(day.clone().startOf("day"));
    var end = d.allDay ? null : moment(day.clone().startOf("day").add('day', this.state.maxTime.hour() < this.state.minTime.hour() ? 1 : 0));
    if (!d.allDay) {
      end.add('hour', this.state.maxTime.hour());
      end.add('hour', -this.state.minTime.hour());
      const events = this.myRef.current.getApi().getEvents();
      start = events.filter(e => !e.allDay && moment(e.start).startOf("day").valueOf() === day.clone().startOf("day").valueOf() && moment(e.end).isBefore(day)).map(e => moment(e.end)).reduce((a, b) => a.isBefore(b) ? b : a, start);
      end = events.filter(e => !e.allDay && moment(e.start).startOf("day").valueOf() === day.clone().startOf("day").valueOf() && moment(e.start).isAfter(day)).map(e => moment(e.start)).reduce((a, b) => a.isBefore(b) ? a : b, end);
      start.add('hour', this.state.minTime.hour());
      end.add('hour', this.state.minTime.hour());
    }

    post("ntak/napiZaras/businessDay", {
      restaurant: { id: Number(this.props.myStatus.restaurant_id) },
      date: moment(day.clone().startOf("day")).valueOf(),
      start: start?.valueOf(),
      end: end?.valueOf(),
    }).done(d => {
      this.myRef.current.getApi().addEvent(this.convertToEvent(d));
    });
    //this.setState({ events: events });
  }

  minTimeText = () => {
    return this.state.minTime.format("HH:mm:ss");
  }

  maxTimeText = () => {
    if (this.state.maxTime.hour() < this.state.minTime.hour()) {
      return (this.state.maxTime.hour() + 24 - this.state.minTime.hour()) + this.state.maxTime.format(":mm:ss");
    }
    const t = (this.state.maxTime.hour() - this.state.minTime.hour());
    return (t ? t : 24) + this.state.maxTime.format(":mm:ss");
  }

  slotLabelFormat = d => moment(d.date).add('hour', this.state.minTime.hour()).format("HH")



}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      businessHours: props.rootReducer.businessHours
    };
  }
  return {};
};

function roomComparator(a, b) {
  if (a.order === b.order && a.id !== b.id) {
    if (a.room === b.room && a.id !== b.id) {
      return a.seats - b.seats;
    }
    return a.room.localeCompare(b.room);
  }
  return a.order - b.order;
}

export default connect(mapStateToProps)(OpeningHoursComponent);
