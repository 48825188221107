import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { I18n, Translate } from "react-redux-i18n";
import Select from 'react-select';


class StornoDialog extends Component {
  state = {
    show: this.props.show,
    reason: 0,
    ap: localStorage.ap ? localStorage.ap : "",
    receiptNumber: "",
    customerTaxNum: "",
    customerName: "",
    customerPostCode: "",
    customerTown: "",
    customerAddress: ""
  };

  constructor(props) {
    super(props);
    this.state.show = this.props.show;
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  componentDidMount() {
  }

  inputChange = event => {
    this.setState({ input: event.currentTarget.value })
  }


  render() {

    const options = [
      { value: "1", label: I18n.t("admin_local.stornoreason_1") },
      { value: "2", label: I18n.t("admin_local.stornoreason_2") },
      { value: "3", label: I18n.t("admin_local.stornoreason_3") },
      { value: "4", label: I18n.t("admin_local.stornoreason_4") },
      { value: "5", label: I18n.t("admin_local.stornoreason_5") },
      { value: "6", label: I18n.t("admin_local.stornoreason_6") },
      { value: "7", label: I18n.t("admin_local.stornoreason_7") },
      { value: "8", label: I18n.t("admin_local.stornoreason_8") },
      { value: "0", label: I18n.t("admin_local.stornoreason_0") }
    ];

    if (this.state.show === false) {
      return null;
    }
    return (
      <Modal style={{ zIndex: 10000 }} show={this.state.show} onHide={this.props.def.reject} centered >
        <Modal.Header className="eatwithme no-close">
          <h1>{I18n.t("admin_local.storno")}</h1>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "27px" }}>
          <Form.Group className="mb-3">
            <Select id="reason"
              onChange={this.optionChange}
              options={options}
              placeholder={I18n.t("admin_local.stornoreason")}
            />
            <Form.Control onChange={this.apchange} id="ap" defaultValue={this.state.ap} type="text" placeholder={I18n.t("admin_local.ap")} />
            <Form.Control onChange={this.change} id="receiptNumber" type="text" placeholder={I18n.t("admin_local.receiptNumber")} />
            <Form.Control onChange={this.change} id="customerTaxNum" type="text" placeholder={I18n.t("admin_local.customerTaxNum")} />
            <Form.Control onChange={this.change} id="customerName" type="text" placeholder={I18n.t("admin_local.customerName")} />
            <Form.Control onChange={this.change} id="customerPostCode" type="text" placeholder={I18n.t("admin_local.customerPostCode")} />
            <Form.Control onChange={this.change} id="customerTown" type="text" placeholder={I18n.t("admin_local.customerTown")} />
            <Form.Control onChange={this.change} id="customerAddress" type="text" placeholder={I18n.t("admin_local.customerAddress")} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {this.props.buttons.map((button, ind) => {
            return (
              <Button
                key={ind}
                onClick={() => {
                  this.unmount();
                  this.props.def.resolve(this.state);
                }}
              >
                <Translate value={button} />
              </Button>
            );
          })}
          <Button
            variant="danger"
            onClick={() => {
              this.unmount();
              this.props.def.reject();
            }}
          >
            <Translate value="local.cancel" />
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
  optionChange = (event) => {
    this.setState({ reason: event.value });

  }
  change = (event) => {
    const state = {};
    state[event.currentTarget.id] = event.currentTarget.value;
    this.setState(state)

  }
  apchange = (event) => {
    const state = {};
    state[event.currentTarget.id] = event.currentTarget.value;
    localStorage.ap = event.currentTarget.value;
    this.setState(state)

  }
}

export default StornoDialog;
